import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import {
  leftIcon,
  centerIcon,
  collapseIcon,
  expandIcon,
  sortThicknessDownIcon,
  sortThicknessUpIcon,
  sortWidthDownIcon,
  sortWidthUpIcon,
  downIcon,
  timeUpIcon,
  timeDownIcon,
  shuffleIcon,
  rightIcon,
  floatTaggedIcon,
} from '../helpers/icons';
import { useDispatch, useSelector } from 'react-redux';
import { boxActions } from '../../redux/_actions';
import { boxConstants } from '../../redux/_constants';

function StackViewSelector(props) {
  const { expandStack, expanded, justifyStack, justify } = props;
  const dispatch = useDispatch();
  const box = useSelector((state) => state.box);
  const tag = useSelector((state) => state.tag);
  const links = useSelector((state) => state.stack.links);
  const {
    SORT_CREATEDAT_DESC,
    SORT_CREATEDAT_ASC,
    SORT_THICKNESS_DESC,
    SORT_THICKNESS_ASC,
    SORT_WIDTH_DESC,
    SORT_WIDTH_ASC,
  } = boxConstants;

  const sortBy = (by) => {
    const { allIds, byId } = box.origamis;
    const { sort } = box.status;
    let sortBy = null;

    switch (by) {
      case 'createdAt':
        sortBy =
          sort === SORT_CREATEDAT_DESC
            ? SORT_CREATEDAT_ASC
            : SORT_CREATEDAT_DESC;
        break;
      case 'thickness':
        sortBy =
          sort === SORT_THICKNESS_DESC
            ? SORT_THICKNESS_ASC
            : SORT_THICKNESS_DESC;
        break;
      case 'width':
        sortBy = sort === SORT_WIDTH_DESC ? SORT_WIDTH_ASC : SORT_WIDTH_DESC;
        break;
      default:
        break;
    }

    dispatch(boxActions.sort(sortBy, allIds, byId));
  };

  const floatTagged = () => {
    if (!tag.id) {
      return;
    }
    const { allIds, byId } = box.origamis;
    dispatch(boxActions.floatTagged(tag.id, allIds, byId, links.byId));
  };

  const shuffle = () => {
    dispatch(boxActions.shuffle(box.origamis.allIds));
  };

  return (
    <Dropdown
      style={{
        borderRadius: '100px',
        border: '1px solid #eee',
        float: 'right',
      }}
    >
      <Dropdown.Toggle id="dropdown-stack-view-selector" variant="outline">
        {downIcon}
      </Dropdown.Toggle>
      <Dropdown.Menu className="stack-view-menu">
        <Dropdown.Item as="button" onClick={justifyStack}>
          {justify === 0 ? 'Center' : justify === 1 ? 'Right' : 'Left'}
          <span style={{ float: 'right' }}>
            {justify === 0 ? centerIcon : justify === 1 ? rightIcon : leftIcon}
          </span>
        </Dropdown.Item>
        <Dropdown.Item as="button" onClick={expandStack}>
          {expanded ? 'Collapse' : 'Expand'}
          <span style={{ float: 'right' }}>
            {expanded ? collapseIcon : expandIcon}
          </span>
        </Dropdown.Item>
        <Dropdown.Item as="button" onClick={() => sortBy('createdAt')}>
          Created at
          <span style={{ float: 'right' }}>
            {box.status.sort === SORT_CREATEDAT_ASC ? timeUpIcon : timeDownIcon}
          </span>
        </Dropdown.Item>
        <Dropdown.Item as="button" onClick={() => sortBy('thickness')}>
          Thickness
          <span style={{ float: 'right' }}>
            {box.status.sort === SORT_THICKNESS_DESC
              ? sortThicknessDownIcon
              : sortThicknessUpIcon}
          </span>
        </Dropdown.Item>
        <Dropdown.Item as="button" onClick={() => sortBy('width')}>
          Width
          <span style={{ float: 'right' }}>
            {box.status.sort === SORT_WIDTH_DESC
              ? sortWidthDownIcon
              : sortWidthUpIcon}
          </span>
        </Dropdown.Item>
        <Dropdown.Item as="button" onClick={shuffle}>
          Shuffle
          <span style={{ float: 'right' }}>{shuffleIcon}</span>
        </Dropdown.Item>
        <Dropdown.Item as="button" onClick={floatTagged}>
          Tags to top
          <span style={{ float: 'right' }}>{floatTaggedIcon}</span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default StackViewSelector;
