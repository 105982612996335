import React, { useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { SubscribeToNewOrigami } from '../graphql/graphql';
import TimeAgo from 'react-timeago';
import MainMenu from './sidebar/sidebar';
import ModelViewer from './helpers/model-viewer';
import UserSelector from './topbar/user-selector';
import DownloadSelector from './topbar/download-selector';
import EditSelector from './topbar/edit-selector';
import SearchSelector from './topbar/search-selector';
import StackViewSelector from './topbar/stack-view-selector';
import axios from 'axios';
import {
  downloadGLB,
  downloadUSDZ,
  downloadFront,
  downloadBack,
} from './helpers/download-helper';
import { loading, LoadingProgressiveStack } from './helpers/loading';
import { formatter } from './helpers/timeformat-helper';
import { notify } from './helpers/notification';
import { isColor, lightenOrDarkenColor } from './helpers/color-helper';
import ErrorModal from './helpers/error-modal';
import { useHotkeys } from 'react-hotkeys-hook';
import OGButton from './helpers/og-button';
//import TagButton from "./helpers/tag-button";
//import BlackBoxButton from "./helpers/blackbox-button";
//import GiftsButton from "./helpers/gifts-button";
import Tag from './helpers/tag';
import { useSelector, useDispatch } from 'react-redux';
import { boxActions, stackActions, alertActions } from '../redux/_actions';
import { boxService } from '../redux/_services';

function Box(props) {
  const [id, setId] = useState(null);
  const [mv, setMv] = useState(null); // for modelViewer
  const [glb, setGlb] = useState(null);
  const [usdz, setUsdz] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [justify, setJustify] = useState(1);
  const { user } = props;
  const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const tag = useSelector((state) => state.tag);
  const box = useSelector((state) => state.box);
  const alert = useSelector((state) => state.alert);
  const filter = useSelector((state) => state.stack.status.filter);
  const filteredStack = useSelector(
    (state) => state.stack.status.filteredStack
  );
  const dispatch = useDispatch();
  const stack =
    filter.length > 0 ? Object.keys(filteredStack) : box.origamis.allIds;

  // Query the API and save them to the state
  useEffect(() => {
    dispatch(boxActions.load(user.username));
    dispatch(stackActions.load());
  }, [user, dispatch]);

  useEffect(() => {
    setId(box.status.selected);
  }, [box.status.selected]);

  useEffect(() => {
    const subscriber = API.graphql(
      graphqlOperation(SubscribeToNewOrigami, {
        username: user.username,
      })
    ).subscribe({
      next: (e) => {
        //const origami = e.value.data.onCreateOrigami;
        //const newOrigami = [origami, ...origamis]
        //updateOrigami(newOrigami)
        //setId(newOrigami[0].id)
      },
      error: (error) => {
        console.log('Subscribe error', error);
      },
    });
    return () => subscriber.unsubscribe();
  }, [box.origamis, user.username]);

  useEffect(() => {
    async function setModel(newId) {
      let [glbLink, usdzLink] = [null, null];

      if (newId) {
        glbLink = await axios.get(`/api/download/${newId}/glb`);

        if (iOS) {
          usdzLink = await axios.get(`/api/download/${newId}/usdz`);
        }
        if (glbLink !== null) {
          setGlb(glbLink.data.glb);
        }
        if (usdzLink !== null) {
          setUsdz(usdzLink.data.usdz);
        }
      } else {
        glbLink = null;
        usdzLink = null;
      }

      setMv(
        <ModelViewer
          modelGLB={glbLink === null ? glbLink : glbLink.data.glb}
          modelUSDZ={usdzLink === null ? usdzLink : usdzLink.data.usdz}
          style={{ backgroundColor: 'unset' }}
        />
      );
    }

    setModel(id);
  }, [id, iOS]);

  useHotkeys(
    'up',
    (e) => {
      e.preventDefault();
      moveUp();
    },
    [box.origamis, id]
  );

  useHotkeys(
    'down',
    (e) => {
      e.preventDefault();
      moveDown();
    },
    [box.origamis, id]
  );

  const moveUp = () => {
    const array = [...stack];
    const indexOf = array.findIndex((i) => i === id);
    if (indexOf !== 0 && indexOf !== null) {
      //setId(array[indexOf - 1])
      dispatch(boxActions.select(array[indexOf - 1]));
    }
  };

  const moveDown = () => {
    const array = [...stack];
    const indexOf = array.findIndex((i) => i === id);
    if (indexOf !== array.length - 1 && indexOf !== null) {
      //setId(array[indexOf + 1])
      dispatch(boxActions.select(array[indexOf + 1]));
    }
  };

  const expandStack = () => {
    expanded ? notify('Collapsing stack') : notify('Expanding stack');
    setExpanded(!expanded);
  };

  const justifyStack = () => {
    justify === 2
      ? notify('Left justifying stack')
      : justify === 0
      ? notify('Centering stack')
      : notify('Right justifying stack');
    // 0 is left, 1 is center, 2 is right
    setJustify(justify === 2 ? 0 : justify + 1);
  };

  const deleteLine = (e, modelId) => {
    const { allIds } = box.origamis;
    const indexOf = allIds.findIndex((id) => id === modelId);
    if (indexOf === -1) {
      return;
    }
    const nextId =
      indexOf === allIds.length - 1
        ? allIds[allIds.length - 2]
        : allIds[indexOf + 1];

    notify(`Deleting ${modelId}`);
    dispatch(boxActions.deleteOrigami(modelId, nextId));
  };

  const handleSelect = (e, id) => {
    e.preventDefault();
    if (box.status.selected === id) {
      return;
    }
    return dispatch(boxActions.select(id));
  };

  const handleImageDL = (id, type) => {
    boxService
      .editOG(id)
      .then((res) => {
        if (type === 'front') {
          downloadFront(id, res.data.getOrigami.params.frontImage);
        }
        if (type === 'back') {
          downloadBack(id, res.data.getOrigami.params.backImage);
        }
      })
      .catch((error) => {
        dispatch(alertActions.error(error.message));
      });
  };

  return (
    <div className="card">
      <div className="card-header">
        <MainMenu
          history={props.history}
          location={props.location}
          setId={setId}
        />
        <UserSelector />
        <div className="search-sort-container">
          <SearchSelector />
          <StackViewSelector
            expandStack={expandStack}
            expanded={expanded}
            justifyStack={justifyStack}
            justify={justify}
          />
        </div>
      </div>

      <div
        className="card-body unselectable model-viewer-container"
        style={{
          height: 'calc(70vh - 48px)',
          width: '100%',
          padding: '0',
          position: 'fixed',
          top: '48px',
          left: '0',
          zIndex: '10',
        }}
      >
        {mv === null ? null : (
          <div className="model-viewer-actions">
            <EditSelector modelID={id} deleteLine={deleteLine} />
            <DownloadSelector
              modelID={id}
              downloadUSDZ={() => downloadUSDZ(id, usdz)}
              downloadGLB={() => downloadGLB(id, glb)}
              downloadFront={() => handleImageDL(id, 'front')}
              downloadBack={() => handleImageDL(id, 'back')}
              clipboard={true}
            />
          </div>
        )}
        <div className="model-viewer">{mv === null ? loading : mv}</div>
      </div>
      <div className="card-body unselectable box-viewer">
        <div style={{ minHeight: '70vh', width: '100%' }} />
        <div style={{ position: 'relative', width: '100%' }}>
          {box.status.loading ? (
            <LoadingProgressiveStack />
          ) : (
            <>
              <ul className="box-deck">
                {stack.map((origamiId, i) => {
                  const p = box.origamis.byId[origamiId];
                  if (!p) {
                    return null;
                  }
                  let selected = p.id === id ? 'selected' : '';
                  let expand = expanded ? 'expanded' : '';
                  let background = null;

                  // Add effects for rounded corners
                  if (p.params) {
                    const edgeColor = isColor(p.params.edge)
                      ? p.params.edge
                      : '#eee';
                    let corners = '#eee'; // default
                    let main = '';

                    if (p.params.radius > 0) {
                      const isRound = p.params.radius === 100;
                      corners = isRound ? '#aaa' : '#bbb';
                      let radius = isRound
                        ? 50
                        : (100 * p.params.radius) / p.params.width;
                      main += edgeColor;
                      main += ` ${radius}% ${100 - radius}%, `;
                    } else if (p.params.radius < 0) {
                      corners = lightenOrDarkenColor(edgeColor, -35);
                      let radius = (-100 * p.params.radius) / p.params.width;
                      main += `${corners} ${radius}%, `;
                      main += `${edgeColor} ${radius}%, `;
                      main += ` ${edgeColor} ${100 - radius}%, `;
                      main += ` ${corners} ${100 - radius}%, `;
                    } else if (isColor(p.params.edge)) {
                      corners = p.params.edge;
                    }

                    background = `linear-gradient(to left, ${corners} 0%, ${main}${corners} 100%)`;
                  }

                  // Apply stack filters
                  if (
                    filter.length > 0 &&
                    !filteredStack.hasOwnProperty(p.id)
                  ) {
                    return null; // skip OG
                  }

                  return (
                    <li key={p.id}>
                      <div
                        className={[selected, expand, 'flat-header'].join(' ')}
                      >
                        {selected !== '' || expanded
                          ? `#${stack.length - i}`
                          : null}
                      </div>
                      <button
                        className={[selected, expand].join(' ')}
                        type="button"
                        onClick={(e) => handleSelect(e, p.id)}
                      >
                        {/* selected !== '' || expanded ? ` (${origamis.length - i})` : null */}
                        <div
                          className={[selected, 'flat'].join(' ')}
                          style={{
                            width: p.params
                              ? (p.params.width / 250) * 100 + '%'
                              : '50%',
                            height: p.params
                              ? p.params.thickness * 6 + 'px'
                              : null,
                            background: background,
                            marginLeft: justify === 0 ? null : 'auto',
                            marginRight: justify === 2 ? null : 'auto',
                          }}
                        >
                          {tag.id &&
                          tag.items &&
                          tag.items.hasOwnProperty(p.id) ? (
                            <div className="tagged" />
                          ) : null}
                        </div>
                      </button>
                      <div
                        className={[selected, expand, 'flat-footer'].join(' ')}
                      >
                        {/*` by ${p.username} ` */}
                        {selected !== '' || expanded ? (
                          <TimeAgo
                            date={p.createdAt}
                            minPeriod={60}
                            formatter={formatter}
                          />
                        ) : null}
                      </div>
                    </li>
                  );
                })}
              </ul>
              <div className="stack-base" />
            </>
          )}
        </div>
      </div>
      {box.status.loading ? null : (
        <>
          <Tag moveUp={moveUp} moveDown={moveDown} id={id} />
          {/*<GiftsButton />
          <BlackBoxButton />
          <TagButton />*/}
          <OGButton />
        </>
      )}
      {!alert.clear ? <ErrorModal /> : null}
    </div>
  );
}

export default Box;
