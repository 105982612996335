import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { logoutIcon } from "../helpers/icons";
import { useUser } from "../../user-provider";

function UserSelector(props) {
  const { logout, user } = useUser();

  return (
    <>
      <Dropdown style={{ float: "right", marginRight: "8px" }}>
        <Dropdown.Toggle
          id="dropdown-user-selector"
          variant="outline"
          style={{ height: "48px" }}
        >
          {/*caretDownIcon*/}
        </Dropdown.Toggle>
        <Dropdown.Menu className="user-selector">
          <Dropdown.Item as="button" onClick={logout}>
            Logout
            <span style={{ float: "right", marginTop: "-2px" }}>
              {logoutIcon}
            </span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <div className="username">@{user.username}</div>
    </>
  );
}

export default UserSelector;
