import axios from 'axios';

export const downloadGLB = async (id, getLink = null) => {
  let link = '/static/placeholders/origamiscan.glb';

  if (getLink === null && id !== null) {
    await axios.get(`/api/download/${id}/glb`).then((res) => {
      link = res.data.glb;
    });
  } else {
    link = getLink;
  }

  downloadURI(link, 'origamiscan.glb');
};

export const downloadUSDZ = async (id, getLink = null) => {
  let link = '/static/placeholders/origamiscan.usdz';

  if (getLink === null && id !== null) {
    await axios.get(`/api/download/${id}/usdz`).then((res) => {
      link = res.data.usdz;
    });
  } else {
    link = getLink;
  }

  downloadURI(link, 'origamiscan.usdz');
};

export const downloadFront = async (id, filename, getLink = null) => {
  let link = '/static/placeholders/front.jpg';

  if (getLink === null && id !== null) {
    await axios.get(`/api/download/${id}/front/${filename}`).then((res) => {
      link = res.data.front;
    });
  } else {
    link = getLink;
  }

  downloadURI(link, 'front.jpg');
};

export const downloadBack = async (id, filename, getLink = null) => {
  let link = '/static/placeholders/back.jpg';

  if (getLink === null && id !== null) {
    await axios.get(`/api/download/${id}/back/${filename}`).then((res) => {
      link = res.data.back;
    });
  } else {
    link = getLink;
  }

  downloadURI(link, 'back.jpg');
};

const downloadURI = async (uri, name) => {
  const link = document.createElement('a');
  link.download = name;
  link.href = uri;
  link.click();
};
