import React, { useEffect, useState } from "react";
import TimeAgo from "react-timeago";
import { formatter } from "../../helpers/timeformat-helper";
import SidebarHeader from "../helpers/sidebar-header";
//import { useSelector } from 'react-redux'
import { useUser } from "../../../user-provider";

function BlackBox(props) {
  const [box, setBox] = useState([]);
  const { menu } = props;
  const { user } = useUser();

  // Query the API and save them to the state
  useEffect(() => {
    const results = user.boxes.items || [];
    setBox(results);
  }, [user]);

  return (
    <React.Fragment>
      <SidebarHeader title={`Black Box (${box.length})`} />
      <div className="box-deck-container">
        <ul className="box-list">
          {box.map((p, i) => {
            return (
              <li key={p.id}>
                <button
                  style={{ textTransform: "capitalize" }}
                  onClick={() => menu(null)}
                >
                  <div className="flat-header">{`${i + 1}`}</div>
                  <div style={{ textAlign: "left", width: "100%" }}>
                    {p.name}
                  </div>
                  <div className="flat-footer">
                    <TimeAgo
                      date={p.createdAt}
                      minPeriod={60}
                      formatter={formatter}
                    />
                  </div>
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    </React.Fragment>
  );
}

export default BlackBox;
