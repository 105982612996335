import React from 'react';
import { SignUp } from "aws-amplify-react";
import { I18n } from 'aws-amplify';
import {
	FormSection,
	FormField,
	SectionHeader,
	SectionBody,
	SectionFooter,
	Button,
	Link,
	Input,
	InputLabel,
	SectionFooterPrimaryContent,
	SectionFooterSecondaryContent,
} from 'aws-amplify-react/lib-esm/Amplify-UI/Amplify-UI-Components-React'

import { PhoneField } from 'aws-amplify-react/lib-esm/Auth/PhoneField';
import { auth } from 'aws-amplify-react/lib-esm/Amplify-UI/data-test-attributes';



class CustomSignUp extends SignUp {
  	constructor(props: any) {
    	super(props);
    	this._validAuthStates = ["signUp"];
  	}

	showComponent(theme) {
		if (this.checkCustomSignUpFields()) {
			this.signUpFields = this.props.signUpConfig.signUpFields;
		}
		this.sortFields();
		return (
			<FormSection theme={theme} data-test={auth.signUp.section}>
				<SectionHeader theme={theme} data-test={auth.signUp.headerSection}>
					{I18n.get(this.header)}
				</SectionHeader>
				<SectionBody theme={theme} data-test={auth.signUp.bodySection}>
					{this.signUpFields.map(field => {
						return field.key !== 'phone_number' ? (
							<FormField theme={theme} key={field.key}>
								{field.required ? (
									<InputLabel theme={theme}>
										{I18n.get(field.label)} *
									</InputLabel>
								) : (
									<InputLabel theme={theme}>{I18n.get(field.label)}</InputLabel>
								)}
								<Input
									autoFocus={
										this.signUpFields.findIndex(f => {
											return f.key === field.key;
										}) === 0
											? true
											: false
									}
									placeholder={I18n.get(field.placeholder)}
									theme={theme}
									type={field.type}
									name={field.key}
									key={field.key}
									onChange={this.handleInputChange}
									data-test={auth.signUp.nonPhoneNumberInput}
									autoCapitalize="off"
								/>
							</FormField>
						) : (
							<PhoneField
								theme={theme}
								required={field.required}
								defaultDialCode={this.getDefaultDialCode()}
								label={field.label}
								placeholder={field.placeholder}
								onChangeText={this.onPhoneNumberChanged}
								key="phone_number"
							/>
						);
					})}
				</SectionBody>
				<SectionFooter theme={theme} data-test={auth.signUp.footerSection}>
					<SectionFooterPrimaryContent theme={theme}>
						<Button
							onClick={this.signUp}
							theme={theme}
							data-test={auth.signUp.createAccountButton}
						>
							{I18n.get('Create Account')}
						</Button>
					</SectionFooterPrimaryContent>
					<SectionFooterSecondaryContent theme={theme}>
						{I18n.get('Have an account? ')}
						<Link
							theme={theme}
							onClick={() => this.changeState('signIn')}
							data-test={auth.signUp.signInLink}
						>
							{I18n.get('Sign in')}
						</Link>
					</SectionFooterSecondaryContent>
				</SectionFooter>
			</FormSection>
		);
	}
}

export default CustomSignUp;	