import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { SketchPicker } from 'react-color';
import { imagesIcon, backImagesIcon } from '../../helpers/icons';
import { lightOrDark } from '../../helpers/color-helper';
import { makerActions } from '../../../redux/_actions';
import { useDispatch, useSelector } from 'react-redux';

function edgeIcon(color) {
  const lod =
    lightOrDark(color) === 'light'
      ? 'radial-gradient(rgba(100, 100, 100, 0.3), transparent)'
      : null;

  return (
    <svg
      className="bi bi-image"
      width="24px"
      height="24px"
      viewBox="0 0 20 20"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      style={{ background: lod }}
    >
      <path
        fillRule="evenodd"
        d="M16.002 4h-12a1 1 0 00-1 1v10a1 1 0 001 1h12a1 1 0 001-1V5a1 1 0 00-1-1zm-12-1a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2h-12z"
        clipRule="evenodd"
      />
    </svg>
  );
}

function selection(title) {
  switch (title) {
    case 'front':
      return imagesIcon;
    case 'back':
      return backImagesIcon;
    default:
      return edgeIcon(title);
  }
}

function EdgeSelector({ current, send }) {
  const dispatch = useDispatch();
  const edge = useSelector((state) => state.maker.edge);
  const customColor = edge.split('#')[0] === '' ? edge : '#ffffff';

  const changeEdge = (val) => {
    if (current.matches('complete')) {
      send('ADDTWO');
    }
    dispatch(makerActions.changeEdge(val));
  };

  return (
    <Dropdown style={{ float: 'right' }}>
      <Dropdown.Toggle id="dropdown-edge-selector" variant="outline">
        {selection(edge)}
      </Dropdown.Toggle>
      <Dropdown.Menu className="edge-menu">
        <Dropdown.Item
          as="button"
          active={edge === 'front'}
          onClick={(e) => changeEdge('front')}
        >
          Edge Front Wrap
          <span style={{ float: 'right' }}>{imagesIcon}</span>
        </Dropdown.Item>
        <Dropdown.Item
          as="button"
          active={edge === 'back'}
          onClick={(e) => changeEdge('back')}
        >
          Edge Back Wrap
          <span style={{ float: 'right' }}>{backImagesIcon}</span>
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item
          as="button"
          active={edge.split('#')[0] === ''}
          onClick={(e) => changeEdge(customColor)}
          style={{ position: 'relative' }}
        >
          Edge Color
          <div
            style={{
              background: customColor,
              right: '10px',
              top: '10%',
              position: 'absolute',
              width: '20%',
              height: '80%',
              border: '1px solid gray',
              borderRadius: '2px',
            }}
          />
        </Dropdown.Item>
        <SketchPicker
          disableAlpha={true}
          color={customColor}
          onChangeComplete={(e) => changeEdge(e.hex)}
          presetColors={[
            '#FFFFFF',
            '#000000',
            '#D0021B',
            '#F5A623',
            '#F8E71C',
            '#8B572A',
            '#BD10E0',
            '#666666',
          ]}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default EdgeSelector;
