import React, { useEffect, useState, useRef } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { getOrigami } from '../graphql/queries'
import TimeAgo from 'react-timeago'
import MainMenu from './sidebar/sidebar'
import ModelViewer from './helpers/model-viewer'
import DownloadSelector from './topbar/download-selector'
import axios from 'axios'
import { downloadGLB, downloadUSDZ } from './helpers/download-helper'
import { loading } from './helpers/loading'
import queryString from 'query-string'
import { formatter } from './helpers/timeformat-helper'
import OGButton from './helpers/og-button'
//import { useHotkeys } from 'react-hotkeys-hook'
import Dropzone from 'react-dropzone'
import { isColor } from './helpers/color-helper'
import EditSelector from './topbar/edit-selector'


function Touch(props) {
  const [id, setId] = useState(null)    
  const [mv, setMv] = useState(null)    // for modelViewer
  const [origami, updateOrigami] = useState([])
  const [glb, setGlb] = useState(null)
  const [usdz, setUsdz] = useState(null)
  const { location } = props
  const [makerFiles, setMakerFiles] = useState(null)
  const menuRef = useRef(null)
  const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream


  useEffect(() => {
    async function setModel(newId) {     
      if (newId === null) { return null }
      let [ glbLink, usdzLink ] = [null, null]
      glbLink = await axios.get(`/api/download/${newId}/glb`)

      if(iOS) {
        usdzLink = await axios.get(`/api/download/${newId}/usdz`)
      }    

      if (glbLink !== null) { setGlb(glbLink.data.glb) }
      if (usdzLink !== null) { setUsdz(usdzLink.data.usdz) }

      setMv (
        <ModelViewer 
          modelGLB={glbLink === null ? glbLink : glbLink.data.glb}
          modelUSDZ={usdzLink === null ? usdzLink : usdzLink.data.usdz}
        />
      )
    }

    async function fetchData(newId) {
      await API.graphql(graphqlOperation(getOrigami, { id: newId }))
      .then((res) => {
        updateOrigami([res.data.getOrigami])
      })
    }

    if (!location || !location.search) { return }     // TODO: should throw 404 error
    const values = queryString.parse(location.search)
    
    if(!values.m) { return }
    setId(values.m)
    setModel(values.m)
    fetchData(values.m)
  }, [iOS, location]);
  

  const dropMaker = function(files) {
    setMakerFiles(files)
    showMakeMenu()
  }

  const showMakeMenu = () => {
    menuRef.current.showMakeMenu()
  }


  return (
    <Dropzone onDrop={(acceptedFiles) => dropMaker(acceptedFiles)} >
      {({getRootProps}) => (
        <div className="card" {...getRootProps()} >
          <div className="card-header" style={{height: '48px'}}>
            <MainMenu 
              id={id} 
              history={props.history} 
              location={props.location} 
              files={makerFiles}
              ref={menuRef}
            />
            <DownloadSelector 
              modelID={id}
              downloadUSDZ={() => downloadUSDZ(id, usdz)}
              downloadGLB={() => downloadGLB(id, glb)}
              clipboard={true}
            />
            <EditSelector modelID={id} deleteLine={null} />
          </div>

          {/* ogmaking ? OGMaker : null */}

          <div className="card-body unselectable" style={{height: '75vh', width: '100%', padding: '0', position: 'fixed', top: '48px', left: '0', zIndex: '10'}}>
            <div style={{width: '100%', height: '100%'}} >
              { mv === null ? loading : mv }
            </div>
          </div>
          <div className="card-body unselectable box-viewer" >  
            <div style={{minHeight: '75vh', width: '100%', marginTop: '48px' }} />
            <ul className="box-deck">
              {    
                origami.map((p, i) => {
                  let background = null

                  // Add effects for rounded corners
                  if (p.params) {
                    let corners = '#eee'    // default
                    let main = ''

                    if (p.params.radius > 0) {
                      corners = '#bbb'
                      let radius = 100 * p.params.radius / p.params.width
                      main += isColor(p.params.edge) ? p.params.edge : "#eee"
                      main += ` ${radius}% ${100-radius}%, `
                    } else if (isColor(p.params.edge)) {
                      corners = p.params.edge
                    }

                    background = `linear-gradient(to left, ${corners} 0%, ${main}${corners} 100%)`
                  }

                  return (
                    <li key={i} >
                      <div className="touch-flat-container">
                        <div className="flat"
                          style={{ 
                            width:  p.params ? p.params.width / 300 * 100 + '%' : '50%', 
                            height: p.params ? p.params.thickness * 10 + 'px' : null,
                            background: background
                          }} 
                        />
                      </div>
                      <div className="touch-flat-header">
                        {`${p.params.width.toFixed(0)} x ${p.params.height.toFixed(0)} x ${p.params.thickness.toFixed(2)} (mm)`}
                      </div>
                      <div className="touch-flat-footer">
                        <span style={{fontWeight: 'normal', position: 'unset', float: 'unset'}}>{`by ${p.username}`}&nbsp;</span>
                        <TimeAgo date={p.createdAt} minPeriod={60} formatter={formatter} />
                      </div>
                    </li>
                  )
                })
              }
            </ul>
          </div>
          <OGButton showMakeMenu={showMakeMenu} dropMaker={dropMaker} />
        </div>
      )}
    </Dropzone>
  );
}


export default Touch;
