
export const makerConstants = {
  REMOVESINGLE: 'MAKER_REMOVESINGLE',
  RESET: 'MAKER_RESET',
  ROTATESINGLE: 'MAKER_ROTATESINGLE',
  ROTATEDOUBLE: 'MAKER_ROTATEDOUBLE',
  SWAP: 'MAKER_SWAP',
  CREATE3D_REQUEST: 'MAKER_CREATE3D_REQUEST',
  CREATE3D_UPLOAD_REQUEST: 'MAKER_CREATE3D_UPLOAD_REQUEST',
  CREATE3D_UPLOAD_SUCCESS: 'MAKER_CREATE3D_UPLOAD_SUCCESS',
  CREATE3D_SUCCESS: 'MAKER_CREATE3D_SUCCESS',
  CREATE3D_FAILURE: 'MAKER_CREATE3D_FAILURE',
  CHANGETHICKNESS: 'MAKER_CHANGETHICKNESS',
  CHANGERADIUS: 'MAKER_CHANGERADIUS',
  CHANGEEDGE: 'MAKER_CHANGEEDGE',
  EDIT3D_REQUEST: 'MAKER_EDIT3D_REQUEST',
  EDIT3D_SUCCESS: 'MAKER_EDIT3D_SUCCESS',
  EDIT3D_FAILURE: 'MAKER_EDIT3D_FAILURE',
  OPENFILES: 'MAKER_OPENFILES',
  CLEARQUEUE: 'MAKER_CLEARQUEUE',
};

export const initialMakerState = {
  queue: [],
  fileObj: [null, null],
  Ws: null,
  file: [null, null],
  rotate: [0, 0],       // default in turns (1 = 360)
  maxDim: 0,
  aspectRatio: [null, null],
  dimensions: [null, null],
  swapped: false,
  thickness: 0.25,      // default in mm
  cornerRadius: 0,
  dpi: 600,             // default in dpi
  edge: "back",
  loading: false,
  modelID: null,
  queueIndex: 0,
  complete: false,
  upload: null,
  error: false,
}