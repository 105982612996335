import React, { useState, useEffect } from 'react';
import { miniSearchIcon, xIcon, checkIcon, taggedIcon } from '../helpers/icons';
import { useSelector, useDispatch } from 'react-redux';
import { stackActions } from '../../redux/_actions';
import { notify } from '../helpers/notification';
import './search-selector.css';
//import { useUser } from '../../user-provider';
//import StackViewSelector from './stack-view-selector';

function SearchSelector(props) {
  const [searchTerm, setSearchTerm] = useState('');
  const [focused, setFocused] = useState(false);
  const stackTags = useSelector((state) => state.stack.tags);
  const filter = useSelector((state) => state.stack.status.filter);
  const dispatch = useDispatch();
  const [tagsInFilter, setTagsInFilter] = useState(filter);
  let topTag = { id: '', name: '' };

  useEffect(() => {
    if (focused) {
      document.getElementById('search-stack-input').focus();
    }
  }, [focused]);

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
  };

  const searchKeyOverride = (e) => {
    switch (e.keyCode) {
      case 9: // Tab
        break;
      case 27: // Esc
        // Cancel search
        setFocused(false);
        break;
      case 13: // Return
        if (topTag.id !== '' && topTag.name !== '') {
          handleFilter(topTag.name, topTag.id);
        }
        break;
      case 38: // Up
      case 40: // Down
      default:
        setFocused(true);
        break;
    }
  };

  const cancelSearch = (e) => {
    e.preventDefault();
    setSearchTerm('');
    document.getElementById('search-stack-input').focus();
  };

  const sortTags = (array) => {
    if (array.length === 0) {
      return [];
    }

    array.sort((a, b) => {
      const nameA = stackTags.byId[a].name.toLowerCase();
      const nameB = stackTags.byId[b].name.toLowerCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });

    return array;
  };

  const handleFilter = (name, tagId) => {
    if (isFiltered(tagId)) {
      notify(`Filter "${name}" removed.`);
      // Optimistic UI update, need to match w/ datastore
      const newFilterArray = sortTags(
        tagsInFilter.filter((tag) => tag !== tagId)
      );
      setTagsInFilter(newFilterArray);
      dispatch(stackActions.filter(newFilterArray));
    } else {
      notify(`Filter "${name}" added.`);
      // Optimistic UI update, need to match w/ datastore
      const newFilterArray = sortTags([...tagsInFilter, tagId]);
      setTagsInFilter(newFilterArray);
      dispatch(stackActions.filter(newFilterArray));
    }
    // reset field to nothing
    setSearchTerm('');
  };

  const handleSelect = (e, name, id) => {
    e.preventDefault();
    handleFilter(name, id);
  };

  // Needed to make sure focus happens on the button in Safari
  const handleSelectMouse = (e) => {
    e.preventDefault();
  };

  const resultsPreview = () => {
    const tl = tagsList();
    const fl = tagsInFilter;
    const showResults = searchTerm && tl.length !== 0;

    return (
      <div className="search-stack-results">
        <div className="search-stack-filters">{filterList()}</div>
        <div
          className={[
            'search-stack-results-header',
            showResults ? 'empty' : null,
          ].join(' ')}
        >
          {fl.length > 0
            ? `${fl.length} filter${fl.length > 1 ? 's. ' : '. '}`
            : ''}
          {showResults
            ? `${tl.length} result${tl.length > 1 ? 's.' : '.'}`
            : searchTerm.length > 0
            ? 'No results.'
            : 'Enter tag.'}
        </div>
        {showResults ? (
          <div className="search-stack-results-body">
            <ul className="tags-list">{tl}</ul>
          </div>
        ) : null}
      </div>
    );
  };

  const showResults = () => {
    setFocused(true);
  };

  const hideResults = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setFocused(false);
    }
  };

  const isFiltered = (tagId) => {
    return tagsInFilter.includes(tagId);
  };

  const tagsList = () => {
    if (!stackTags) {
      return null;
    }

    const result = [];
    let topTagTemp = null;
    stackTags.allIds.forEach((s, i) => {
      const p = stackTags.byId[s];

      if (!p.name.toLowerCase().includes(searchTerm.toLowerCase())) return;
      if (tagsInFilter.includes(p.id)) return; // Skip filtered.
      if ((topTagTemp === null) & (searchTerm !== '')) {
        topTagTemp = { name: p.name, id: p.id };
      }

      result.push(
        <li key={p.id}>
          <button
            onClick={(e) => handleSelect(e, p.name, p.id)}
            onMouseDown={(e) => handleSelectMouse(e)}
          >
            <div className="tag-header">{`${result.length + 1}`}</div>
            <div style={{ textAlign: 'left', width: '100%' }}>
              {addBold(p.name, searchTerm)}
              {` (${p.origamiLinks.items.length})`}
            </div>
            <div className="tag-footer" style={{ color: 'green' }}>
              {isFiltered(p.id) ? checkIcon : null}
            </div>
          </button>
        </li>
      );
    });

    if (topTagTemp !== null) {
      topTag = topTagTemp;
    }
    return result;
  };

  const addBold = (text, highlight) => {
    const start = text.toLowerCase().indexOf(highlight.toLowerCase());
    if (start === -1 || highlight.length === 0) {
      return text;
    }

    return (
      <>
        {text.substring(0, start)}
        <b>{text.substring(start, start + highlight.length)}</b>
        {text.substring(start + highlight.length)}
      </>
    );
  };

  const filterList = () => {
    return tagsInFilter && tagsInFilter.length > 0 ? (
      <ul className="selected-tags-list">
        {tagsInFilter.map((i) => {
          const p = stackTags.byId[i];
          return (
            <li key={p.id}>
              <button
                onClick={(e) => handleSelect(e, p.name, p.id)}
                onMouseDown={(e) => handleSelectMouse(e)}
              >
                <div
                  className="search-tag-header"
                  style={{ color: 'rgba(28, 182, 245, 1)' }}
                >
                  {taggedIcon}
                </div>
                <div className="search-tag-body">{`${p.name}`}</div>
                <div className="search-tag-footer"> {xIcon} </div>
              </button>
            </li>
          );
        })}
      </ul>
    ) : null;
  };

  return (
    <div
      className={['search-stack', focused ? 'searching' : 'icon-only'].join(
        ' '
      )}
      onFocus={showResults}
      onBlur={hideResults}
      onClick={showResults}
    >
      <div className="search-stack-header">{miniSearchIcon}</div>
      <div
        className={[
          'search-stack-input-container',
          focused ? null : 'hidden',
        ].join(' ')}
      >
        <input
          id="search-stack-input"
          placeholder="Search"
          onChange={handleSearch}
          value={searchTerm}
          autoCorrect="off"
          autoComplete="off"
          autoCapitalize="none"
          spellCheck="false"
          onKeyDown={searchKeyOverride}
        />
      </div>
      {searchTerm !== '' ? (
        <button onClick={cancelSearch}> {xIcon} </button>
      ) : focused ? (
        <div style={{ minWidth: '24px', margin: '0 12px' }} />
      ) : null}
      {/* true || */ focused ? resultsPreview() : null}
    </div>
  );
}

export default SearchSelector;
