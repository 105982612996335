export const stackConstants = {
  STACK_REQUEST: 'STACK_STACK_REQUEST',
  STACK_SUCCESS: 'STACK_STACK_SUCCESS',
  STACK_FAILURE: 'STACK_STACK_FAILURE',
  SORT_NAME_ASC: 'STACK_SORT_NAME_ASC',
  SORT_NAME_DESC: 'STACK_SORT_NAME_DESC',
  SORT_COUNT_ASC: 'STACK_SORT_COUNT_ASC',
  SORT_COUNT_DESC: 'STACK_SORT_COUNT_DESC',
  CREATE_REQUEST: 'STACK_CREATE_REQUEST',
  CREATE_SUCCESS: 'STACK_CREATE_SUCCESS',
  CREATE_FAILURE: 'STACK_CREATE_FAILURE',
  ADD_LINK: 'STACK_ADD_LINK',
  ADD_LINK_PENDING: 'STACK_ADD_LINK_PENDING',
  DELETE_LINK: 'STACK_DELETE_LINK',
  DELETE_OG_LINKS: 'STACK_DELETE_OG_LINKS',
  DELETE_OG_LINKS_FAILURE: 'STACK_DELETE_OG_LINKS_FAILURE',
  EDIT_REQUEST: 'STACK_EDIT_REQUEST',
  EDIT_SUCCESS: 'STACK_EDIT_SUCCESS',
  EDIT_FAILURE: 'STACK_EDIT_FAILURE',
  FILTER_UPDATE: 'STACK_FILTER_UPDATE_REQUEST',
};

export const initialStackState = {
  loaded: false,
  sort: stackConstants.SORT_NAME_DESC,
  filter: [],
  filteredStack: {},
};