import { sidebarConstants } from '../_constants';

export const sidebarActions = {
  show,
  hide,
  changeMenu
};

function show(selected = null) {
  return { type: sidebarConstants.SHOW, selected };
}

function hide() {
  return { type: sidebarConstants.HIDE };
}

function changeMenu(selected) {
  return { type: sidebarConstants.CHANGEMENU, selected };
}