import React from 'react';
import { Button } from 'react-bootstrap'
import TagsSelector from './tags-view-selector'
import { listIcon } from '../../helpers/icons'
import { useDispatch } from 'react-redux'
import { sidebarActions } from '../../../redux/_actions'


function TagsSidebarHeader(props) {
  const { title } = props
  const dispatch = useDispatch();

  return (
    <div className="stack-sidebar-header" >
      <Button variant='outline' onClick={() => dispatch(sidebarActions.hide())} >{listIcon}</Button>
      <span className='title'>{title}</span>
      <TagsSelector />
    </div>
  );
}


export default TagsSidebarHeader;