import React from 'react';
import ImageViewer from './image-viewer-sidebar';
import ModelMakerSidebarHeader from './model-maker-sidebar-header';
import { loading as LoadingOverlay } from '../../helpers/loading';
import { useSelector } from 'react-redux';
import { useMachine } from '@xstate/react';
import { oMachine } from './state-machine';

function ModelMaker() {
  const loading = useSelector((state) => state.maker.loading);
  const [current, send] = useMachine(oMachine);

  return (
    <div className="card">
      <ModelMakerSidebarHeader
        title={'Make 3D'}
        current={current}
        send={send}
      />
      <div className="card-body unselectable image-viewer">
        {loading ? LoadingOverlay : null}
        <ImageViewer current={current} send={send} />
      </div>
    </div>
  );
}

export default ModelMaker;
