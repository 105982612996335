import React, { useEffect, useState } from 'react';
import { notify } from '../../helpers/notification';
import { useHotkeys } from 'react-hotkeys-hook';
import TagsSidebarHeader from './tags-sidebar-header';
import TagsButtonCluster from './tags-button-cluster';
import {
  checkIcon,
  taggedIcon,
  miniSearchIcon,
  xIcon,
} from '../../helpers/icons';
import { useUser } from '../../../user-provider';
import { useDispatch, useSelector } from 'react-redux';
import {
  tagActions,
  stackActions,
  sidebarActions,
  alertActions,
  boxActions,
} from '../../../redux/_actions';
import { tagConstants, sidebarStates } from '../../../redux/_constants';

const modeConstants = {
  TAG: 'TAG',
  SET: 'SET',
  EDIT: 'EDIT',
  ADD: 'ADD',
};

function StackTags(props) {
  const [toggleAdd, setToggleAdd] = useState(false);
  const [toggleEdit, setToggleEdit] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [editData, setEditData] = useState({ id: '', name: '' });
  const [mode, setMode] = useState(modeConstants.TAG);
  const [tagsInSelected, setTagsInSelected] = useState([]);
  const { user } = useUser();
  const dispatch = useDispatch();
  const tag = useSelector((state) => state.tag);
  const box = useSelector((state) => state.box);
  const sidebar = useSelector((state) => state.sidebar);
  const stackTags = useSelector((state) => state.stack.tags);
  const stackLinks = useSelector((state) => state.stack.links);
  let topTag = { id: '', name: '' };

  /*
  useEffect(() => {
    const subNewStacks = API.graphql(graphqlOperation(SubscribeToNewStacks, { owner: user.username }))
    .subscribe({
      next: e => {
        const stack = e.value.data.onCreateStack;
        dispatch(stackActions.addStack(stack));
        dispatch(tagActions.select(stack.name, stack.id, {}, []));
      }, 
      error: (error) => {console.log('New stacks subscribe error', error)}
    });
    return () => subNewStacks.unsubscribe();
  }, [user.username, dispatch])
  */

  useEffect(() => {
    if (sidebar.show && sidebar.selected === sidebarStates.TAGS) {
      document.getElementById('search-tags-input').focus();
    }
  }, [sidebar]);

  useEffect(() => {
    const sort = (array) => {
      if (array.length === 0) {
        return [];
      }

      array.sort((a, b) => {
        const nameA = stackTags.byId[a].name.toLowerCase();
        const nameB = stackTags.byId[b].name.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });

      return array;
    };
    const result = [];

    const selected =
      box.origamis.byId &&
      box.status.selected &&
      box.origamis.byId[box.status.selected];

    selected &&
      selected.origamiLinks.items.map((l) =>
        result.push(stackLinks.byId[l].stackId)
      );
    setTagsInSelected(sort(result));
  }, [box.status.selected, box.origamis.byId, stackLinks.byId, stackTags.byId]);

  /*
  useEffect(() => {
    const subNewLinks = API.graphql(graphqlOperation(SubscribeToNewLinks, { owner: user.username }))
    .subscribe({
      next: e => {
        const link = e.value.data.onCreateOrigamiLink;
        console.log("from subscription")
        console.log(performance.now());
        dispatch(stackActions.addLink(link));
      },
      error: (error) => {console.log('New links subscribe error', error)}
    });
    return () => subNewLinks.unsubscribe();
  }, [user.username, dispatch])


  useEffect(() => {
    const subUpdateLinks = API.graphql(graphqlOperation(SubscribeToUpdateLinks, { owner: user.username }))
    .subscribe({
      next: e => {
        const link = e.value.data.onUpdateOrigamiLink;
        dispatch(stackActions.deleteLink(link));
      },
      error: (error) => {console.log('Update links subscribe error', error)}
    });
    return () => subUpdateLinks.unsubscribe();
  }, [user.username, dispatch])
  */

  const toggleShowAdd = () => {
    if (toggleAdd) {
      setMode(modeConstants.TAG);
    }
    setToggleAdd(!toggleAdd);
  };

  const toggleShowEdit = () => {
    if (toggleEdit) {
      setMode(modeConstants.TAG);
    }
    setToggleEdit(!toggleEdit);
  };

  const sortTags = (array) => {
    if (array.length === 0) {
      return [];
    }

    array.sort((a, b) => {
      const nameA = stackTags.byId[a].name.toLowerCase();
      const nameB = stackTags.byId[b].name.toLowerCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });

    return array;
  };

  const addStackTag = (name) => {
    if (!user?.boxes) {
      dispatch(
        alertActions.error('Missing box reference error.  Please reload.')
      );
      toggleShowAdd();
      return;
    }

    toggleShowAdd();
    const boxId = user.boxes.items.find(
      (element) => element.name === 'blackbox'
    ).id;
    dispatch(stackActions.create(boxId, name));
  };

  const editStackTag = (name, id) => {
    toggleShowEdit();
    dispatch(stackActions.edit(name, id));
  };

  const handleEdit = (name, id) => {
    toggleShowEdit();
    setEditData({ name: name, id: id });
  };

  useHotkeys(
    'a',
    (e) => {
      e.preventDefault();
      toggleShowAdd();
    },
    []
  );

  useHotkeys(
    'e',
    (e) => {
      e.preventDefault();
      toggleShowEdit();
    },
    []
  );

  const handleSelect = (e, name, id) => {
    e.preventDefault();

    // Modes: Select for tag button, toggle tag, edit tag
    switch (mode) {
      case modeConstants.TAG:
        handleTag(name, id);
        break;
      case modeConstants.SET:
        if (tag.id === id) {
          break;
        }
        setMode(modeConstants.TAG);
        dispatch(
          tagActions.select(name, id, stackLinks.byId, stackLinks.allIds)
        );
        break;
      case modeConstants.EDIT:
        handleEdit(name, id);
        break;
      default:
        break;
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
  };

  const searchKeyOverride = (e) => {
    switch (e.keyCode) {
      case 9: // Tab
      case 27: // Esc
        sidebar.show
          ? dispatch(sidebarActions.hide())
          : dispatch(sidebarActions.show());
        break;
      case 13: // Return
        if (topTag.id !== '' && topTag.name !== '') {
          handleTag(topTag.name, topTag.id);
        }
        break;
      case 38: // Up
        dispatch(boxActions.moveUp());
        break;
      case 40: // Down
        dispatch(boxActions.moveDown());
        break;
      default:
        break;
    }
  };

  const handleTag = (name, tagId) => {
    const ogId = box.status.selected;
    const items = tagId === tag.id ? tag.items : null;
    const queueId = `${ogId}+${tagId}`;
    let linkId = null;

    if (isTagged(tagId)) {
      notify(`Tag ${name} removed.`);
      // Optimistic UI update, need to match w/ datastore
      const newTagsArray = sortTags(
        tagsInSelected.filter((tag) => tag !== tagId)
      );
      setTagsInSelected(newTagsArray);
      if (
        tag.tagQueue.hasOwnProperty(queueId) &&
        tag.tagQueue[queueId].type === tagConstants.TAG_REQUEST
      ) {
        linkId = tagConstants.TAG_REQUEST;
      } else {
        linkId =
          tagId === tag.id
            ? tag.items[ogId]
            : stackTags.byId[tagId].origamiLinks.items.find(
                (link) => ogId === stackLinks.byId[link].origamiId
              );
      }

      dispatch(tagActions.untag(ogId, linkId, tagId, items));
    } else {
      notify(`Tag ${name} you're it!`);
      // Optimistic UI update, need to match w/ datastore
      const newTagsArray = sortTags([...tagsInSelected, tagId]);
      setTagsInSelected(newTagsArray);
      dispatch(tagActions.tag(ogId, tagId, items));
    }
  };

  const isTagged = (tagId) => {
    return tagsInSelected.includes(tagId);
  };

  const isAutoTag = (id) => {
    return tag.id === id;
  };

  const cancelSearch = () => {
    setSearchTerm('');
    document.getElementById('search-tags-input').focus();
  };

  const addBold = (text, highlight) => {
    const start = text.toLowerCase().indexOf(highlight.toLowerCase());
    if (start === -1 || highlight.length === 0) {
      return text;
    }

    return (
      <>
        {text.substring(0, start)}
        <b>{text.substring(start, start + highlight.length)}</b>
        {text.substring(start + highlight.length)}
      </>
    );
  };

  const tagsList = () => {
    if (!stackTags) {
      return null;
    }

    const result = [];
    let topTagTemp = null;
    stackTags.allIds.forEach((s, i) => {
      const p = stackTags.byId[s];

      if (!p.name.toLowerCase().includes(searchTerm.toLowerCase())) return;
      if ((topTagTemp === null) & (searchTerm !== '')) {
        topTagTemp = { name: p.name, id: p.id };
      }

      result.push(
        <li key={p.id}>
          <button onClick={(e) => handleSelect(e, p.name, p.id)}>
            <div
              className="tag-header"
              style={{
                color: isAutoTag(p.id) ? 'rgba(28, 182, 245, 1)' : null,
              }}
            >
              {isAutoTag(p.id) ? taggedIcon : `${i + 1}`}
            </div>
            <div className="tag-body">
              {addBold(p.name, searchTerm)}
              {` (${p.origamiLinks.items.length})`}
            </div>
            <div className="tag-footer" style={{ color: 'green' }}>
              {isTagged(p.id) ? checkIcon : null}
            </div>
          </button>
        </li>
      );
    });

    if (result.length === 0) {
      return (
        <li key={'empty'}>
          <div style={{ display: 'flex' }}>
            <div className="tag-header" />
            <div
              style={{
                textAlign: 'left',
                width: '100%',
                fontStyle: 'italic',
                color: 'rgba(150,150,150,1)',
              }}
            >
              No tags found...
            </div>
            <div className="tag-footer" />
          </div>
        </li>
      );
    }

    if (topTagTemp !== null) {
      topTag = topTagTemp;
    }
    return result;
  };

  return (
    <React.Fragment>
      <TagsSidebarHeader
        title={`Tags (${stackLinks ? stackLinks.allIds.length : 0})`}
      />
      <div className="box-deck-container">
        <div className="search-tags-list">
          <div className="search-tags-header">{miniSearchIcon}</div>
          <div className="search-tags-input-container">
            <input
              id="search-tags-input"
              placeholder="Search tags..."
              onChange={handleSearch}
              value={searchTerm}
              autoCorrect="off"
              autoComplete="off"
              autoCapitalize="none"
              spellCheck="false"
              onKeyDown={searchKeyOverride}
            />
          </div>
          {searchTerm !== '' ? (
            <button onClick={cancelSearch}> {xIcon} </button>
          ) : null}
        </div>
        {tagsInSelected && tagsInSelected.length > 0 ? (
          <ul className="selected-tags-list">
            {tagsInSelected.map((i) => {
              const p = stackTags.byId[i];
              return (
                <li key={p.id}>
                  <button onClick={(e) => handleSelect(e, p.name, p.id)}>
                    <div
                      className="tag-header"
                      style={{
                        color: isAutoTag(p.id) ? 'rgba(28, 182, 245, 1)' : null,
                      }}
                    >
                      {isAutoTag(p.id) ? taggedIcon : null}
                    </div>
                    <div className="tag-body">{`${p.name}`}</div>
                    <div className="tag-footer" style={{ color: 'green' }}>
                      {checkIcon}
                    </div>
                  </button>
                </li>
              );
            })}
          </ul>
        ) : null}
        <ul className="tags-list">{tagsList()}</ul>
      </div>
      <TagsButtonCluster
        addStackTag={addStackTag}
        toggleAdd={toggleAdd}
        toggleShowAdd={toggleShowAdd}
        toggleEdit={toggleEdit}
        toggleShowEdit={toggleShowEdit}
        editStackTag={editStackTag}
        editData={editData}
        setMode={setMode}
        mode={mode}
        modeConstants={modeConstants}
      />
    </React.Fragment>
  );
}

export default StackTags;
