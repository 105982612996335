import { Machine, actions } from 'xstate'
const { assign } = actions


export const oMachine = Machine({
    id: 'fetch',
    initial: 'start',
    context: {
      images: 0
    },
    states: {
      start: {
        on: {
          ADDONE: {
            target: 'single',
            actions: assign({
              images: (context, event) =>
                context.images + 1
            })
          }, 
          ADDTWO: {
            target: 'double',
            actions: assign({
              images: (context, event) =>
                context.images + 2
            })
          }
        }
      },
      single: {
        on: {
          ADDONE: {
            target: 'double',
            actions: assign({
              images: (context, event) =>
                context.images = context.images === 1 ? 2 : 1 
            })
          },
          ADDTWO: {
            target: 'double',
            actions: assign({
              images: (context, event) =>
                context.images = 2
            })
          },
          RESET: {
            target: 'start',
            actions: assign({
              images: (context, event) =>
                context.images = 0
            })
          }
        }
      },
      double: {
        on: {
          ADDONE: {
            target: 'single',
            actions: assign({
              images: (context, event) =>
                context.images = 1
            })
          },
          ADDTWO: {
            target: 'double',
            actions: assign({
              images: (context, event) =>
                context.images = 2
            })
          },
          CREATE: 'creating'
        }
      },
      creating: {
        on: {
          COMPLETE: 'complete',
          ERROR: 'double'
        }
      },
      complete: {
        on: {
          ADDONE: {
            target: 'single',
            actions: assign({
              images: (context, event) =>
                context.images = 1
            })
          },
          ADDTWO: {
            target: 'double',
            actions: assign({
              images: (context, event) =>
                context.images = 2
            })
          },
          UPDATE: 'creating'
        }
      },
    }
  });
  