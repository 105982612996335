import { stackConstants, tagConstants } from '../_constants';
import { stackService, tagService } from '../_services';
import { stackUtilities } from '../_utilities';
import { alertActions, boxActions } from './';
import { normalize, schema } from 'normalizr';
import { store } from '../store';

export const stackActions = {
  load,
  sort,
  addStack,
  create,
  addLink,
  deleteLink,
  deleteLinksForOrigami,
  edit,
  filter,
};

// Normalized data schema
const link = new schema.Entity('links');
const stack = new schema.Entity('stacks', {
  origamiLinks: {
    items: [link],
  },
});

function sort(sortBy, byId, allIds, newItem = null) {
  const sortedResult = stackUtilities.sort(sortBy, byId, allIds, newItem);

  return { type: sortBy, result: sortedResult };
}

function filter(filterList) {
  return (dispatch) => {
    // Generate ogId list that matches filter criteria
    const stackLinks = store.getState().stack.links;
    const selected = store.getState().box.status.selected;
    const items = stackUtilities.filter(
      filterList,
      stackLinks.byId,
      stackLinks.allIds,
      'stackId',
      'origamiId'
    );

    // Updated selected in Box & sort if necessary
    if (filterList.length > 0 && !Object.keys(items).includes(selected)) {
      dispatch(boxActions.select(Object.keys(items)[0]));
    }

    dispatch(success(filterList, items));
  };

  function success(list, items) {
    return {
      type: stackConstants.FILTER_UPDATE,
      filter: list,
      filteredStack: items,
    };
  }
}

function edit(name, id) {
  return (dispatch) => {
    // Optimistic add to normalize data structure
    const { byId, allIds } = store.getState().stack.tags;
    const { status } = store.getState().stack;
    byId[id].name = name;
    const newById = Object.assign(byId);
    const sortedResult = sort(status.sort, newById, allIds);
    const result = { byId: newById, allIds: sortedResult.result };
    dispatch(request(result));

    stackService
      .edit(id, name)
      .then((res) => {
        dispatch(success());
      })
      .catch((error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      });
  };

  function request(result) {
    return { type: stackConstants.EDIT_REQUEST, result };
  }
  function success() {
    return { type: stackConstants.EDIT_SUCCESS };
  }
  function failure(error) {
    return { type: stackConstants.EDIT_FAILURE };
  }
}

function addStack(stack) {
  const { status, tags } = store.getState().stack;
  return sort(status.sort, tags.byId, tags.allIds, stack);
}

function addLink(link) {
  const { links, tags } = store.getState().stack;
  let result = links;

  // For follow up API data update
  if (link.id !== tagConstants.TAG_REQUEST) {
    const newAllIds = links.allIds.filter(
      (id) => id !== tagConstants.TAG_REQUEST
    );
    newAllIds.push(link.id);
    const newById = Object.assign(links.byId, { [link.id]: link });
    result = { byId: newById, allIds: newAllIds };
    const index = tags.byId[link.stackId].origamiLinks.items.indexOf(
      tagConstants.TAG_REQUEST
    );
    if (index !== -1) {
      tags.byId[link.stackId].origamiLinks.items[index] = link.id;
    }
  } else {
    // Handles optimistic UI update, placeholder of TAG_REQUEST
    tags.byId[link.stackId].origamiLinks.items.push(link.id);
    links.allIds.push(link.id);
    return {
      type: stackConstants.ADD_LINK_PENDING,
      links: result,
      stacks: tags,
    };
  }

  return { type: stackConstants.ADD_LINK, links: result, stacks: tags };
}

function deleteLink(link) {
  const { links, tags } = store.getState().stack;
  const newAllIds = links.allIds.filter((id) => id !== link.id);
  delete links.byId[link.id];
  const newById = Object.assign(links.byId);
  const result = { byId: newById, allIds: newAllIds };
  const newTags = tags.byId[link.stackId].origamiLinks.items.filter(
    (id) => id !== link.id
  );
  tags.byId[link.stackId].origamiLinks.items = newTags;

  return { type: stackConstants.DELETE_LINK, links: result, stacks: tags };
}

function deleteLinksForOrigami(og) {
  return (dispatch) => {
    const { links, tags } = store.getState().stack;

    // Find all links w/ origamiId
    const linkIds = og.origamiLinks.items;
    const newAllIds = links.allIds.filter((id) => !linkIds.includes(id));

    // Find all stack tags that reference above links
    linkIds.forEach((id) => {
      const tagId = links.byId[id].stackId;
      const newTags = tags.byId[tagId].origamiLinks.items.filter(
        (el) => el !== id
      );
      tags.byId[tagId].origamiLinks.items = newTags;
      delete links.byId[id];
      tagService.untag(id).catch((error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      });
    });

    const newByIds = Object.assign(links.byId);
    const result = { byId: newByIds, allIds: newAllIds };
    dispatch(success(result, tags));
  };

  function success(result, tags) {
    return {
      type: stackConstants.DELETE_OG_LINKS,
      links: result,
      stacks: tags,
    };
  }
  function failure(error) {
    return { type: stackConstants.DELETE_OG_LINKS_FAILURE };
  }
}

function create(boxId, name) {
  return (dispatch) => {
    // Optimistic add to normalize data structure
    const { byId } = store.getState().stack.tags;
    const newTag = {
      id: stackConstants.CREATE_REQUEST,
      name: name,
      origamiLinks: { items: [] },
    };
    const newById = Object.assign(byId, {
      [stackConstants.CREATE_REQUEST]: newTag,
    });
    const sortedResult = addStack(newTag);
    const result = { byId: newById, allIds: sortedResult.result };
    dispatch(request(result));

    stackService
      .create(boxId, name)
      .then((res) => {
        const { byId, allIds } = store.getState().stack.tags;
        const apiTag = res.data.createStack;
        const index = allIds.indexOf(stackConstants.CREATE_REQUEST);
        const newAllIds = [...allIds];
        if (index !== -1) {
          newAllIds[index] = apiTag.id;
        } else {
          newAllIds.push(apiTag.id);
        }

        delete byId[stackConstants.CREATE_REQUEST];
        const newById = Object.assign(byId, { [apiTag.id]: apiTag });
        const apiResult = { byId: newById, allIds: newAllIds };
        dispatch(success(apiResult));
      })
      .catch((error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      });
  };

  function request(result) {
    return { type: stackConstants.CREATE_REQUEST, result };
  }
  function success(result) {
    return { type: stackConstants.CREATE_SUCCESS, result };
  }
  function failure(error) {
    return { type: stackConstants.CREATE_FAILURE };
  }
}

function load() {
  return (dispatch) => {
    dispatch(request());

    stackService
      .load()
      .then((res) => {
        const normalizedStacks = normalize(res[0], [stack]);
        const normalizedLinks = normalize(res[1], [link]);
        const sortedResult = stackUtilities.sort(
          stackConstants.SORT_NAME_DESC,
          normalizedStacks.entities.stacks,
          normalizedStacks.result
        );
        const sortedStacks = { ...normalizedStacks, result: sortedResult };
        dispatch(success({ stacks: sortedStacks, links: normalizedLinks }));
      })
      .catch((error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      });
  };

  function request() {
    return { type: stackConstants.STACK_REQUEST };
  }
  function success(data) {
    return { type: stackConstants.STACK_SUCCESS, data };
  }
  function failure(error) {
    return { type: stackConstants.STACK_FAILURE };
  }
}
