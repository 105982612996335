/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const scanOrigamis = /* GraphQL */ `
  query ScanOrigamis {
    scanOrigamis {
      items {
        id
        username
        createdAt
        updatedAt
        status
      }
    }
  }
`;
export const getOrigami = /* GraphQL */ `
  query GetOrigami($id: ID!) {
    getOrigami(id: $id) {
      id
      username
      createdAt
      updatedAt
      status
      params {
        frontImage
        backImage
        rotate
        thickness
        radius
        edge
        width
        height
      }
      origamiLinks {
        nextToken
      }
    }
  }
`;
export const listOrigamis = /* GraphQL */ `
  query ListOrigamis(
    $filter: ModelOrigamiFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrigamis(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        createdAt
        updatedAt
        status
      }
      nextToken
    }
  }
`;
export const getBox = /* GraphQL */ `
  query GetBox($id: ID!) {
    getBox(id: $id) {
      id
      name
      type
      stacks {
        nextToken
      }
      private
      ownerId
      createdAt
      updatedAt
      status
    }
  }
`;
export const listBoxs = /* GraphQL */ `
  query ListBoxs(
    $filter: ModelBoxFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBoxs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        type
        private
        ownerId
        createdAt
        updatedAt
        status
      }
      nextToken
    }
  }
`;
export const getStack = /* GraphQL */ `
  query GetStack($id: ID!) {
    getStack(id: $id) {
      id
      origamiLinks {
        nextToken
      }
      box {
        id
        name
        type
        private
        ownerId
        createdAt
        updatedAt
        status
      }
      boxId
      name
      members
      private
      createdAt
      updatedAt
      status
      owner
    }
  }
`;
export const listStacks = /* GraphQL */ `
  query ListStacks(
    $filter: ModelStackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStacks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        boxId
        name
        members
        private
        createdAt
        updatedAt
        status
        owner
      }
      nextToken
    }
  }
`;
export const getOrigamiLink = /* GraphQL */ `
  query GetOrigamiLink($id: ID!) {
    getOrigamiLink(id: $id) {
      id
      origamiId
      origami {
        id
        username
        createdAt
        updatedAt
        status
      }
      createdAt
      updatedAt
      stackId
      stack {
        id
        boxId
        name
        members
        private
        createdAt
        updatedAt
        status
        owner
      }
      status
      owner
    }
  }
`;
export const listOrigamiLinks = /* GraphQL */ `
  query ListOrigamiLinks(
    $filter: ModelOrigamiLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrigamiLinks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        origamiId
        createdAt
        updatedAt
        stackId
        status
        owner
      }
      nextToken
    }
  }
`;
export const getGift = /* GraphQL */ `
  query GetGift($id: ID!) {
    getGift(id: $id) {
      id
      recipients
      giverId
      origamiId
      createdAt
      updatedAt
      status
    }
  }
`;
export const listGifts = /* GraphQL */ `
  query ListGifts(
    $filter: ModelGiftFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGifts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        recipients
        giverId
        origamiId
        createdAt
        updatedAt
        status
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      boxes {
        nextToken
      }
      origamis {
        nextToken
      }
      gifts {
        nextToken
      }
      createdAt
      updatedAt
      status
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        createdAt
        updatedAt
        status
      }
      nextToken
    }
  }
`;
export const origamisByUsername = /* GraphQL */ `
  query OrigamisByUsername(
    $username: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrigamiFilterInput
    $limit: Int
    $nextToken: String
  ) {
    origamisByUsername(
      username: $username
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        createdAt
        updatedAt
        status
      }
      nextToken
    }
  }
`;
