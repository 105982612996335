import React from 'react';
import { ForgotPassword } from "aws-amplify-react";
import { I18n } from 'aws-amplify';
import {
	FormSection,
	FormField,
	SectionHeader,
	SectionBody,
	SectionFooter,
	Button,
	Link,
	Input,
	SectionFooterPrimaryContent,
	SectionFooterSecondaryContent,
} from 'aws-amplify-react/lib-esm/Amplify-UI/Amplify-UI-Components-React'


import { auth } from 'aws-amplify-react/lib-esm/Amplify-UI/data-test-attributes';



class CustomForgotPassword extends ForgotPassword {
  	constructor(props: any) {
    	super(props);
    	this._validAuthStates = ["forgotPassword"];
  	}

	sendView() {
		const theme = this.props.theme;

		return (
			<div>
				<FormField theme={theme}>
					<Input
						defaultValue={this.state.username}
						autoFocus={true}
						placeholder={I18n.get('Enter your username')}
						theme={theme}
						key="username"
						name="username"
						onChange={this.handleInputChange}
						data-test={auth.genericAttrs.usernameInput}
						autoCapitalize="off"
					/>
				</FormField>		
			</div>
		);
	}

	showComponent(theme) {
		const { authData = {} } = this.props;
		
		return (
			<FormSection theme={theme} data-test={auth.forgotPassword.section}>
				<SectionHeader
					theme={theme}
					data-test={auth.forgotPassword.headerSection}
				>
					{I18n.get('Reset your password')}
				</SectionHeader>
				<SectionBody theme={theme} data-test={auth.forgotPassword.bodySection}>
					{this.state.delivery || authData.username
						? this.submitView()
						: this.sendView()}
				</SectionBody>
				<SectionFooter theme={theme}>
					<SectionFooterPrimaryContent theme={theme}>
						{this.state.delivery || authData.username ? (
							<Button
								theme={theme}
								onClick={this.submit}
								data-test={auth.forgotPassword.submitButton}
							>
								{I18n.get('Submit')}
							</Button>
						) : (
							<Button
								theme={theme}
								onClick={this.send}
								data-test={auth.forgotPassword.sendCodeButton}
							>
								{I18n.get('Send Code')}
							</Button>
						)}
					</SectionFooterPrimaryContent>
					<SectionFooterSecondaryContent theme={theme}>
						{this.state.delivery || authData.username ? (
							<Link
								theme={theme}
								onClick={this.send}
								data-test={auth.forgotPassword.resendCodeLink}
							>
								{I18n.get('Resend Code')}
							</Link>
						) : (
							<Link
								theme={theme}
								onClick={() => this.changeState('signIn')}
								data-test={auth.forgotPassword.backToSignInLink}
							>
								{I18n.get('Back to Sign In')}
							</Link>
						)}
					</SectionFooterSecondaryContent>
				</SectionFooter>
			</FormSection>
		);
	}
}

export default CustomForgotPassword;	