import { API, graphqlOperation } from 'aws-amplify';
import { createOrigamiLink, deleteOrigamiLink, getStack } from '../../graphql/graphql'


export const tagService = {
  tag,
  untag,
  load,
};


function tag(id, stackId) { 
  return API.graphql(graphqlOperation(createOrigamiLink, { origamiId: id, stackId: stackId }));
}


function untag(linkId) {
  return API.graphql(graphqlOperation(deleteOrigamiLink, { id: linkId } ));
}


function load(id) {
  return API.graphql(graphqlOperation(getStack, { id: id }));
}