import React, { useState, useCallback } from 'react';
import { Modal, Button } from 'react-bootstrap'


const InputComponent = (name, handler, saveHandler) => {
    const autoFocus = useCallback(el => el ? el.focus() : null, [])

    return (
      <input 
        type="text" 
        ref={autoFocus} 
        value={name} 
        className="form-control" 
        onChange={handler} 
        onKeyPress={(e) => e.key === "Enter" ? saveHandler(e) : null}
      />
    )
}


function AddStacks(props) {
  const [name, setName] = useState('')
  const { toggleAdd, toggleShowAdd, addStackTag } = props;

  const handleChangeInput = (e) => { 
    setName(e.target.value)
    //console.log(e)
  }

  const saveInput = (e) => {
    e.preventDefault();
    const target = name;
    setName('')
    addStackTag(target)
  }

  return (
    <Modal 
      show={toggleAdd} 
      onHide={toggleShowAdd}
      centered
      size="sm"
      className="add-stacks-modal"
      animation={false}
    >
        <Modal.Header closeButton>
          <Modal.Title>Create Tag</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { InputComponent(name, handleChangeInput, saveInput) }
        </Modal.Body>
        <Modal.Footer>
          <Button className="save-button" variant="primary" onClick={saveInput}>
            Save
          </Button>
        </Modal.Footer>
    </Modal>
  )
}

export default AddStacks;