import { tagConstants, initialTagState } from '../_constants';


export function tag(state = initialTagState, action) {
  switch(action.type) {
    case tagConstants.SELECT: 
      return { ...state, name: action.name, id: action.id, items: action.items };
    case tagConstants.TAG_REQUEST:
      return { 
        ...state, 
        tagging: true, 
        items: action.items !== null ? action.items : state.items,
        tagQueue: action.tagQueue
      };
    case tagConstants.TAG_SUCCESS:
      return { 
        ...state, 
        tagging: false, 
        isTagged: true, 
        items: action.items !== null ? action.items : state.items,
        tagQueue: action.nextQueue
      };
    case tagConstants.TAG_FAILURE:
      return { ...state, tagging: false };
    case tagConstants.UNTAG_REQUEST: 
      return { 
        ...state, 
        tagging: true, 
        items: action.items !== null ? action.items : state.items,
        tagQueue: action.tagQueue
      };
    case tagConstants.UNTAG_SUCCESS:
      return { ...state, tagging: false, isTagged: false };
    case tagConstants.UNTAG_FAILURE:
      return { ...state, tagging: false };
    case tagConstants.UNTAG_CANCELED:
      return { ...state, tagging: false };
    case tagConstants.STACK_REQUEST:
      return { ...state, loaded: false };
    case tagConstants.STACK_SUCCESS:
      return { ...state, loaded: true, id: action.data.id, name: action.data.name, items: action.data.origamiLinks.items };
    case tagConstants.STACK_FAILURE:
      return { ...state, loaded: false };
    default: 
      return state;
  }
}