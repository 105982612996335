import { combineReducers } from "redux";
import { stackConstants, initialStackState } from "../_constants";

function status(state = initialStackState, action) {
  switch (action.type) {
    case stackConstants.STACK_REQUEST:
      return { ...state, loaded: false };
    case stackConstants.STACK_SUCCESS:
      return { ...state, loaded: true };
    case stackConstants.STACK_FAILURE:
      return { ...state, loaded: false };
    case stackConstants.SORT_NAME_DESC:
    case stackConstants.SORT_NAME_ASC:
    case stackConstants.SORT_COUNT_DESC:
    case stackConstants.SORT_COUNT_ASC:
      return { ...state, sort: action.type };
    case stackConstants.FILTER_UPDATE:
      return {
        ...state,
        filter: action.filter,
        filteredStack: action.filteredStack,
      };
    default:
      return state;
  }
}

function stacksById(state = [], action) {
  switch (action.type) {
    case stackConstants.STACK_SUCCESS:
      return action.data.stacks.entities.stacks || state;
    case stackConstants.ADD_LINK_PENDING:
    case stackConstants.ADD_LINK:
    case stackConstants.DELETE_LINK:
    case stackConstants.DELETE_OG_LINKS:
      return action.stacks.byId || state;
    case stackConstants.CREATE_REQUEST:
    case stackConstants.CREATE_SUCCESS:
    case stackConstants.EDIT_REQUEST:
      return action.result.byId || state;
    default:
      return state;
  }
}

function linksById(state = [], action) {
  switch (action.type) {
    case stackConstants.STACK_SUCCESS:
      return action.data.links.entities.links || state;
    case stackConstants.ADD_LINK_PENDING:
    case stackConstants.ADD_LINK:
    case stackConstants.DELETE_LINK:
    case stackConstants.DELETE_OG_LINKS:
      return action.links.byId || state;
    default:
      return state;
  }
}

function allStacks(state = [], action) {
  switch (action.type) {
    case stackConstants.STACK_SUCCESS:
      return action.data.stacks.result || state;
    case stackConstants.SORT_NAME_DESC:
    case stackConstants.SORT_NAME_ASC:
    case stackConstants.SORT_COUNT_DESC:
    case stackConstants.SORT_COUNT_ASC:
      return action.result || state;
    case stackConstants.ADD_LINK_PENDING:
    case stackConstants.ADD_LINK:
    case stackConstants.DELETE_LINK:
    case stackConstants.DELETE_OG_LINKS:
      return action.stacks.allIds || state;
    case stackConstants.CREATE_REQUEST:
    case stackConstants.CREATE_SUCCESS:
    case stackConstants.EDIT_REQUEST:
      return action.result.allIds || state;
    default:
      return state;
  }
}

function allLinks(state = [], action) {
  switch (action.type) {
    case stackConstants.STACK_SUCCESS:
      return action.data.links.result || state;
    case stackConstants.ADD_LINK_PENDING:
    case stackConstants.ADD_LINK:
    case stackConstants.DELETE_LINK:
    case stackConstants.DELETE_OG_LINKS:
      return action.links.allIds || state;
    default:
      return state;
  }
}

const tags = combineReducers({
  byId: stacksById,
  allIds: allStacks,
});

const links = combineReducers({
  byId: linksById,
  allIds: allLinks,
});

export const stack = combineReducers({
  status: status,
  tags: tags,
  links: links,
});
