import axios from 'axios';
import { API, graphqlOperation } from 'aws-amplify';
import { createOrigami } from '../../graphql/graphql';

export const makerService = {
  getUploadToken,
  uploadFiles,
  addOrigami,
  getPhotos,
};

function getUploadToken(data) {
  return axios.post('/api/upload', data, {}).catch((error) => {
    console.log(error);
    return error;
  });
}

function uploadFiles(data, payload) {
  const { urls } = data;
  const frontData = new FormData();
  const backData = new FormData();
  let uploadFront = null;
  let uploadBack = null;

  if (urls.front !== '') {
    Object.keys(urls.front.fields).forEach((key) => {
      frontData.append(key, urls.front.fields[key]);
    });
    frontData.append('Content-Type', payload[0].type);
    frontData.append('file', payload[0]); // file must be last
    uploadFront = true;
  }

  if (urls.back !== '') {
    Object.keys(urls.back.fields).forEach((key) => {
      backData.append(key, urls.back.fields[key]);
    });
    backData.append('Content-Type', payload[1].type);
    backData.append('file', payload[1]);
    uploadBack = true;
  }

  return Promise.all([
    data,
    uploadFront ? axios.post(urls.front.url, frontData, {}) : null,
    uploadBack ? axios.post(urls.back.url, backData, {}) : null,
  ]);
}

function addOrigami(id, data) {
  const filename = data.getAll('filename');
  const rotate = data.getAll('rotate');

  // data for Origami
  const inputData = {
    id: id,
    username: data.get('username'),
    status: 'created',
    params: {
      frontImage: filename[0],
      backImage: filename[1],
      rotate: rotate,
      thickness: data.get('thickness'),
      radius: data.get('radius'),
      edge: data.get('edge'),
      width: data.get('width'),
      height: data.get('height'),
    },
  };

  // data for OrigamiLink
  /*const linkData = {
    origamiId: res,
    stackId: user.boxes.items
      .find(e => e.name === "blackbox").stacks.items
      .find(e => e.name === "unsorted").id
  }
  */

  return Promise.all([
    API.graphql(graphqlOperation(createOrigami, { input: inputData })),
    //API.graphql(graphqlOperation(createOrigamiLink, { input: linkData }))
  ]);
}

function getPhotos(data) {
  const id = data.id;
  const front = data.params.frontImage;
  const back = data.params.backImage;

  return Promise.all([
    data,
    front ? axios.get(`/api/download/${id}/front/${front}`) : null,
    back ? axios.get(`/api/download/${id}/back/${back}`) : null,
  ]);
}
