import { useEffect, useState, useCallback } from 'react';
import { Spinner, Placeholder } from 'react-bootstrap';

export const loading = (
  <div className="loadingSpinner">
    <Spinner animation="border" role="status" variant="secondary">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  </div>
);

const generateRandomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min) + min);
};

export const loadingStack = (
  <div className="loading">
    <ul>
      {Array.from({ length: generateRandomNumber(20, 50) }, () =>
        generateRandomNumber(50, 75)
      ).map((val, i) => {
        const height = generateRandomNumber(3, 7);

        return (
          <li key={`loading-stack-${i}`}>
            <div
              style={{
                width: `${val}%`,
                height: `${height}px`,
              }}
            >
              <Placeholder animation="wave" style={{ fontSize: `${height}px` }}>
                <Placeholder bg="light" />
              </Placeholder>
            </div>
          </li>
        );
      })}
    </ul>
    <div className="stack-base" />
  </div>
);

export function LoadingProgressiveStack() {
  const [stack, setStack] = useState([]);

  const newLayer = useCallback(() => {
    const height = generateRandomNumber(3, 7);
    return (
      <li key={`loading-stack-${stack.length + 1}`}>
        <div
          style={{
            width: `${generateRandomNumber(50, 75)}%`,
            height: `${height}px`,
          }}
        >
          <Placeholder animation="wave" style={{ fontSize: `${height}px` }}>
            <Placeholder bg="light" />
          </Placeholder>
        </div>
      </li>
    );
  }, [stack]);

  const addToStack = useCallback(() => {
    setStack((s) => [newLayer(), ...s]);
  }, [newLayer]);

  useEffect(() => {
    const timer = setInterval(addToStack, 100);
    return () => clearInterval(timer);
  }, [addToStack, newLayer]);

  return (
    <div className="loading">
      <ul>{stack}</ul>
      <div className="stack-base" />
    </div>
  );
}
