import React from "react";
import { Button } from "react-bootstrap";
import { listIcon } from "../../helpers/icons";
import ThicknessSelector from "./thickness-selector";
import RadiusSelector from "./radius-selector";
import EdgeSelector from "./edge-selector";
import { useDispatch } from "react-redux";
import { sidebarActions } from "../../../redux/_actions";

function ModelMakerSidebarHeader(props) {
  const { title, current, send } = props;
  const dispatch = useDispatch();

  return (
    <div className="stack-sidebar-header">
      <Button variant="outline" onClick={() => dispatch(sidebarActions.hide())}>
        {listIcon}
      </Button>
      <span className="title">{title}</span>
      <EdgeSelector current={current} send={send} />
      <RadiusSelector current={current} send={send} />
      <ThicknessSelector current={current} send={send} />
    </div>
  );
}

export default ModelMakerSidebarHeader;
