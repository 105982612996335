import { toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export const notify = (text) => toast(text, { 
  hideProgressBar: true, 
  autoClose: 2000, 
  transition: Zoom, 
  position: "top-center",
  closeOnClick: false,
  closeButton: false,
  className: "notification"
});
