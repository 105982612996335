import { boxConstants } from '../_constants';

export const boxUtilities = {
  sort,
  filter,
  shuffle,
  floatTagged,
};

function floatTagged(tagId, allIds, byId, linksById) {
  const array = [...allIds];

  array.sort(function (a, b) {
    const itemA = byId[a].origamiLinks.items.findIndex(
      (i) => linksById[i].stackId === tagId
    );
    const itemB = byId[b].origamiLinks.items.findIndex(
      (i) => linksById[i].stackId === tagId
    );

    if (itemA < itemB) {
      return 1;
    }
    if (itemA > itemB) {
      return -1;
    }
    return 0;
  });

  return array;
}

function shuffle(allIds) {
  const array = [...allIds];

  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * i);
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }

  return array;
}

function sort(sortBy, allIds, byId) {
  // by createdAt, thickness, width
  const {
    SORT_CREATEDAT_ASC,
    SORT_CREATEDAT_DESC,
    SORT_THICKNESS_ASC,
    SORT_THICKNESS_DESC,
    SORT_WIDTH_ASC,
    SORT_WIDTH_DESC,
  } = boxConstants;

  const array = [...allIds];
  let param = '';
  let direction = 1;

  switch (sortBy) {
    case SORT_CREATEDAT_ASC:
      direction = -1;
      param = 'createdAt';
      break;
    case SORT_THICKNESS_ASC:
      param = 'thickness';
      direction = -1;
      break;
    case SORT_WIDTH_ASC:
      param = 'width';
      direction = -1;
      break;
    case SORT_CREATEDAT_DESC:
      param = 'createdAt';
      break;
    case SORT_THICKNESS_DESC:
      param = 'thickness';
      break;
    case SORT_WIDTH_DESC:
      param = 'width';
      break;
    default:
      break;
  }

  array.sort(function (a, b) {
    let itemA = null;
    let itemB = null;

    if (sortBy === SORT_CREATEDAT_DESC || sortBy === SORT_CREATEDAT_ASC) {
      itemA = -new Date(byId[a][param]).getTime() * direction;
      itemB = -new Date(byId[b][param]).getTime() * direction;
    } else {
      itemA = byId[a].params[param] * direction;
      itemB = byId[b].params[param] * direction;
    }

    if (itemA < itemB) {
      return -1;
    }
    if (itemA > itemB) {
      return 1;
    }
    // names must be equal
    return 0;
  });

  return array;
}

function filter() {
  return null;
}

/*
function filter (id, object, array, param_src, param_dst) {
  if (Object.keys(object).length === 0) { return {}; }

  const filtered = array.filter(i => {
    if(!object.hasOwnProperty(i)) { 
      console.log(i)
      return false 
    }
    if(!object[i].hasOwnProperty(param_src)) {
      console.log(i)
      return false
    }
    return (object[i][param_src] === id);
  });
  const result = Object.assign({}, ...filtered.map((x) => ({[object[x][param_dst]]: object[x]['id']})));
  return result;
}
*/
