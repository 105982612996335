import React from 'react';
import { Button } from 'react-bootstrap';
import {
  prevQueueIcon,
  nextQueueIcon,
  swapVIcon,
  origamiIcon,
  plusAddIcon,
  rotateDoubleIcon,
} from '../../helpers/icons';
import { useSelector } from 'react-redux';
//import { makerActions } from '../../../redux/_actions'

function MakerButtons(props) {
  const maker = useSelector((state) => state.maker);
  const { queueIndex, queue, loading, file } = maker;
  const {
    openFiles,
    current,
    nextQueue,
    prevQueue,
    swapImages,
    rotateImages,
    validate,
    create3d,
  } = props;
  const ready = file.length === 2 && file[0] && file[1] && validate();
  const readyOne = file.length === 2 && (file[0] || file[1]);

  return (
    <div className="model-maker-sidebar-buttons">
      {queue.length > 2 ? (
        <React.Fragment>
          {queueIndex / 2 === 0 ? null : (
            <label className="maker-completed">{queueIndex / 2}</label>
          )}
          <Button
            variant="outline-secondary"
            onClick={prevQueue}
            className="prev-queue"
            disabled={queueIndex - 2 < 0}
          >
            {prevQueueIcon}
          </Button>
        </React.Fragment>
      ) : null}
      <Button
        variant="outline-secondary"
        onClick={swapImages}
        disabled={!readyOne}
      >
        {swapVIcon}
      </Button>
      {current.matches('start') ||
      current.matches('complete') ||
      current.matches('creating') ? (
        <Button
          variant="outline-secondary"
          className={'blinking'}
          style={{ margin: '0 auto' }}
          disabled={loading}
        >
          <label
            htmlFor={'fileUpload'}
            className="file-upload"
            style={{ cursor: 'pointer', color: 'rgba(28, 182, 245, 1)' }}
          >
            {plusAddIcon}
            <input
              id={'fileUpload'}
              type="file"
              onChange={(e) => openFiles(e, 0)}
              multiple
            />
          </label>
        </Button>
      ) : (
        <Button
          variant="outline-secondary"
          onClick={create3d}
          style={{ margin: '0 auto' }}
          disabled={!ready}
          className={ready ? 'blinking' : null}
        >
          {origamiIcon}
        </Button>
      )}
      <Button
        variant="outline-secondary"
        onClick={rotateImages}
        disabled={!readyOne}
        className="double-rotate-button"
      >
        {rotateDoubleIcon}
        <span>2x</span>
      </Button>

      {queue.length <= 2 ? null : (
        <React.Fragment>
          <Button
            variant="outline-secondary"
            onClick={nextQueue}
            className="next-queue"
            disabled={queueIndex + 2 >= queue.length}
          >
            {nextQueueIcon}
          </Button>
          {Math.round((queue.length - queueIndex - 2) / 2) === 0 ? null : (
            <label className="maker-pending">
              {Math.round((queue.length - queueIndex - 2) / 2)}
            </label>
          )}
        </React.Fragment>
      )}
    </div>
  );
}

export default MakerButtons;
