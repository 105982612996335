import React from "react";
import "./App.css";
import Homepage from "./components/homepage/homepage";
import Box from "./components/box";
import Touch from "./components/touch";
import { Routes, Route, Navigate } from "react-router-dom";
import { useUser } from "./user-provider";
import { toast } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";

toast.configure();

function App() {
  const { isAuthenticating, user, WithUser } = useUser();
  const HOCBox = WithUser(Box);
  const HOCTouch = WithUser(Touch);

  const AuthPage = ({ comp: Component, redirect }) => {
    return !user ? <Navigate to={redirect} /> : <Component user={user} />;
  };

  const HomePage = ({ comp: Component, redirect }) => {
    return user ? <Navigate to={redirect} /> : <Component />;
  };

  return (
    !isAuthenticating && (
      <div className="container">
        <div className="row">
          <div className="col">
            <Routes>
              <Route
                path="/touch"
                element={<AuthPage comp={HOCTouch} redirect="/" />}
              />
              <Route
                path="/box"
                element={<AuthPage comp={HOCBox} redirect="/" />}
              />
              <Route
                path="/"
                element={<HomePage comp={Homepage} redirect="/box" />}
              />
              {/*<Route component={() => <p>404 page not found</p>} />*/}
            </Routes>
          </div>
        </div>
      </div>
    )
  );
}

export default App;
