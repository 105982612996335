import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import {
  paperIcon,
  photoIcon,
  postcardIcon,
  cardIcon,
  envelopeIcon,
  thickEnvelopeIcon,
} from "../../helpers/icons";
import { useDispatch, useSelector } from "react-redux";
import { makerActions } from "../../../redux/_actions";

const selection = {
  0.1: paperIcon,
  0.25: photoIcon,
  0.5: postcardIcon,
  0.75: cardIcon,
  1.2: envelopeIcon,
  2.4: thickEnvelopeIcon,
};

function ThicknessSelector({ current, send }) {
  const thickness = useSelector((state) => state.maker.thickness);
  const dispatch = useDispatch();

  const changeThickness = (val) => {
    if (current.matches("complete")) {
      send("ADDTWO");
    }
    dispatch(makerActions.changeThickness(val));
  };

  return (
    <Dropdown style={{ float: "right" }}>
      <Dropdown.Toggle id="dropdown-thickness-selector" variant="outline">
        {selection[thickness]}
      </Dropdown.Toggle>
      <Dropdown.Menu className="thickness-menu">
        <Dropdown.Item
          as="button"
          active={thickness === 0.1}
          onClick={(e) => changeThickness(0.1)}
        >
          0.10mm | 0.004" | Paper
        </Dropdown.Item>
        <Dropdown.Item
          as="button"
          active={thickness === 0.25}
          onClick={(e) => changeThickness(0.25)}
        >
          0.25mm | 0.01" | Photo
        </Dropdown.Item>
        <Dropdown.Item
          as="button"
          active={thickness === 0.5}
          onClick={(e) => changeThickness(0.5)}
        >
          0.50mm | 0.02" | Biz/Postcard
        </Dropdown.Item>
        <Dropdown.Item
          as="button"
          active={thickness === 0.75}
          onClick={(e) => changeThickness(0.75)}
        >
          0.75mm | 0.03" | ID/Credit Card
        </Dropdown.Item>
        <Dropdown.Item
          as="button"
          active={thickness === 1.2}
          onClick={(e) => changeThickness(1.2)}
        >
          1.20mm | 0.05" | Thin Envelope
        </Dropdown.Item>
        <Dropdown.Item
          as="button"
          active={thickness === 2.4}
          onClick={(e) => changeThickness(2.4)}
        >
          2.40mm | 0.10" | Thick Envelope
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default ThicknessSelector;
