
export const AuthTheme = {
    container: { 'padding' : '0' },
    formContainer: { 'padding' : '0', 'margin' : '0' },
    formSection: {
	    'padding': '24px 32px',
    	'boxShadow' : 'none',
    	'minWidth' : 'initial',
    	'lineHeight': '1',
    	'margin' : '0',
    	'width' : '100%',
    },
    a: { 
      'color' : 'rgb(28,182,245)',
      'cursor' : 'pointer' 
    },
    button: { 
    	'backgroundColor' : 'rgb(28,182,245)', 
    	'borderRadius' : '3px',
    	'textTransform' : 'initial',
    	'lineHeight' : '1',
    	'fontSize' : '1.0rem', 
    	'minWidth' : '110px', 
      'color' : 'rgb(255,255,255)',
      'border' : 'none',
      'padding' : '14px 0',
      'width' : '100%',
    },
    sectionHeader: { 'display': 'none' },
    sectionBody: { 'marginBottom' : '24px' },
    inputLabel: { 'display': 'none' },
    input: { 
      'padding' : '10px',
      'fontSize' : '16px',
      'background' : 'rgb(255,255,255)',
      'borderRadius' : '3px',
      'marginBottom' : '10px',
      'border' : '1px solid rgb(196,196,196)',
      'color' : 'rgb(21,41,57)',
      'width' : '100%',
      'display' : 'block'
    },
    hint: {
      'fontSize' : '12px',
      'color' : 'rgb(130,130,130)'
    },
    formField: { 'marginBottom' : '10px'},
    sectionFooterPrimaryContent: {
      'display' : 'flex',
      'marginBottom' : '10px'
    },
    sectionFooterSecondaryContent: {
    	'fontSize' : '16px', 
    	'fontWeight' : '400',
    	'lineHeight' : '1.4',
      'color' : 'rgb(130,130,130)'
    },
    toast: {
      'position' : 'fixed',
      'width' : '100%',
      'height' : '10%',
      'bottom' : '0',
      'left' : '0',
      'zIndex' : '1000',
      'background' : '#eee',
      'opacity' : '0.6',
      'fontSize' : '20px',
      'textAlign' : 'center',
      'verticalAlign' : 'middle',
      'display' : 'flex',
      'span' : {
        'width' : '100%'
      }
    }
};


export const SignUpConfig = {
  header: '',
  hideAllDefaults: true,
  defaultCountryCode: '1',
  signUpFields: [
    {
      label: 'Username',
      key: 'username',
      required: true,
      displayOrder: 1,
      type: 'string',
      placeholder: 'Username',
    },
    {
      label: 'Password',
      key: 'password',
      required: true,
      displayOrder: 2,
      type: 'password',
      placeholder: 'Password',
    },
    {
      label: 'Email',
      key: 'email',
      required: true,
      displayOrder: 3,
      type: 'string',
      placeholder: 'Email',
    },
        {
      label: 'Name',
      key: 'name',
      required: true,
      displayOrder: 4,
      type: 'string',
      placeholder: 'Full Name',
    }
  ]
};


export const AuthLabels = {
    en: {
        'Sign in': 'Log in',
        'Sign In': 'Log In',
        'Create Account': 'Sign Up',
        'Create account': 'Sign up',
        'No account? ': "Want an account? ",
        'Have an account? ': "Got an account? ",
    }
};
