export const isColor = (color) => {
  if (!color || typeof color !== 'string') return false;

  // Validate hex values
  if (color.substring(0, 1) === '#') color = color.substring(1);

  switch (color.length) {
    case 3:
      return /^[0-9A-F]{3}$/i.test(color);
    case 6:
      return /^[0-9A-F]{6}$/i.test(color);
    case 8:
      return /^[0-9A-F]{8}$/i.test(color);
    default:
      break;
  }

  return false;
};

export const lightOrDark = (color) => {
  // Variables for red, green, blue values
  let r, g, b, hsp;

  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If HEX --> store the red, green, blue values in separate variables
    color = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    );

    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    // If RGB --> Convert it to HEX: http://gist.github.com/983661
    color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'));

    r = color >> 16;
    g = (color >> 8) & 255;
    b = color & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 240) {
    // default = 127.5
    return 'light';
  } else {
    return 'dark';
  }
};

export const lightenOrDarkenColor = (col, amt) => {
  // Used to add a little more lightness or darkness to the hex color
  let usePound = false;
  if (col[0] === '#') {
    col = col.slice(1);
    usePound = true;
  }

  if (col.length === 3) {
    col = col
      .split('')
      .map((item) => {
        if (item === '#') {
          return item;
        }
        return item + item;
      })
      .join('');
  }

  const num = parseInt(col, 16);
  let r = (num >> 16) + amt;
  if (r > 255) r = 255;
  else if (r < 0) r = 0;
  let b = ((num >> 8) & 0x00ff) + amt;
  if (b > 255) b = 255;
  else if (b < 0) b = 0;
  let g = (num & 0x0000ff) + amt;
  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  let rawHex = (g | (b << 8) | (r << 16)).toString(16);

  while (rawHex.length < 6) {
    rawHex = '0' + rawHex;
  }

  return (usePound ? '#' : '') + rawHex;
};
