import { makerConstants } from "../_constants";
import { makerService, boxService } from "../_services";
import { alertActions, wsActions, boxActions } from "./";
import { store } from "../store";

export const makerActions = {
  removeSingle,
  reset,
  rotateSingle,
  rotateDouble,
  swap,
  changeThickness,
  changeRadius,
  changeEdge,
  create3d,
  created3d,
  edit3d,
  openFiles,
  clearQueue,
  kickoff3d,
};

function removeSingle(payload) {
  return { type: makerConstants.REMOVESINGLE, payload };
}

function reset() {
  return { type: makerConstants.RESET };
}

function rotateSingle(index) {
  return { type: makerConstants.ROTATESINGLE, index };
}

function rotateDouble() {
  return { type: makerConstants.ROTATEDOUBLE };
}

function swap() {
  return { type: makerConstants.SWAP };
}

function changeThickness(thickness) {
  return { type: makerConstants.CHANGETHICKNESS, thickness };
}

function changeRadius(radius) {
  return { type: makerConstants.CHANGERADIUS, radius };
}

function changeEdge(edge) {
  return { type: makerConstants.CHANGEEDGE, edge };
}

function create3d(data, payload) {
  return (dispatch) => {
    dispatch(request());

    makerService
      .getUploadToken(data) // Get upload tokens
      .then((res) => {
        const connectTimeout = setTimeout(function () {
          if (store.getState().ws.connecting !== null) {
            const error = new Error("WS connection timeout.  Make 3D failed.");
            dispatch(failure());
            dispatch(alertActions.error(error.message));
          }
        }, 5000);
        dispatch(
          wsActions.connect(res.data.wss, res.data.itemId, connectTimeout)
        ); // Start WS session
        return res;
      })
      .then((res) => {
        const uploadTimeout = setTimeout(function () {
          if (store.getState().maker.upload.timeout !== null) {
            const error = new Error("Upload timeout.  Make 3D failed.");
            dispatch(failure());
            dispatch(alertActions.error(error.message));
          }
        }, 10000);
        dispatch(upload(data, res, uploadTimeout));
        return makerService.uploadFiles(res.data, payload); // Upload files
      })
      .then((res) => {
        // If WS open, then kickoff 3d immediately
        // If WS not open, then onOpen will send kickoff 3d
        const id = res[0].itemId;
        dispatch(uploaded());
        dispatch(wsActions.pending(id));
        const { connected } = store.getState().ws;
        if (connected) {
          dispatch(kickoff3d());
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(alertActions.error(error.message));
        dispatch(failure());
      });
  };

  function request() {
    return { type: makerConstants.CREATE3D_REQUEST };
  }
  function upload(data, session, timeout) {
    return {
      type: makerConstants.CREATE3D_UPLOAD_REQUEST,
      data,
      session,
      timeout,
    };
  }
  function uploaded() {
    return { type: makerConstants.CREATE3D_UPLOAD_SUCCESS };
  }
  function failure(error) {
    return { type: makerConstants.CREATE3D_FAILURE };
  }
}

function kickoff3d() {
  return (dispatch) => {
    const { connected, id } = store.getState().ws;
    if (connected) {
      const makerTimeout = setTimeout(function () {
        if (store.getState().ws.making !== null) {
          const error = new Error("Maker timeout.  Make 3D failed.");
          dispatch(wsActions.disconnect());
          dispatch(failure());
          dispatch(alertActions.error(error.message));
        }
      }, 5000);
      dispatch(wsActions.send(id, makerTimeout)); // Send WS upload complete
    }
  };

  function failure() {
    return { type: makerConstants.CREATE3D_FAILURE };
  }
}

function created3d(id, data) {
  return (dispatch) => {
    makerService
      .addOrigami(id, data) // Send data to GraphQL DB
      .then((res) => {
        dispatch(boxActions.addOrigami(res[0].data.createOrigami)); // Update normalized data structures
        dispatch(success());
      })
      .catch((error) => {
        dispatch(failure());
        dispatch(alertActions.error(error.message));
      });
  };

  function success() {
    return { type: makerConstants.CREATE3D_SUCCESS };
  }
  function failure(error) {
    return { type: makerConstants.CREATE3D_FAILURE };
  }
}

function edit3d(id) {
  // Check to see if this asset is in the previous queue
  // Otherwise load as the newest into the queue
  // Request data, download photos, clearqueue

  return (dispatch) => {
    dispatch(request());
    boxService
      .editOG(id)
      .then((res) => {
        return res.data.getOrigami;
      })
      .then((res) => {
        return makerService.getPhotos(res);
      })
      .then((res) => {
        const data = { ...res[0], file: [res[1].data.front, res[2].data.back] };
        dispatch(success(data));
      })
      .catch((error) => {
        dispatch(failure());
        dispatch(alertActions.error(error.message));
      });
  };

  function request() {
    return { type: makerConstants.EDIT3D_REQUEST };
  }
  function success(data) {
    return { type: makerConstants.EDIT3D_SUCCESS, data };
  }
  function failure() {
    return { type: makerConstants.EDIT3D_FAILURE };
  }
}

function openFiles(payload) {
  return { type: makerConstants.OPENFILES, payload };
}

function clearQueue() {
  return { type: makerConstants.CLEARQUEUE };
}
