import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { notify } from '../helpers/notification';
import {
  downloadIcon,
  linkIcon,
  appleIcon,
  androidIcon,
  imagesIcon,
  backImagesIcon,
} from '../helpers/icons';
import { useSelector } from 'react-redux';

const copyToClipboard = (e, id) => {
  const textField = document.createElement('textarea');
  textField.innerText = `${window.location.origin}/touch?m=${id}`;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand('copy');
  textField.remove();
  notify('Link copied to clipboard');
};

function DownloadSelector(props) {
  const {
    modelID,
    downloadUSDZ,
    downloadGLB,
    downloadFront,
    downloadBack,
    clipboard,
  } = props;
  const selected = useSelector((state) => state.box?.status?.selected);

  return (
    <Dropdown style={{ float: 'right' }}>
      <Dropdown.Toggle
        id="dropdown-download-selector"
        variant="outline"
        style={{ height: '48px' }}
        disabled={!selected}
      >
        {downloadIcon}
      </Dropdown.Toggle>
      <Dropdown.Menu className="download-selector">
        <Dropdown.Item as="button" onClick={downloadFront}>
          Front Img
          <span style={{ float: 'right', marginTop: '-2px' }}>
            {imagesIcon}
          </span>
        </Dropdown.Item>
        <Dropdown.Item as="button" onClick={downloadBack}>
          Back Img
          <span style={{ float: 'right', marginTop: '-2px' }}>
            {backImagesIcon}
          </span>
        </Dropdown.Item>
        <Dropdown.Item as="button" onClick={downloadGLB}>
          GLB
          <span style={{ float: 'right', marginTop: '-2px' }}>
            {androidIcon}
          </span>
        </Dropdown.Item>
        <Dropdown.Item as="button" onClick={downloadUSDZ}>
          USDZ
          <span style={{ float: 'right', marginTop: '-4px' }}>{appleIcon}</span>
        </Dropdown.Item>
        {clipboard ? (
          <Dropdown.Item
            as="button"
            onClick={(e) => copyToClipboard(e, modelID)}
          >
            Copy URL
            <span style={{ float: 'right', marginTop: '-2px' }}>
              {linkIcon}
            </span>
          </Dropdown.Item>
        ) : null}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default DownloadSelector;
