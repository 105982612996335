import React, { useState, useCallback } from 'react';
import { Modal, Button } from 'react-bootstrap'


const InputComponent = (name, handler, saveHandler) => {
    const autoFocus = useCallback(el => el ? el.focus() : null, [])

    return (
      <input 
        type="text" 
        ref={autoFocus} 
        value={name} 
        className="form-control" 
        onChange={handler} 
        onKeyPress={(e) => e.key === "Enter" ? saveHandler(e) : null}
      />
    )
}


function EditStacks(props) {
  const [name, setName] = useState(null)
  const { toggleEdit, toggleShowEdit, editStackTag, editData } = props;


  const handleChangeInput = (e) => { 
    setName(e.target.value)
    //console.log(e)
  }

  const saveInput = (e) => {
    e.preventDefault();
    if (name === '' || name === editData.name || name === null) return;
    const target = name;
    setName(null);
    editStackTag(target, editData.id);
  }

  return (
    <Modal 
      show={toggleEdit} 
      onHide={toggleShowEdit}
      centered
      size="sm"
      className="edit-stacks-modal"
      animation={false}
    >
        <Modal.Header closeButton>
          <Modal.Title>Edit Tag</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { InputComponent(name === null ? editData.name : name, handleChangeInput, saveInput) }
        </Modal.Body>
        <Modal.Footer>
          <Button className="save-button" variant="primary" onClick={saveInput}>
            Save
          </Button>
        </Modal.Footer>
    </Modal>
  )
}

export default EditStacks;