import { makerConstants, initialMakerState } from '../_constants';

export function maker(state = initialMakerState, action) {
  switch (action.type) {
    case makerConstants.REMOVESINGLE:
      return {
        ...state,
        fileObj: action.payload.fileObj,
        file: action.payload.file,
        aspectRatio: action.payload.aspectRatio,
        dimensions: action.payload.dimensions,
        rotate: action.payload.rotate,
        complete: false,
      };
    case makerConstants.RESET:
      return initialMakerState;
    case makerConstants.ROTATESINGLE:
      let rot = state.rotate;
      const i = action.index;
      return {
        ...state,
        rotate: [rot[0] + 0.25 * ((i + 1) % 2), rot[1] - 0.25 * i],
        complete: false,
      };
    case makerConstants.ROTATEDOUBLE:
      rot = state.rotate;
      return {
        ...state,
        rotate: [rot[0] + 0.25, rot[1] - 0.25],
        complete: false,
      };
    case makerConstants.SWAP:
      return {
        ...state,
        fileObj: state.fileObj.reverse(),
        file: state.file.reverse(),
        rotate: state.rotate.reverse(),
        swapped: !state.swapped,
        aspectRatio: state.aspectRatio.reverse(),
        dimensions: state.dimensions.reverse(),
        complete: false,
      };
    case makerConstants.CHANGETHICKNESS:
      return {
        ...state,
        thickness: action.thickness,
        complete: false,
      };
    case makerConstants.CHANGERADIUS:
      return {
        ...state,
        cornerRadius: action.radius,
        complete: false,
      };
    case makerConstants.CHANGEEDGE:
      return {
        ...state,
        edge: action.edge,
        complete: false,
      };
    case makerConstants.CREATE3D:
      return {};
    case makerConstants.EDIT3D_SUCCESS:
      return {
        ...state,
        queueIndex: 0,
        queue: [],
        swapped: false,
        fileObj: [null, null],
        file: action.data.file,
        loading: false,
        maxDim: 0,
        aspectRatio: [
          action.data.params.width / action.data.params.height,
          action.data.params.width / action.data.params.height,
        ],
        rotate: action.data.params.rotate,
        dimensions: [
          { w: action.data.params.width, h: action.data.params.height },
          { w: action.data.params.width, h: action.data.params.height },
        ],
        cornerRadius: action.data.params.radius,
        thickness: action.data.params.thickness,
        edge: action.data.params.edge,
        modelID: action.data.id,
      };
    case makerConstants.OPENFILES:
      return {
        ...state,
        queueIndex: action.payload.queueIndex,
        queue: action.payload.queue,
        swapped: action.payload.swapped,
        fileObj: action.payload.fileObj,
        file: action.payload.file,
        loading: action.payload.loading,
        maxDim: action.payload.maxDim,
        aspectRatio: action.payload.aspectRatio,
        rotate: action.payload.rotate,
        dimensions: action.payload.dimensions,
      };
    case makerConstants.CLEARQUEUE:
      return {
        ...state,
        queueIndex: 0,
        queue: [],
        fileObj: [null, null],
        file: [null, null],
        rotate: [0, 0],
        maxDim: 0,
        aspectRatio: [null, null],
        dimensions: [null, null],
        swapped: false,
      };
    case makerConstants.CREATE3D_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case makerConstants.CREATE3D_UPLOAD_REQUEST:
      return {
        ...state,
        modelID: action.session.data.itemId,
        upload: {
          session: action.session,
          data: action.data,
          timeout: action.timeout,
        },
      };
    case makerConstants.CREATE3D_UPLOAD_SUCCESS:
      clearTimeout(state.upload.timeout);
      return {
        ...state,
        upload: { ...state.upload, timeout: null },
      };
    case makerConstants.CREATE3D_SUCCESS:
      return {
        ...state,
        loading: false,
        complete: true,
        error: false,
      };
    case makerConstants.CREATE3D_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
}
