import { sidebarConstants, initialSidebarState } from '../_constants';


export function sidebar(state = initialSidebarState, action) {
  switch(action.type) {
    case sidebarConstants.SHOW: 
      return action.selected !== null ? 
        { ...state, show: true, selected: action.selected } : 
        { ...state, show: true }
    case sidebarConstants.HIDE: 
      return { ...state, show: false }
    case sidebarConstants.CHANGEMENU:
      return { ...state, selected: action.selected }
    default: 
      return state
  }
}

