export const boxConstants = {
  SELECT: 'BOX_SELECT',
  LOAD_REQUEST: 'BOX_LOAD_REQUEST',
  LOAD_SUCCESS: 'BOX_LOAD_SUCCESS',
  LOAD_FAILURE: 'BOX_LOAD_FAILURE',
  SHUFFLE: 'BOX_SHUFFLE',
  FLOAT_TAGGED: 'BOX_FLOAT_TAGGED',
  SORT_CREATEDAT_ASC: 'BOX_SORT_CREATEDAT_ASC',
  SORT_CREATEDAT_DESC: 'BOX_SORT_CREATEDAT_DESC',
  SORT_THICKNESS_ASC: 'BOX_SORT_THICKNESS_ASC',
  SORT_THICKNESS_DESC: 'BOX_SORT_THICKNESS_DESC',
  SORT_WIDTH_ASC: 'BOX_SORT_WIDTH_ASC',
  SORT_WIDTH_DESC: 'BOX_SORT_WIDTH_DESC',
  LOADING: 'BOX_LOADING',
  ADD_ORIGAMI: 'BOX_ADD_ORIGAMI',
  ADD_LINK: 'BOX_ADD_LINK',
  ADD_LINK_PENDING: 'BOX_ADD_LINK_PENDING',
  DELETE_LINK: 'BOX_DELETE_LINK',
  DELETE_OG_REQUEST: 'BOX_DELETE_OG_REQUEST',
  DELETE_OG_SUCCESS: 'BOX_DELETE_OG_SUCCESS',
  DELETE_OG_FAILURE: 'BOX_DELETE_OG_FAILURE'
};


export const initialBoxState = {
  selected: null,
  loaded: false,
  loading: true,
  sort: boxConstants.SORT_CREATEDAT_DESC
};