import { API, graphqlOperation } from 'aws-amplify';
import { origamisByUsername, deleteOrigami, getOrigamiEdit } from '../../graphql/graphql';
import { fetchItemsNextToken } from '../../graphql/query-helpers';


export const boxService = {
  load,
  deleteOG,
  editOG
};


async function load(username) {
  const qparams = {
    username: username,
    limit: 1000,
    sortDirection: 'DESC'
  }

  return fetchItemsNextToken({
    query: origamisByUsername,
    variables: qparams
  })
}


async function deleteOG(id) {
  return API.graphql(graphqlOperation(deleteOrigami, 
    { id: id }
  ));
}


// Retrieves DB info and photo to put into maker
async function editOG(id) {
  return API.graphql(graphqlOperation(getOrigamiEdit, 
    { id: id }
  ));
}