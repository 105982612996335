import React from 'react';
import { Placeholder } from 'react-bootstrap';
import {
	SignIn,
	SignUp,
	ForgotPassword,
	FederatedButtons,
} from 'aws-amplify-react';
import { Auth, Logger, I18n } from 'aws-amplify';
import {
	FormSection,
	FormField,
	SectionHeader,
	SectionBody,
	SectionFooter,
	Button,
	Link,
	Hint,
	Input,
	SectionFooterPrimaryContent,
	SectionFooterSecondaryContent,
} from 'aws-amplify-react/lib-esm/Amplify-UI/Amplify-UI-Components-React';

import { auth } from 'aws-amplify-react/lib-esm/Amplify-UI/data-test-attributes';

const logger = new Logger('SignIn');

const overlay = (
	<div id="loading">
		<Placeholder animation="wave">
			<Placeholder xs={12} />
		</Placeholder>
	</div>
);

class CustomSignIn extends SignIn {
	constructor(props: any) {
		super(props);
		this._validAuthStates = ['signIn', 'signedOut', 'signedUp'];
	}

	async signIn(event) {
		// avoid submitting the form
		if (event) {
			event.preventDefault();
		}

		const username = this.getUsernameFromInput() || '';
		const password = this.inputs.password;
		let user = null;

		if (!Auth || typeof Auth.signIn !== 'function') {
			throw new Error(
				'No Auth module found, please ensure @aws-amplify/auth is imported'
			);
		}
		this.setState({ loading: true });
		try {
			user = await Auth.signIn(username, password); // Original
			//const user = await this.props.login(username, password);		// Using UserProvider
			logger.debug(user);
			if (
				user.challengeName === 'SMS_MFA' ||
				user.challengeName === 'SOFTWARE_TOKEN_MFA'
			) {
				logger.debug('confirm user with ' + user.challengeName);
				this.changeState('confirmSignIn', user);
			} else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
				logger.debug('require new password', user.challengeParam);
				this.changeState('requireNewPassword', user);
			} else if (user.challengeName === 'MFA_SETUP') {
				logger.debug('TOTP setup', user.challengeParam);
				this.changeState('TOTPSetup', user);
			} else if (
				user.challengeName === 'CUSTOM_CHALLENGE' &&
				user.challengeParam &&
				user.challengeParam.trigger === 'true'
			) {
				logger.debug('custom challenge', user.challengeParam);
				this.changeState('customConfirmSignIn', user);
			} else {
				this.checkContact(user);
			}
		} catch (err) {
			if (err.code === 'UserNotConfirmedException') {
				logger.debug('the user is not confirmed');
				this.changeState('confirmSignUp', { username });
			} else if (err.code === 'PasswordResetRequiredException') {
				logger.debug('the user requires a new password');
				this.changeState('forgotPassword', { username });
			} else {
				this.error(err);
			}
		} finally {
			this.setState({ loading: false });
			this.props.confirmLogin(user);
		}
	}

	showComponent(theme) {
		const {
			authState,
			federated,
			hide = [],
			onStateChange,
			onAuthEvent,
			override = [],
		} = this.props;
		/*if (hide && hide.includes(SignIn)) {
			return null;
		}*/
		const hideSignUp =
			!override.includes('SignUp') &&
			hide.some((component) => component === SignUp);
		const hideForgotPassword =
			!override.includes('ForgotPassword') &&
			hide.some((component) => component === ForgotPassword);
		return (
			<FormSection theme={theme} data-test={auth.signIn.section}>
				{this.state.loading ? overlay : null}
				<SectionHeader theme={theme} data-test={auth.signIn.headerSection}>
					{I18n.get('Sign in to your account')}
				</SectionHeader>
				<FederatedButtons
					federated={federated}
					theme={theme}
					authState={authState}
					onStateChange={onStateChange}
					onAuthEvent={onAuthEvent}
				/>
				<form onSubmit={this.signIn}>
					<SectionBody theme={theme}>
						<FormField theme={theme}>
							<Input
								defaultValue={this.state.username}
								autoFocus={true}
								placeholder={I18n.get('Enter your username')}
								theme={theme}
								key="username"
								name="username"
								onChange={this.handleInputChange}
								data-test={auth.genericAttrs.usernameInput}
								autoCapitalize="off"
								disabled={this.state.loading}
							/>
						</FormField>
						<FormField theme={theme}>
							<Input
								placeholder={I18n.get('Enter your password')}
								theme={theme}
								key="password"
								type="password"
								name="password"
								onChange={this.handleInputChange}
								data-test={auth.signIn.passwordInput}
								disabled={this.state.loading}
							/>
							{!hideForgotPassword && (
								<Hint theme={theme}>
									{I18n.get('Forget your password? ')}
									<Link
										theme={theme}
										onClick={() => this.changeState('forgotPassword')}
										data-test={auth.signIn.forgotPasswordLink}
									>
										{I18n.get('Reset password')}
									</Link>
								</Hint>
							)}
						</FormField>
					</SectionBody>
					<SectionFooter theme={theme} data-test={auth.signIn.footerSection}>
						<SectionFooterPrimaryContent theme={theme}>
							<Button
								theme={theme}
								type="submit"
								disabled={this.state.loading}
								data-test={auth.signIn.signInButton}
							>
								{I18n.get('Sign In')}
							</Button>
						</SectionFooterPrimaryContent>
						{!hideSignUp && (
							<SectionFooterSecondaryContent theme={theme}>
								{I18n.get('No account? ')}
								<Link
									theme={theme}
									onClick={() => this.changeState('signUp')}
									data-test={auth.signIn.createAccountLink}
								>
									{I18n.get('Create account')}
								</Link>
							</SectionFooterSecondaryContent>
						)}
					</SectionFooter>
				</form>
			</FormSection>
		);
	}
}

export default CustomSignIn;
