import React from 'react';
import { Button } from 'react-bootstrap'
import { plusSquareIcon, pencilEditIcon, taggedIcon } from '../../helpers/icons'
import AddStacks from './add-tag-modal';
import EditStacks from './edit-tag-modal';


function TagsButtonCluster(props) {
  const { 
    addStackTag, 
    toggleAdd, 
    toggleShowAdd, 
    setMode, 
    mode, 
    modeConstants,
    toggleEdit,
    toggleShowEdit,
    editStackTag,
    editData
  } = props;
  const { EDIT, SET, TAG, ADD } = modeConstants;


  const toggleAddTag = () => {
    setMode(ADD);
    toggleShowAdd();
  }


  const toggleSetTag = () => {
    if (mode === SET) {
      setMode(TAG);
    } else {
      setMode(SET);
    }
  }


  const toggleEditTag = () => {
    if (mode === EDIT) {
      setMode(TAG);
    } else {
      setMode(EDIT);
    }
  }


  return (
    <div className="tags-button-cluster" >
      <div className="sidebar-add-tag-button">
        <Button className={toggleAdd ? "activated" : null} variant="outline-secondary" onClick={toggleAddTag} >{ plusSquareIcon }</Button>
        <AddStacks toggleAdd={toggleAdd} toggleShowAdd={toggleShowAdd} addStackTag={addStackTag} /> 
      </div>
      <div className="sidebar-edit-tag-button">
        <Button className={mode === EDIT ? "activated" : null} variant="outline-secondary" onClick={toggleEditTag}> {pencilEditIcon} </Button>
        <EditStacks toggleEdit={toggleEdit} toggleShowEdit={toggleShowEdit} editStackTag={editStackTag} editData={editData} />
      </div>
      <div className="sidebar-tag-tag-button">
        <Button className={mode === SET ? "activated" : null } variant="outline-secondary" onClick={toggleSetTag}> {taggedIcon} </Button>
      </div>
    </div>
  );
}

export default TagsButtonCluster;

