import { API, graphqlOperation } from 'aws-amplify';
import { listBlackBoxStacks, listOrigamiLinks, createUserStack, editStackName } from '../../graphql/graphql'
import { fetchItemsNextToken } from '../../graphql/query-helpers'


export const stackService = {
  load,
  create,
  edit
};


async function load() {
  const qparams = {
    limit: 1000
  }

  return Promise.all([
    fetchItemsNextToken({
      query: listBlackBoxStacks,
      variables: qparams
    }),
    fetchItemsNextToken({
      query: listOrigamiLinks,
      variables: qparams
    })
  ]);
}


async function create(boxId, name) {
  return API.graphql(graphqlOperation(createUserStack, 
    { boxId: boxId, private: true, name: name }
  ));
}


async function edit(stackId, name) {
  return API.graphql(graphqlOperation(editStackName,
    { id: stackId, name: name }
  ));
}