import React, { useEffect, useState } from 'react';
import TimeAgo from 'react-timeago';
import { formatter } from '../../helpers/timeformat-helper';
import { isColor } from '../../helpers/color-helper';
import { useHotkeys } from 'react-hotkeys-hook';
import StackSidebarHeader from './stack-sidebar-header';
import { useUser } from '../../../user-provider';

function StackSidebar(props) {
  const [origami, updateOrigami] = useState([]);
  const [id, setId] = useState(null);
  const [sort, setSort] = useState('createdAt');
  const [sortDir, setSortDir] = useState(1);
  const [expanded, setExpanded] = useState(false);
  const [justify, setJustify] = useState(0);
  const { show } = props;
  const { user } = useUser();

  // Query the API and save them to the state
  useEffect(() => {
    const results = user.origamis.items;
    updateOrigami(results);
  }, [user]);

  useEffect(() => {
    setId(props.id);
  }, [props.id]);

  useHotkeys(
    'up',
    (e) => {
      if (show === null) {
        return;
      }
      e.preventDefault();
      const array = [...origami];
      const indexOf = array.findIndex((i) => i.id === id);
      if (indexOf !== 0 && indexOf !== null) {
        updateRoute(array[indexOf - 1].id);
      }
    },
    [origami, id, show]
  );

  useHotkeys(
    'down',
    (e) => {
      if (show === null) {
        return;
      }
      e.preventDefault();
      const array = [...origami];
      const indexOf = array.findIndex((i) => i.id === id);
      if (indexOf !== array.length - 1 && indexOf !== null) {
        updateRoute(array[indexOf + 1].id);
      }
    },
    [origami, id, show]
  );

  const expandStack = () => {
    //expanded ? notify('Collapsing stack') : notify('Expanding stack')
    setExpanded(!expanded);
  };

  const justifyStack = () => {
    //center ? notify('Left justifying stack') : notify('Centering stack')
    // 0 is left, 1 is center, 2 is right
    setJustify(justify === 2 ? 0 : justify + 1);
  };

  const sortBy = (e, parameter = 'createdAt') => {
    const array = [...origami];
    let direction = sortDir;

    if (parameter === 'shuffle') {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * i);
        const temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }
    } else {
      if (parameter === sort) {
        direction = -1 * sortDir;
        setSortDir(direction);
      }

      array.sort(function (a, b) {
        let itemA = a.params[parameter] * direction;
        let itemB = b.params[parameter] * direction;

        if (parameter === 'createdAt') {
          itemA = -new Date(a[parameter]).getTime() * direction;
          itemB = -new Date(b[parameter]).getTime() * direction;
        }

        if (itemA < itemB) {
          return -1;
        }
        if (itemA > itemB) {
          return 1;
        }
        // names must be equal
        return 0;
      });
    }

    setSort(parameter);
    //if (parameter === 'createdAt') { parameter = 'created at'}
    //notify(`Sorting by ${parameter}`)
    updateOrigami(array);
  };

  /*
  const deleteLine = (e, modelId) => {
    const inputData = { 
      id: modelId,
      status: "deleted"
    }

    const array = [...origami]; 
    const indexOf = array.findIndex( ({id}) => id === modelId );
    if (indexOf === -1) { return }

    notify(`Deleting ${modelId}`)
    new Promise(function(resolve, reject) {
      //API.graphql(graphqlOperation(deleteOrigami, { input: inputData }))
      API.graphql(graphqlOperation(UpdateOrigami, { input: inputData }))
    })

    array.splice(indexOf, 1)
    
    if (indexOf === origami.length) {
      setId(array[array.length].id)
    } else {
      setId(array[indexOf].id)
    }
    
    updateOrigami(array)  
  }
  */

  const updateRoute = (newId) => {
    if (id === newId) {
      return;
    }
    props.history.push(`/touch?m=${newId}`);
    setId(newId);
  };

  return (
    <React.Fragment>
      <StackSidebarHeader
        sortBy={sortBy}
        expandStack={expandStack}
        expanded={expanded}
        justifyStack={justifyStack}
        justify={justify}
        sort={sort}
        sortDir={sortDir}
        title={`My OG (${origami.length})`}
      />
      <div className="box-deck-container">
        <ul className="box-deck">
          {origami.map((p, i) => {
            let selected = p.id === id ? 'selected' : '';
            let expand = expanded ? 'expanded' : '';
            let background = null;

            // Add effects for rounded corners
            if (p.params) {
              let corners = '#eee'; // default
              let main = '';

              if (p.params.radius > 0) {
                corners = '#bbb';
                let radius = (100 * p.params.radius) / p.params.width;
                main += isColor(p.params.edge) ? p.params.edge : '#eee';
                main += ` ${radius}% ${100 - radius}%, `;
              } else if (isColor(p.params.edge)) {
                corners = p.params.edge;
              }

              background = `linear-gradient(to left, ${corners} 0%, ${main}${corners} 100%)`;
            }

            return (
              <li key={p.id}>
                <div className={[selected, expand, 'flat-header'].join(' ')}>
                  {selected !== '' || expanded ? `${origami.length - i}` : null}
                </div>
                <button
                  className={[selected, expand].join(' ')}
                  type="button"
                  onClick={() => updateRoute(p.id)}
                >
                  {/* selected !== '' || expanded ? ` (${origami.length - i})` : null */}
                  <div
                    className="flat"
                    style={{
                      width: p.params
                        ? (p.params.width / 250) * 100 + '%'
                        : '50%',
                      height: p.params ? p.params.thickness * 10 + 'px' : null,
                      background: background,
                      marginLeft: justify === 0 ? null : 'auto',
                      marginRight: justify === 2 ? null : 'auto',
                    }}
                  />
                </button>
                <div className={[selected, expand, 'flat-footer'].join(' ')}>
                  {/*` by ${p.username} ` */}
                  {selected !== '' || expanded ? (
                    <TimeAgo
                      date={p.createdAt}
                      minPeriod={60}
                      formatter={formatter}
                    />
                  ) : null}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </React.Fragment>
  );
}

export default StackSidebar;
