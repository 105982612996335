import { combineReducers } from "redux";
import { boxConstants, initialBoxState } from "../_constants";

function origamisById(state = [], action) {
  switch (action.type) {
    case boxConstants.LOAD_SUCCESS:
      return action.data.origamis.entities.origamis || state;
    case boxConstants.ADD_ORIGAMI:
      return action.origamis.byId || state;
    case boxConstants.ADD_LINK:
    case boxConstants.ADD_LINK_PENDING:
    case boxConstants.DELETE_LINK:
      return action.byId || state;
    case boxConstants.DELETE_OG_REQUEST:
      return action.data.byId || state;
    default:
      return state;
  }
}

function allOrigamis(state = [], action) {
  switch (action.type) {
    case boxConstants.LOAD_SUCCESS:
      return action.data.origamis.result || state;
    case boxConstants.ADD_ORIGAMI:
      return action.origamis.allIds || state;
    case boxConstants.SHUFFLE:
      return action.result || state;
    case boxConstants.FLOAT_TAGGED:
    case boxConstants.SORT_CREATEDAT_DESC:
    case boxConstants.SORT_CREATEDAT_ASC:
    case boxConstants.SORT_THICKNESS_DESC:
    case boxConstants.SORT_THICKNESS_ASC:
    case boxConstants.SORT_WIDTH_DESC:
    case boxConstants.SORT_WIDTH_ASC:
      return action.result || state;
    case boxConstants.DELETE_OG_REQUEST:
      return action.data.allIds || state;
    default:
      return state;
  }
}

/*
function tagsById(state = [], action) {
  switch(action.type) {
    case boxConstants.LOAD_SUCCESS: 
      return action.data.origamis.entities.tags;
    default: 
      return state;
  }
}


function linksById(state = [], action) {
  switch(action.type) {
    case boxConstants.LOAD_SUCCESS: 
      return action.data.origamis.entities.links;
    default: 
      return state;
  }
}
*/

const origamis = combineReducers({
  byId: origamisById,
  allIds: allOrigamis,
});

function status(state = initialBoxState, action) {
  switch (action.type) {
    case boxConstants.LOADING:
      return { ...state, loading: true };
    case boxConstants.SELECT:
      return { ...state, selected: action.id };
    case boxConstants.LOAD_REQUEST:
      return { ...state, loading: true };
    case boxConstants.LOAD_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        selected: action.data.origamis.result[0],
      };
    case boxConstants.LOAD_FAILURE:
      return { ...state, loading: false, loaded: false };
    case boxConstants.SHUFFLE:
    case boxConstants.FLOAT_TAGGED:
    case boxConstants.SORT_CREATEDAT_DESC:
    case boxConstants.SORT_CREATEDAT_ASC:
    case boxConstants.SORT_THICKNESS_DESC:
    case boxConstants.SORT_THICKNESS_ASC:
    case boxConstants.SORT_WIDTH_DESC:
    case boxConstants.SORT_WIDTH_ASC:
      return { ...state, loading: false, sort: action.type };
    case boxConstants.ADD_ORIGAMI:
      return { ...state, selected: action.id };
    case boxConstants.DELETE_OG_REQUEST:
      return { ...state, selected: action.data.nextId };
    default:
      return state;
  }
}

export const box = combineReducers({
  status: status,
  origamis: origamis,
  //links: linksById,
  //tags: tagsById
});
