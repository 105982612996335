import React, { useState, useEffect, useLayoutEffect } from "react";
import "@google/model-viewer";
import useOutsideClick from "../helpers/outsideclick-helper";

const prefix = "/static/carousel";
const models = [
  "-xvfJwzmooUWtg",
  "6A1ezZxCCTw4ag",
  "FydGdmUZJ_LU6g",
  "nKzCBkjuUYxNSA",
  "RFXmQczriNhaGg",
];

const HeroScroller = () => {
  const [ct, setCt] = useState(0);
  const [src, setSrc] = useState(`${prefix}/${models[ct]}.glb`);
  const [idle, setIdle] = useState(true);
  const [iosSrc, setIosSrc] = useState(`${prefix}/${models[ct]}.usdz`);
  const mvRef = React.useRef();

  useLayoutEffect(() => {
    const { current } = mvRef;
    current.addEventListener("camera-change", (e) => {
      if (e.detail.source === "user-interaction") {
        setIdle(false);
      }
    });
  }, [mvRef]);

  useOutsideClick(mvRef, () => {
    setIdle(true);
  });

  useEffect(() => {
    const interval = idle
      ? setInterval(() => {
          setSrc(`${prefix}/${models[ct % 5]}.glb`);
          setIosSrc(`${prefix}/${models[ct % 5]}.usdz`);
          setCt(ct < 5 ? ct + 1 : 0);
        }, 12000)
      : null;
    return () => clearInterval(interval);
  }, [ct, idle]);

  return (
    <model-viewer
      class="carousel"
      src={src}
      ios-src={iosSrc}
      alt="3D Model"
      shadow-intensity="0"
      stage-light-intensity="10"
      environment-intensity="2"
      environment-image="/static/white.png"
      camera-orbit="0deg 75deg 105%"
      exposure="1"
      max-field-of-view="85deg"
      auto-rotate
      camera-controls
      ar
      magic-leap
      quick-look-browsers="safari chrome"
      ref={mvRef}
      onClick={() => setIdle(true)}
      style={{ "--progress-bar-color": "rgb(28, 182, 245, 0.3)" }}
    />
  );
};

export default HeroScroller;
