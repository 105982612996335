import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { makerActions } from "../../../redux/_actions";

const selection = {
  0: "∟",
  3.2: "S╰",
  4.8: "M╰",
  6.4: "L╰",
  9.5: "XL╰",
  12.7: "2XL╰",
  100: "〇",
  "-3.2": "S ⦦",
  "-4.8": "M ⦦",
  "-6.4": "L ⦦",
  "-9.5": "XL ⦦",
  "-12.7": "2XL ⦦",
};

function RadiusSelector({ current, send }) {
  const cornerRadius = useSelector((state) => state.maker.cornerRadius);
  const dispatch = useDispatch();

  const changeRadius = (val) => {
    if (current.matches("complete")) {
      send("ADDTWO");
    }
    dispatch(makerActions.changeRadius(val));
  };

  return (
    <Dropdown style={{ float: "right" }}>
      <Dropdown.Toggle
        id="dropdown-radius-selector"
        variant="outline"
        style={{ height: "48px", width: "48px" }}
      >
        {selection[cornerRadius]}
      </Dropdown.Toggle>
      <Dropdown.Menu className="radius-menu">
        <Dropdown.Item
          as="button"
          active={cornerRadius === 0}
          onClick={(e) => changeRadius(0)}
        >
          0mm | 0" | No round
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item
          as="button"
          active={cornerRadius === 3.2}
          onClick={(e) => changeRadius(3.2)}
        >
          3.2mm | 1/8" | S round
        </Dropdown.Item>
        <Dropdown.Item
          as="button"
          active={cornerRadius === 4.8}
          onClick={(e) => changeRadius(4.8)}
        >
          4.8mm | 3/16" | M round
        </Dropdown.Item>
        <Dropdown.Item
          as="button"
          active={cornerRadius === 6.4}
          onClick={(e) => changeRadius(6.4)}
        >
          6.4mm | 1/4" | L round
        </Dropdown.Item>
        <Dropdown.Item
          as="button"
          active={cornerRadius === 9.5}
          onClick={(e) => changeRadius(9.5)}
        >
          9.5mm | 3/8" | XL round
        </Dropdown.Item>
        <Dropdown.Item
          as="button"
          active={cornerRadius === 12.7}
          onClick={(e) => changeRadius(12.7)}
        >
          12.7mm | 1/2" | 2XL round
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item
          as="button"
          active={cornerRadius === 100}
          onClick={(e) => changeRadius(100)}
        >
          100% | 〇 | Full round
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item
          as="button"
          active={cornerRadius === -3.2}
          onClick={(e) => changeRadius(-3.2)}
        >
          3.2mm | 1/8" | S bevel
        </Dropdown.Item>
        <Dropdown.Item
          as="button"
          active={cornerRadius === -4.8}
          onClick={(e) => changeRadius(-4.8)}
        >
          4.8mm | 3/16" | M bevel
        </Dropdown.Item>
        <Dropdown.Item
          as="button"
          active={cornerRadius === -6.4}
          onClick={(e) => changeRadius(-6.4)}
        >
          6.4mm | 1/4" | L bevel
        </Dropdown.Item>
        <Dropdown.Item
          as="button"
          active={cornerRadius === -9.5}
          onClick={(e) => changeRadius(-9.5)}
        >
          9.5mm | 3/8" | XL bevel
        </Dropdown.Item>
        <Dropdown.Item
          as="button"
          active={cornerRadius === -12.7}
          onClick={(e) => changeRadius(-12.7)}
        >
          12.7mm | 1/2" | 2XL bevel
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default RadiusSelector;
