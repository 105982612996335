import { API, graphqlOperation } from 'aws-amplify';

/**
 * @desc Recursively fetch all items in a list query using nextToken
 * @param {Object} query The query object from cda-graphql in use.
 * @param {Object} variables The variables to pass to query.
 * @param {Array} items Any preliminary Items already fetched
 * @param {Function} callback Optional callback function to be fired with every batch of items from query iteration.
 * @returns {Array} Array of all items received from queries.
 */
export async function fetchItemsNextToken({ query, variables, items = [], callback = undefined }) {
  const { data } = await API.graphql(graphqlOperation(query, variables));
  //const key = Object.keys(data).find(k => k.includes('items'));
  const key = Object.keys(data)[0]
  const res = data[key]; // res = { items: [], nextToken: '' }

  items.push(...res.items);
  if (callback) {
    callback(res.items);
  }
  if (!res.nextToken) return items;

  // eslint-disable-next-line no-param-reassign
  variables.nextToken = res.nextToken;
  return fetchItemsNextToken({ query, variables, items, callback });
}