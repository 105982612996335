import React, { useEffect, useRef, useState } from 'react';
import MakerButtons from './model-maker-sidebar-buttons';
import {
  backMakerRotateIcon,
  frontMakerRotateIcon,
  plusMakerIcon,
  xIcon,
} from '../../helpers/icons';
import { useHotkeys } from 'react-hotkeys-hook';
import Dropzone from 'react-dropzone';
import { parse as exifParse } from 'exifr';
import { useSelector, useDispatch } from 'react-redux';
import { makerActions } from '../../../redux/_actions';
import { sidebarStates } from '../../../redux/_constants';
import { useUser } from '../../../user-provider';

const defaultFront = '../static/placeholders/front.jpg';
const defaultBack = '../static/placeholders/back.jpg';

function ImageViewer({ current, send }) {
  const dispatch = useDispatch();
  const maker = useSelector((state) => state.maker);
  const sidebar = useSelector((state) => state.sidebar);
  const { user } = useUser();
  const {
    aspectRatio,
    dimensions,
    rotate,
    file,
    fileObj,
    cornerRadius,
    dpi,
    maxDim,
    queue,
    queueIndex,
    thickness,
    edge,
  } = maker;
  const [div, setDiv] = useState({ w: 0, h: 0 });
  const makerRef = useRef(null);

  useHotkeys(
    'left',
    (e) => {
      e.preventDefault();
      if (!sidebar.show || sidebar.selected !== sidebarStates.MAKER) {
        return;
      }
      prevQueue();
    },
    [maker]
  );

  useHotkeys(
    'right',
    (e) => {
      e.preventDefault();
      if (!sidebar.show || sidebar.selected !== sidebarStates.MAKER) {
        return;
      }
      nextQueue();
    },
    [maker]
  );

  useHotkeys(
    'a',
    (e) => {
      e.preventDefault();
      if (!sidebar.show || sidebar.selected !== sidebarStates.MAKER) {
        return;
      }
      document.getElementById('fileUpload0').click();
    },
    [current]
  );

  useHotkeys(
    'd',
    (e) => {
      e.preventDefault();
      if (!sidebar.show || sidebar.selected !== sidebarStates.MAKER) {
        return;
      }
      if (current.context.images === 0) {
        return;
      }
      rotateImages();
    },
    [current]
  );

  useHotkeys(
    's',
    (e) => {
      e.preventDefault();
      if (!sidebar.show || sidebar.selected !== sidebarStates.MAKER) {
        return;
      }
      if (current.context.images === 0) {
        return;
      }
      swapImages();
    },
    [current]
  );

  useEffect(() => {
    window.addEventListener('resize', resized);
    return () => window.removeEventListener('resize', resized);
  }, []);

  useEffect(() => {
    if (makerRef.current) {
      resized();
    }
  }, []);

  useEffect(() => {
    if (!maker.loading) {
      if (maker.complete) {
        send('COMPLETE');
      }
      if (maker.error) {
        send('ERROR');
      }
    }
  }, [maker.loading, maker.complete, maker.error, send]);

  const resized = () => {
    const bound = makerRef.current.getBoundingClientRect();
    setDiv({ w: bound.width, h: bound.height });
  };

  const toggleRotateAnimation = () => {
    document.getElementById('imgView0').style.transition = 'transform 0.5s';
    document.getElementById('imgView1').style.transition = 'transform 0.5s';

    setTimeout(() => {
      document.getElementById('imgView0').style.transition = 'initial';
      document.getElementById('imgView1').style.transition = 'initial';
    }, 500);
  };

  const rotateImages = () => {
    if (current.matches('complete')) {
      send('ADDTWO');
    }
    toggleRotateAnimation();
    dispatch(makerActions.rotateDouble());
  };

  const rotateFront = () => {
    if (current.matches('complete')) {
      send('ADDTWO');
    }
    toggleRotateAnimation();
    dispatch(makerActions.rotateSingle(0));
  };

  const rotateBack = () => {
    if (current.matches('complete')) {
      send('ADDTWO');
    }
    toggleRotateAnimation();
    dispatch(makerActions.rotateSingle(1));
  };

  const swapImages = () => {
    if (current.matches('complete')) {
      send('ADDTWO');
    }
    dispatch(makerActions.swap());
  };

  const nextQueue = async () => {
    const targetFiles = [];

    if (queueIndex + 2 < queue.length) {
      targetFiles.push(queue[queueIndex + 2]);

      if (queueIndex + 3 < queue.length) {
        targetFiles.push(queue[queueIndex + 3]);
      }

      let data = await loadFiles(targetFiles);
      let payload = {
        queueIndex: queueIndex + 2,
        queue: queue,
        ...data,
      };
      dispatch(makerActions.openFiles(payload));
    }
  };

  const prevQueue = async () => {
    const targetFiles = [];

    if (queueIndex - 2 >= 0) {
      targetFiles.push(queue[queueIndex - 2]);
      targetFiles.push(queue[queueIndex - 1]);
      let data = await loadFiles(targetFiles);
      let payload = {
        queueIndex: queueIndex - 2,
        queue: queue,
        ...data,
      };
      dispatch(makerActions.openFiles(payload));
    }
  };

  const dragFile = (files, index) => {
    const e = {
      target: {
        files: files,
      },
      preventDefault: () => {},
    };

    openFiles(e, index);
  };

  const cancelFile = (e, index) => {
    e.preventDefault();
    const payload = {
      fileObj: fileObj,
      file: file,
      aspectRatio: aspectRatio,
      dimensions: dimensions,
      rotate: rotate,
    };

    payload.fileObj[index] = null;
    payload.file[index] = null;
    payload.aspectRatio[index] = null;
    payload.dimensions[index] = null;
    payload.rotate[index] = 0;

    dispatch(makerActions.removeSingle(payload));

    if (current.matches('double')) {
      send('ADDONE');
    } else {
      send('RESET');
    }
  };

  const openFiles = async (e, index = 0) => {
    e.preventDefault();
    let payload = {
      queueIndex: queueIndex,
      queue: queue,
    };
    let targetFiles = [];

    // Open button handler, Check if canceled event
    if (e.target.files.length === 0) {
      return;
    }

    // Handle file Queue, resets Queue if > 2, otherwise replaces in queue
    if (e.target.files.length > 2) {
      payload.queue = [];
      payload.queueIndex = 0;

      for (let i = 0; i < e.target.files.length; i++) {
        payload.queue.push(e.target.files[i]);
      }
      targetFiles = [payload.queue[0], payload.queue[1]];
    } else {
      targetFiles = e.target.files;
    }

    let data = await loadFiles(targetFiles, index);
    payload = { ...payload, ...data };
    dispatch(makerActions.openFiles(payload));
  };

  const loadFiles = async (files, index = 0) => {
    let newFiles = file;
    let payload = {
      fileObj: fileObj,
      file: null,
      swapped: false,
      loading: false,
      maxDim: maxDim,
      aspectRatio: aspectRatio,
      rotate: rotate,
      dimensions: dimensions,
    };

    // Handle 2x Files at once
    if (files.length === 2) {
      newFiles = [];
      payload.fileObj = [];

      for (let i = 0; i < files.length; i++) {
        payload.fileObj.push(files[i]);
        const url = URL.createObjectURL(files[i]);
        newFiles.push(url);
        let meta = await extractMetaData(files[i]);
        payload = await getMaxDimension(url, meta, i, payload);
      }

      send('ADDTWO');
    } else {
      // Handle single file add
      if (current.matches('single')) {
        // Single state
        payload.fileObj[index] = files[0];
      } else {
        // Start / Double / Complete state
        newFiles = [null, null];
        payload.fileObj = [null, null];
        payload.fileObj[index] = files[index];
      }

      let url = URL.createObjectURL(files[0]);
      newFiles[index] = url;
      let meta = await extractMetaData(files[0]);
      payload = await getMaxDimension(url, meta, index, payload);
      send('ADDONE');
    }

    payload.file = newFiles;
    return payload;
  };

  // Get metadata for estimated width / height
  // phone 72dpi, feed scanners 150, 200, 300, 600dpi, flatbed 6400
  const extractMetaData = async (file) => {
    const data = {};

    await exifParse(file, { jfif: true }).then((result) => {
      // Todo hack: for 72 DPI photos, need to fix completely
      data.X = result.XResolution === 72 ? 600 : result.XResolution;
      data.Y = result.YResolution === 72 ? 600 : result.YResolution;
      if (result.ResolutionUnit === 'cm' || result.ResolutionUnit === 2) {
        data.units = 'cm';
      } else {
        data.units = 'inches';
      }
      return data;
    });

    return data;
  };

  const getMaxDimension = async (URL, meta, index, payload) => {
    const factor = meta.units === 'cm' ? 10.0 : 25.4;
    let img;
    const imageLoadPromise = new Promise((resolve) => {
      img = new Image();
      img.onload = function () {
        const max = Math.max(this.width, this.height);
        if (max > maxDim) {
          payload.maxDim = max;
        }

        payload.aspectRatio[index] = this.width / this.height;
        payload.dimensions[index] = {
          w: (this.width / meta.X) * factor,
          h: (this.height / meta.Y) * factor,
        };
        payload.rotate = autoRotate(payload.aspectRatio, rotate);
        resolve();
      };
      img.src = URL;
    });

    await imageLoadPromise;
    return payload;
  };

  // Checks front and back aspectRatio and auto rotates
  const autoRotate = (aspectRatio, rotate) => {
    if (aspectRatio[0] !== null && aspectRatio[1] !== null) {
      let arMatch =
        Math.abs(aspectRatio[0] - aspectRatio[1]) / aspectRatio[0] <= 0.1;
      let arInvMatch =
        Math.abs(aspectRatio[0] - 1 / aspectRatio[1]) / aspectRatio[0] <= 0.1;

      // Make rotation adjustment if can be made
      if (!arMatch && arInvMatch) {
        return [rotate[0], rotate[0] + 0.25];
      }
    }

    return [rotate[0], rotate[0]]; // reset front to back rotation
  };

  const validate = () => {
    // Missing front or back image
    if (fileObj[0] === null || fileObj[1] === null) {
      return false;
    }

    // Orientation doesn't match, note rotate is by 0.25 turns
    let oFront = Math.abs(rotate[0] % 0.5) === 0 ? 0 : 1; // 0 is for 0 / 180 / 360
    let oBack = Math.abs(rotate[1] % 0.5) === 0 ? 0 : 1;
    let arMatch = false;
    if (oFront === oBack) {
      arMatch =
        Math.abs(aspectRatio[0] - aspectRatio[1]) / aspectRatio[0] <= 0.1;
    } else {
      arMatch =
        Math.abs(aspectRatio[0] - 1 / aspectRatio[1]) / aspectRatio[0] <= 0.1;
    }
    if (!arMatch) {
      return false;
    }
    return true;
  };

  const create3d = () => {
    if (!validate()) {
      return;
    } // Post error

    if (current.matches('double')) {
      send('CREATE');
    }
    if (current.matches('complete')) {
      send('UPDATE');
    }

    const data = new FormData(); // for requesting s3 URL
    const orientation = Math.abs(rotate[0] % 0.5) === 0 ? 0 : 1;
    data.append('filename', fileObj[0].name);
    data.append('filename', fileObj[1].name);
    data.append('rotate', rotate[0] * 360);
    data.append('rotate', rotate[1] * 360);
    data.append('thickness', thickness);
    data.append('radius', cornerRadius);
    data.append('edge', edge);
    data.append('username', user.username);
    data.append('width', orientation === 0 ? dimensions[0].w : dimensions[0].h);
    data.append(
      'height',
      orientation === 0 ? dimensions[0].h : dimensions[0].w
    );

    dispatch(makerActions.create3d(data, fileObj));
  };

  const fileArray = [defaultFront, defaultBack];

  if (file) {
    if (file[0] !== null) {
      fileArray[0] = file[0];
    }
    if (file.length === 2 && file[1] !== null) {
      fileArray[1] = file[1];
    }
  }

  return (
    <div className="og-maker-sidebar" ref={makerRef}>
      {(fileArray || []).map((url, index) => {
        let scale = 100;
        let height = 100;
        let borderRadius = '';
        const containerRatio = div.w / (div.h / 2); // if > 1 vertical is constraint
        let clipPath = 'initial';

        // Scale adjustment for height
        if (aspectRatio[index] > 1) {
          // landscape
          scale = scale / aspectRatio[index];
        }
        height = containerRatio > 1 ? scale : scale * containerRatio;

        // Corner radius adjustment
        if (cornerRadius === 100) {
          clipPath = `circle(closest-side)`;
        } else if (cornerRadius > 0) {
          const cornerPercent =
            (100 * (cornerRadius / 25.4)) / ((maxDim ? maxDim : 3600) / dpi);

          if (aspectRatio[index] !== null) {
            if (aspectRatio[index] > 1) {
              borderRadius =
                cornerPercent +
                '% / ' +
                cornerPercent * aspectRatio[index] +
                '%';
            } else {
              borderRadius =
                cornerPercent / aspectRatio[index] +
                '% / ' +
                cornerPercent +
                '%';
            }
          } else {
            borderRadius =
              cornerPercent / (2 / 3) + '% / ' + cornerPercent + '%';
          }
        } else if (cornerRadius < 0) {
          // Used for beveled corners
          let hBevelOffset = 0;
          let vBevelOffset = 0;
          const bevelPercent =
            (100 * (-cornerRadius / 25.4)) / ((maxDim ? maxDim : 3600) / dpi);

          if (aspectRatio[index] !== null) {
            if (aspectRatio[index] > 1) {
              hBevelOffset = bevelPercent;
              vBevelOffset = bevelPercent * aspectRatio[index];
            } else {
              hBevelOffset = bevelPercent / aspectRatio[index];
              vBevelOffset = bevelPercent;
            }
          } else {
            hBevelOffset = bevelPercent / (2 / 3);
            vBevelOffset = bevelPercent;
          }

          clipPath = `polygon(0% ${vBevelOffset}%, ${hBevelOffset}% 0%, ${
            100 - hBevelOffset
          }% 0%, 100% ${vBevelOffset}%, 100% ${100 - vBevelOffset}%, ${
            100 - hBevelOffset
          }% 100%, ${hBevelOffset}% 100%, 0% ${100 - vBevelOffset}%, 0% ${
            100 - vBevelOffset
          }%)`;
        }

        return (
          <Dropzone
            onDrop={(files) => {
              dragFile(files, index);
            }}
            key={index}
          >
            {({ getRootProps }) => (
              <div
                {...getRootProps()}
                style={{
                  width: '100%',
                  height: 'calc(50% - 36px)',
                  position: 'relative',
                  marginTop: index === 1 ? '72px' : null,
                }}
              >
                <img
                  src={url}
                  alt="..."
                  id={'imgView' + index}
                  style={{
                    zIndex: 10 - index,
                    height: 0.9 * height + '%',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    borderRadius: borderRadius,
                    transformOrigin: 'top left',
                    objectFit: 'cover',
                    clipPath: clipPath,
                    transform:
                      'rotate(' + rotate[index] + 'turn) translate(-50%, -50%)',
                  }}
                />
                {url !== '../static/placeholders/front.jpg' &&
                url !== '../static/placeholders/back.jpg' ? (
                  <React.Fragment>
                    <button
                      type="button"
                      className="btn btn-cancel-file"
                      onClick={(e) => {
                        cancelFile(e, index);
                      }}
                    >
                      {xIcon}
                    </button>
                    <button
                      type="button"
                      className="btn btn-rotate"
                      onClick={index === 0 ? rotateFront : rotateBack}
                    >
                      {index === 0 ? frontMakerRotateIcon : backMakerRotateIcon}
                    </button>
                    <input
                      id={'fileUpload' + index}
                      type="file"
                      onChange={(e) => {
                        openFiles(e, index);
                      }}
                      multiple
                    />
                  </React.Fragment>
                ) : (
                  <button
                    type="button"
                    className="btn btn-maker-add file-upload"
                  >
                    <label
                      htmlFor={'fileUpload' + index}
                      className="file-upload"
                      style={{
                        cursor: 'pointer',
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {plusMakerIcon}
                      <input
                        id={'fileUpload' + index}
                        type="file"
                        onChange={(e) => {
                          openFiles(e, index);
                        }}
                        multiple
                      />
                    </label>
                  </button>
                )}
              </div>
            )}
          </Dropzone>
        );
      })}
      <MakerButtons
        openFiles={openFiles}
        current={current}
        nextQueue={nextQueue}
        prevQueue={prevQueue}
        swapImages={swapImages}
        rotateImages={rotateImages}
        validate={validate}
        create3d={create3d}
      />
    </div>
  );
}

export default ImageViewer;
