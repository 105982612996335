import React from 'react';
import TimeAgo from 'react-timeago'
import { formatter } from '../../helpers/timeformat-helper'
import SidebarHeader from '../helpers/sidebar-header'
//import { useSelector } from 'react-redux'
//import { useUser } from '../../../user-provider'


function Gifts(props) {
  //const [gifts, setBox] = useState([]);
  const gifts = [];
  const { menu } = props;
  //const { user } = useUser();

  // Query the API and save them to the state
  //useEffect(() => {
    //const results = user.gifts.items
    //setBox(results)
  //}, [user]);


  return (
    <React.Fragment>
      <SidebarHeader 
        title={`Gifts (${gifts ? gifts.length : 0})`}
      />
      <ul className="box-list">
        {
        	gifts ? 
            gifts.map((p, i) => {
              return (
                <li key={p.id} >
                  <button style={{textTransform: 'capitalize'}} onClick={() => menu(null)} > 
                  <div className="flat-header">
                    { `${i+1}`}
                  </div>
                  <div style={{textAlign: 'left', width: '100%'}}>{ p.name }</div>
                  <div className="flat-footer">
                    <TimeAgo date={p.createdAt} minPeriod={60} formatter={formatter} />
                  </div>
                  </button>
                </li>
              )
            }) 
         : null
        }
      </ul>
    </React.Fragment>
  );
}


export default Gifts;
