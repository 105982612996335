export const sidebarConstants = {
  SHOW: 'SIDEBAR_SHOW',
  HIDE: 'SIDEBAR_HIDE',
  CHANGEMENU: 'SIDEBAR_CHANGEMENU'
};

export const sidebarStates = {
	BLACKBOX: 'SIDEBAR_BLACKBOX',
	MAKER: 'SIDEBAR_MAKER',
	TAGS: 'SIDEBAR_TAGS',
	STACKS: 'SIDEBAR_STACKS',
	GIFTS: 'SIDEBAR_GIFTS'
};

export const initialSidebarState = {
	show: false,
	selected: sidebarStates.MAKER
};