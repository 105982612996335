import { wsConstants } from '../_constants';

export const wsActions = {
  connect,
  connected,
  disconnect,
  disconnected,
  receive,
  send,
  pending,
};

function connect(host, id, connectTimeout) {
  return { type: wsConstants.CONNECT, host, id, connectTimeout };
}

function connected(host) {
  return { type: wsConstants.CONNECTED, host };
}

function disconnect() {
  return { type: wsConstants.DISCONNECT };
}

function disconnected() {
  return { type: wsConstants.DISCONNECTED };
}

function receive(msg) {
  return { type: wsConstants.RECEIVE, msg };
}

function send(msg, makerTimeout) {
  return { type: wsConstants.SEND, msg, makerTimeout };
}

function pending(id) {
  return { type: wsConstants.PENDING, id };
}