import React from 'react';
import { Button } from 'react-bootstrap';
import {
  box2Icon,
  questionSquareIcon,
  giftIcon,
  origamiIcon,
} from '../../helpers/icons';
import { sidebarStates } from '../../../redux/_constants';
import { sidebarActions } from '../../../redux/_actions';
import { useDispatch, useSelector } from 'react-redux';
import './sidebar-footer.css';

function StackSidebarFooter() {
  const dispatch = useDispatch();
  const { selected } = useSelector((state) => state.sidebar);

  const handleClick = (e, choice) => {
    e.preventDefault();
    if (selected === choice) {
      return;
    }
    dispatch(sidebarActions.changeMenu(choice));
  };

  return (
    <div className="stack-sidebar-footer">
      <Button
        title="Black Box"
        variant="outline-secondary"
        onClick={(e) => handleClick(e, sidebarStates.BLACKBOX)}
      >
        {box2Icon}
      </Button>
      <Button
        title="Gift Box"
        variant="outline-secondary"
        onClick={(e) => handleClick(e, sidebarStates.GIFTS)}
      >
        {giftIcon}
      </Button>
      <Button
        title="Stack Tags"
        variant="outline-secondary"
        onClick={(e) => handleClick(e, sidebarStates.TAGS)}
      >
        {questionSquareIcon}
      </Button>
      <Button
        title="Make OG"
        variant="outline-secondary"
        onClick={(e) => handleClick(e, sidebarStates.MAKER)}
        style={{ padding: '9px' }}
      >
        {origamiIcon}
      </Button>
    </div>
  );
}

export default StackSidebarFooter;
