import React from 'react';
import Dropzone from 'react-dropzone';
import { Button } from 'react-bootstrap';
import { makeIcon } from './icons';
import { useDispatch, useSelector } from 'react-redux';
import { sidebarActions, makerActions } from '../../redux/_actions';
import { sidebarStates } from '../../redux/_constants';


function OGButton() {
  const dispatch = useDispatch();
  const sidebar = useSelector( state => state.sidebar );

  const handleClick = () => {
    if(sidebar.show && sidebar.selected === sidebarStates.MAKER) {
      return dispatch(sidebarActions.hide());
    }

    return dispatch(sidebarActions.show(sidebarStates.MAKER));
  }

  return (
  	<Dropzone onDrop={(acceptedFiles) => dispatch(makerActions.openFiles(acceptedFiles))} >
  		{({getRootProps}) => (
   		<Button 
        variant="outline-secondary" 
        id="og-button" 
        {...getRootProps(
          { onClick: handleClick }
        )} 
      >
				{makeIcon}
    	</Button>
    	)}
    </Dropzone>
  );
}


export default OGButton;