import React from 'react';
import { ButtonGroup, Button } from 'react-bootstrap';
import { upIcon, downIcon, taggedIcon } from './icons';
import { tagActions, sidebarActions } from '../../redux/_actions';
import { sidebarStates, tagConstants } from '../../redux/_constants';
import { notify } from '../helpers/notification';
import { useDispatch, useSelector } from 'react-redux';
import { useHotkeys } from 'react-hotkeys-hook';
import './tag.css';

function Tag(props) {
  const dispatch = useDispatch();
  const { moveUp, moveDown } = props;
  const { tag, box } = useSelector((state) => state);

  useHotkeys(
    'space',
    (e) => {
      tagHandler(e);
    },
    [tag.id, tag.items, box.status.selected, moveDown]
  );

  const tagHandler = (e) => {
    e.preventDefault();
    if (tag.name === null) {
      dispatch(sidebarActions.show(sidebarStates.TAGS));
      return;
    }

    const ogId = box.status.selected;
    const items = tag.items;
    const queueId = `${ogId}+${tag.id}`;
    let linkId = null;

    if (isSelected()) {
      notify(`Tag ${tag.name} removed.`);

      if (
        tag.tagQueue.hasOwnProperty(queueId) &&
        tag.tagQueue[queueId].type === tagConstants.TAG_REQUEST
      ) {
        linkId = tagConstants.TAG_REQUEST;
      } else {
        linkId = tag.items[ogId];
      }

      dispatch(tagActions.untag(ogId, linkId, tag.id, items));
    } else {
      notify(`Tag ${tag.name} you're it!`);
      dispatch(tagActions.tag(ogId, tag.id, items));
    }
    moveDown();
  };

  const isSelected = () => {
    return (
      box.status.selected &&
      tag.items &&
      tag.items.hasOwnProperty(box.status.selected)
    );
  };

  return (
    <ButtonGroup id="tag-interface" aria-label="Tag Interface">
      <Button className="arrow" variant="outline-secondary" onClick={moveUp}>
        {upIcon}
      </Button>
      <Button
        className={['tag', isSelected() ? 'tagged' : ''].join(' ')}
        variant="outline"
        onClick={tagHandler}
      >
        <span>{tag.id === null ? 'Select' : tag.name}&nbsp;&nbsp;</span>
        {taggedIcon}
      </Button>
      <Button className="arrow" variant="outline-secondary" onClick={moveDown}>
        {downIcon}
      </Button>
    </ButtonGroup>
  );
}

export default Tag;
