import { wsConstants, initialWsState } from '../_constants';


export function ws(state = initialWsState, action) {
  switch(action.type) {
    case wsConstants.CONNECT: 
      console.log("Connecting...")
      return { ...state, host: action.host, id: action.id, connecting: action.connectTimeout };
    case wsConstants.CONNECTED:
      console.log('Connected.');
      clearTimeout(state.connecting);
      return { ...state, connecting: null, connected: true };
    case wsConstants.PENDING:
      console.log('Upload(s) complete.')
      return { ...state, pending: action.id };
    case wsConstants.SEND:
      console.log("Creating Origami...")
      return { ...state, pending: null, making: action.makerTimeout };
    case wsConstants.RECEIVE:
      console.log("Origami created.");
      clearTimeout(state.making);
      return { ...state, making: null };
    case wsConstants.DISCONNECT:
      console.log("Disconnecting...");
      return state;
    case wsConstants.DISCONNECTED: 
      console.log("Connection closed.");
      return initialWsState;
    default: 
      return state;
  }
}

