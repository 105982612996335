import React from 'react';
import { Modal, Button } from 'react-bootstrap'


function DeleteOG(props) {
  const { toggleDel, toggleShowDel, deleteOG } = props;

  return (
    <Modal 
      show={toggleDel} 
      onHide={toggleShowDel}
      centered
      size="sm"
      className="delete-og-modal"
      animation={false}
    >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>This OG will be removed from all of your stacks and boxes but NOT gifts that have been sent.</p>
          <p style={{marginBottom: '0'}}>Are you sure you want to delete this OG?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button className="save-button" variant="light" onClick={toggleShowDel}>
            Cancel
          </Button>
          <Button className="save-button" variant="danger" onClick={deleteOG}>
            Delete
          </Button>
        </Modal.Footer>
    </Modal>
  )
}

export default DeleteOG;