export const wsConstants = {
  CONNECT: 'WS_CONNECT',
  CONNECTED: 'WS_CONNECTED',
  DISCONNECT: 'WS_DISCONNECT',
  DISCONNECTED: 'WS_DISCONNECTED',
  SEND: 'WS_SEND',
  RECEIVE: 'WS_RECEIVE',
  PENDING: 'WS_PENDING',
};

export const initialWsState = {
  host: '',
  connected: false,
  connecting: null,
  id: '',
  making: null,
  pending: null
}