import React from 'react';
import { Button } from 'react-bootstrap'
import StackViewSelector from '../../topbar/stack-view-selector'
import { listIcon } from '../../helpers/icons'
import { useDispatch } from 'react-redux'
import { sidebarActions } from '../../../redux/_actions'


function StackSidebarHeader(props) {
  const { sortBy, expandStack, expanded, justifyStack, justify, sort, sortDir, title } = props
  const dispatch = useDispatch();

  return (
    <div className="stack-sidebar-header" >
      <Button variant='outline' onClick={() => dispatch(sidebarActions.hide())} >{listIcon}</Button>
      <span className='title'>{title}</span>
      <StackViewSelector 
        sortBy={sortBy} 
        expandStack={expandStack} 
        expanded={expanded} 
        justifyStack={justifyStack}
        justify={justify}
        sort={sort}
        sortDir={sortDir}
      />
    </div>
  );
}


export default StackSidebarHeader;