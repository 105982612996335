import { combineReducers } from 'redux';
import { alert } from './alert.reducer';
import { maker } from './maker.reducer';
import { sidebar } from './sidebar.reducer';
import { ws } from './ws.reducer';
import { tag } from './tag.reducer';
import { box } from './box.reducer';
import { stack } from './stack.reducer';

const rootReducer = combineReducers({
  alert,
  maker,
  sidebar,
  ws,
  tag,
  box,
  stack
});

export default rootReducer;