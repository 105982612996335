import React, { useState, useEffect, useCallback } from 'react';
import { Auth, I18n, API, graphqlOperation } from 'aws-amplify';
import Amplify from 'aws-amplify';
import awsmobile from './aws-exports';
import { AuthLabels } from './components/homepage/auth-theme';
import {
  getUser as GetUser,
  createUser as CreateUser,
  //getBoxes as GetBoxes,
  createUserBox,
  createUserStack,
} from './graphql/graphql';

Amplify.configure(awsmobile);

export const UserContext = React.createContext(null);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  const checkIfUserExists = useCallback(
    async (id) =>
      await API.graphql(graphqlOperation(GetUser, { id }))
        .then((user) => {
          const { getUser } = user.data;
          if (!getUser) {
            const newUser = createUser(id);
            return newUser;
          }
          return getUser;
        })
        .catch((err) => {
          throw err;
        }),
    []
  );

  const createUser = async (username) =>
    await API.graphql(
      graphqlOperation(CreateUser, { username: username, id: username })
    )
      .then(async (user) => {
        const bb = await API.graphql(
          graphqlOperation(createUserBox, {
            ownerId: username,
            private: true,
            name: 'blackbox',
            type: 'BLACK',
          })
        );
        const wb = await API.graphql(
          graphqlOperation(createUserBox, {
            ownerId: username,
            private: false,
            name: 'whitebox',
            type: 'WHITE',
          })
        );
        await Promise.all([
          API.graphql(
            graphqlOperation(createUserStack, {
              boxId: bb.data.createBox.id,
              private: true,
              name: 'unsorted',
            })
          ),
          API.graphql(
            graphqlOperation(createUserStack, {
              boxId: wb.data.createBox.id,
              private: false,
              name: 'unsorted',
            })
          ),
        ]);
      })
      .then(async (username) => {
        const user = await API.graphql(graphqlOperation(GetUser, { username }));
        return user;
      })
      .catch((err) => {
        throw err;
      });

  useEffect(() => {
    // Configure the keys needed for the Auth module. Essentially this is
    // like calling `Amplify.configure` but only for `Auth`.
    I18n.setLanguage('en');
    I18n.putVocabularies(AuthLabels);
    Auth.configure();

    // attempt to fetch the info of the user that was already logged in
    Auth.currentAuthenticatedUser()
      .then(async (user) => {
        setUser(user);
        const currentUser = await checkIfUserExists(user.username);
        console.log('currentUser: ', currentUser);
        user.origamis = currentUser.origamis || {};
        user.boxes = currentUser.boxes || {};
        user.gifts = currentUser.gifts || {};
      })
      .then(() => setIsAuthenticating(false))
      .catch((error) => {
        console.log(error);
        setUser(null);
        setIsAuthenticating(false);
      });
  }, [checkIfUserExists]);

  // We make sure to handle the user update here, but return the resolve value in order for our components to be
  // able to chain additional `.then()` logic. Additionally, we `.catch` the error and "enhance it" by providing
  // a message that our React components can use.
  const login = async (usernameOrEmail, password) =>
    await Auth.signIn(usernameOrEmail, password)
      .then(async (user) => {
        setUser(user);
        const currentUser = await checkIfUserExists(user.username);
        user.origamis = currentUser.origamis || {};
        user.boxes = currentUser.boxes || {};
        user.gifts = currentUser.gifts || {};
        return user;
      })
      .catch((err) => {
        if (err.code === 'UserNotFoundException') {
          err.message = 'Invalid username or password';
        }

        throw err;
      });

  // same thing here
  const logout = async () =>
    await Auth.signOut().then((data) => {
      setUser(null);
      return data;
    });

  const confirmLogin = async (user) => {
    setUser(user);
    const currentUser = await checkIfUserExists(user.username);
    user.origamis = currentUser.origamis || {};
    user.boxes = currentUser.boxes || {};
    user.gifts = currentUser.gifts || {};
  };

  // Wrapper for SignIn amplify custom widget
  const WithSignIn = (Component) => {
    function withSignIn(props) {
      return <Component login={login} confirmLogin={confirmLogin} {...props} />;
    }
    return withSignIn;
  };

  // Wrapper to get User info to Class
  const WithUser = (Component) => {
    function withUser(props) {
      return <Component user={user} {...props} />;
    }
    return withUser;
  };

  // Make sure to not force a re-render on the components that are reading these values,
  // unless the `user` value has changed. This is an optimisation that is mostly needed in cases
  // where the parent of the current component re-renders and thus the current component is forced
  // to re-render as well. If it does, we want to make sure to give the `UserContext.Provider` the
  // same value as long as the user data is the same. If you have multiple other "controller"
  // components or Providers above this component, then this will be a performance booster.
  //const values = React.useMemo(() => ({ user, login, logout, WithSignIn, Test }), [user, WithSignIn]);
  const values = {
    user,
    login,
    logout,
    WithSignIn,
    confirmLogin,
    WithUser,
    isAuthenticating,
  };

  // Finally, return the interface that we want to expose to our other components
  //return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
};

// We also create a simple custom hook to read these values from. We want our React components
// to know as little as possible on how everything is handled, so we are not only abtracting them from
// the fact that we are using React's context, but we also skip some imports.
export const useUser = () => {
  const context = React.useContext(UserContext);

  if (context === undefined) {
    throw new Error(
      '`useUser` hook must be used within a `UserProvider` component'
    );
  }
  return context;
};
