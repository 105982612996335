import graphql from "graphql-tag";
//import gql from 'graphql-tag';

// mutations
const createUser = graphql`
  mutation ($username: String!, $id: ID!) {
    createUser(input: { username: $username, status: CREATED, id: $id }) {
      id
      username
      createdAt
      status
    }
  }
`;

const getUser = graphql`
  query getUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      boxes(limit: 1000) {
        items {
          id
          name
          private
          type
          stacks(limit: 1000) {
            items {
              id
            }
          }
        }
      }
    }
  }
`;

/*
const getBoxes = graphql`
  query ($id: ID!) {
    listBoxs(id: $id) {
      id
      username
    }
  }
`
*/

const editStackName = /* GraphQL */ `
  mutation ($id: ID!, $name: String) {
    updateStack(input: { id: $id, name: $name }) {
      id
      name
    }
  }
`;

const getStack = graphql`
  query GetStack($id: ID!) {
    getStack(id: $id) {
      id
      origamiLinks {
        items {
          origami {
            id
          }
        }
        nextToken
      }
      name
      members
      private
      createdAt
      updatedAt
      owner
    }
  }
`;

const listBlackBoxStacks = graphql`
  query listStacks(
    $limit: Int
    $nextToken: String
    $filter: ModelStackFilterInput
  ) {
    listStacks(limit: $limit, nextToken: $nextToken, filter: $filter) {
      items {
        id
        name
        box {
          id
          name
        }
        members
        private
        createdAt
        updatedAt
        origamiLinks(
          limit: $limit
          filter: { status: { ne: "deleted" } }
          nextToken: $nextToken
        ) {
          items {
            id
            origamiId
          }
          nextToken
        }
      }
    }
  }
`;

const listOrigamiLinks = graphql`
  query listOrigamiLinks($limit: Int, $nextToken: String) {
    listOrigamiLinks(
      limit: $limit
      nextToken: $nextToken
      filter: { status: { ne: "deleted" } }
    ) {
      items {
        id
        origamiId
        createdAt
        updatedAt
        stackId
        owner
      }
      nextToken
    }
  }
`;

const SubscribeToNewLinks = `
  subscription OnCreateOrigamiLink($owner: String!) {
    onCreateOrigamiLink(owner: $owner) {
      id
      origamiId
      createdAt
      updatedAt
      stackId
      owner
    }
  }
`;

const SubscribeToUpdateLinks = `
  subscription OnUpdateOrigamiLink($owner: String!) {
    onUpdateOrigamiLink(owner: $owner) {
      id
      origamiId
      createdAt
      updatedAt
      stackId
      owner
    }
  }
`;

const SubscribeToNewStacks = `
  subscription OnCreateStack($owner: String!) {
    onCreateStack(owner: $owner) {
      id
      name
      box {
        id
        name
      }
      members
      private
      createdAt
      updatedAt
      origamiLinks (
        limit: 1000
      ) {
        items {
          origami {
            id
          }
        } 
        nextToken
      }
    }
  }
`;

const SubscribeToNewOrigami = `
  subscription OnCreateOrigami($username: String!) {
    onCreateOrigami(username: $username) {
      id
      username
      createdAt
      status
      params {
        frontImage
        backImage
        rotate
        thickness
        radius
        edge
        width
        height
      }
    }
  }
`;

const createUserBox = `
  mutation($ownerId: ID!, $private: Boolean!, $name: String, $type: BoxType!) {
    createBox(input: {
      ownerId: $ownerId
      private: $private
      name: $name
      type: $type
      status: "CREATED"
    }) {
      id ownerId private createdAt type
    }
  }
`;

const createOrigami = `
  mutation ($input: CreateOrigamiInput!) {
    createOrigami(input: $input) {
      id
      username
      createdAt
      status
      params {
        frontImage
        backImage
        rotate
        thickness
        radius
        edge
        width
        height
      }
      origamiLinks {
        items { 
          id
        }
      }
    }
  }
`;

const deleteOrigami = `
  mutation($id: ID!) {
    updateOrigami(input: {
      id: $id
      status: "deleted"
    }) {
      id updatedAt status username
    }
  }
`;

const getOrigamiEdit = `
  query GetOrigami($id: ID!) {
    getOrigami(
      id: $id
    ) {
      id
      username
      createdAt
      updatedAt
      status
      params {
        frontImage
        backImage
        rotate
        thickness
        radius
        edge
        width
        height
      }
    }
  }
`;

const createUserStack = `
  mutation($boxId: ID!, $private: Boolean!, $name: String! ) {
    createStack(input: {
      boxId: $boxId
      private: $private
      name: $name
      status: "CREATED"
    }) {
      id
      origamiLinks {
        items {
          origami {
            createdAt
            status
            params {
              thickness
              radius
              edge
              width
              height
            }
          }
        }
      }
      name
      members
      private
      createdAt
      updatedAt
      boxId
      owner
    }
  }
`;

const createOrigamiLink = `
  mutation($stackId: ID!, $origamiId: ID!) {
    createOrigamiLink(input: {
      stackId: $stackId
      origamiId: $origamiId
      status: "created"
    }) {
      id stackId origamiId createdAt owner
    }
  }
`;

const deleteOrigamiLink = `
  mutation($id: ID!) {
    updateOrigamiLink(input: {
      id: $id
      status: "deleted"
    }) {
      id stackId origamiId createdAt owner
    }
  }
`;

const scanOrigamis = `
  query scanOrigamis {
    scanOrigamis(
      indexName: "createdAt"
    ) {
      items {
        id
        username
        createdAt
        status
        params {
          thickness
          radius
          edge
          width
          height
        }
      }
    }
  }
`;

const origamisByUsername = /* GraphQL */ `
  query OrigamisByUsername(
    $username: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $limit: Int
    $nextToken: String
  ) {
    origamisByUsername(
      username: $username
      createdAt: $createdAt
      sortDirection: $sortDirection
      limit: $limit
      nextToken: $nextToken
      filter: { status: { ne: "deleted" } }
    ) {
      items {
        id
        username
        createdAt
        status
        params {
          thickness
          radius
          edge
          width
          height
        }
        origamiLinks(limit: 1000, filter: { status: { ne: "deleted" } }) {
          items {
            id
            createdAt
            updatedAt
            stack {
              name
              id
            }
          }
        }
      }
      nextToken
    }
  }
`;

export {
  createUser,
  getUser,
  createUserBox,
  createUserStack,
  createOrigami,
  createOrigamiLink,
  deleteOrigamiLink,
  SubscribeToNewStacks,
  listBlackBoxStacks,
  SubscribeToNewOrigami,
  SubscribeToNewLinks,
  SubscribeToUpdateLinks,
  scanOrigamis,
  origamisByUsername,
  getStack,
  listOrigamiLinks,
  deleteOrigami,
  editStackName,
  getOrigamiEdit,
};
