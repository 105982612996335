export const tagConstants = {
  SELECT: 'TAG_SELECT',
  TAG_REQUEST: 'TAG_TAG_REQUEST',
  TAG_SUCCESS: 'TAG_TAG_SUCCESS',
  TAG_FAILURE: 'TAG_TAG_FAILURE',
  UNTAG_REQUEST: 'TAG_UNTAG_REQUEST',
  UNTAG_SUCCESS: 'TAG_UNTAG_SUCCESS',
  UNTAG_FAILURE: 'TAG_UNTAG_FAILURE',
  UNTAG_CANCELED: 'TAG_UNTAG_CANCELED',
  STACK_REQUEST: 'TAG_STACK_REQUEST',
  STACK_SUCCESS: 'TAG_STACK_SUCCESS',
  STACK_FAILURE: 'TAG_STACK_FAILURE',
};

export const initialTagState = {
  name: null, 
  id: null, 
  tagging: false, 
  isTagged: false,
  loaded: false,
  items: [],
  tagQueue: {} 	// pending tag/untag requests
};