import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { alertActions } from '../../redux/_actions';
import { xCircleIcon } from './icons';


function ErrorModal() {
  const { alert } = useSelector(state => state);
  const dispatch = useDispatch();

  const clearError = () => {
    dispatch(alertActions.clear());
  }

  return (
    <Modal 
      show={true} 
      onHide={clearError}
      centered
      size="sm"
      className="error-modal"
      animation={false}
    >
        <Modal.Header closeButton>
          <Modal.Title >
            {xCircleIcon}
            <span>Error</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { alert.message }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={clearError} >Close</Button>
        </Modal.Footer>
    </Modal>
  )
}

export default ErrorModal;