import { boxConstants, tagConstants } from '../_constants';
import { boxService } from '../_services';
import { boxUtilities } from '../_utilities';
import { alertActions, stackActions } from './';
import { normalize, schema } from 'normalizr';
import { store } from '../store';


export const boxActions = {
  select,
  load,
  sort,
  floatTagged,
  shuffle,
  addOrigami,
  deleteOrigami,
  addLink,
  deleteLink,
  moveUp,
  moveDown
};


// Normalized data schema
const tag = new schema.Entity('tags');      // linkId stored
const link = new schema.Entity('links', {
  stack: tag
});
const origami = new schema.Entity('origamis', {
  origamiLinks: {
    items: [link]
  }
});


function moveUp(){
  const { allIds } = store.getState().box.origamis;
  const { selected } = store.getState().box.status;
  const indexOf = allIds.findIndex(i => i === selected);

  if (indexOf !== 0 && indexOf !== null) {
    return(select(allIds[indexOf - 1]));
  }
  return { type: boxConstants.SELECT, id: selected };
}


function moveDown() {
  const { allIds } = store.getState().box.origamis;
  const { selected } = store.getState().box.status;
  const indexOf = allIds.findIndex(i => i === selected);

  if (indexOf !== (allIds.length - 1) && indexOf !== null) {
    return(select(allIds[indexOf + 1]));
  }  
  return { type: boxConstants.SELECT, id: selected };
}


function select(id) {
  return { type: boxConstants.SELECT, id };
}


function floatTagged(tagId, allIds, byId, linksById) {
  const result = boxUtilities.floatTagged(tagId, allIds, byId, linksById);
  return { type: boxConstants.FLOAT_TAGGED, result };
}


function addLink(link) {
  const { byId } = store.getState().box.origamis;

  if (link.id !== tagConstants.TAG_REQUEST) {
    const index = byId[link.origamiId].origamiLinks.items.indexOf(tagConstants.TAG_REQUEST);    
    if (index !== -1) {
      byId[link.origamiId].origamiLinks.items[index] = link.id;
    }
  } else {
    byId[link.origamiId].origamiLinks.items.push(link.id);
    return { type: boxConstants.ADD_LINK_PENDING, byId };
  }

  return { type: boxConstants.ADD_LINK, byId };
}


function deleteLink(link) {
  const { byId } = store.getState().box.origamis;
  const newTags = byId[link.origamiId].origamiLinks.items.filter(id => id !== link.id);
  byId[link.origamiId].origamiLinks.items = newTags;
  return { type: boxConstants.DELETE_LINK, byId }; 
}


function shuffle(allIds) {
  return dispatch => {
    dispatch(request());
    const result = boxUtilities.shuffle(allIds);
    dispatch(success(result));
  }
  
  function request() { return { type: boxConstants.LOADING } };
  function success(result) { return { type: boxConstants.SHUFFLE, result } };
}


function sort(sortBy, allIds, byId) {
  const result = boxUtilities.sort(sortBy, allIds, byId);
  return { type: sortBy, result };
}


function addOrigami(data) {
  const { allIds, byId } = store.getState().box.origamis;

  const newAllIds = [ data.id, ...allIds ];
  const newOrigami = {};
  newOrigami[data.id] = data;
  const newById = { ...byId, ...newOrigami };

  return { type: boxConstants.ADD_ORIGAMI, origamis: { allIds: newAllIds, byId: newById }, id: data.id };
}


function deleteOrigami(id, nextId) {
  // Remove from box and mark origami deleted
  // Mark all links that reference this origami deleted
  return dispatch => {
    const { allIds, byId } = store.getState().box.origamis;
    const newAllIds = allIds.filter(i => i !== id);
    const og = byId[id];
    delete byId[id];    
    const newByIds = Object.assign(byId);
    const data = { byId: newByIds, allIds: newAllIds, nextId: nextId };
    dispatch(request(data));
    dispatch(stackActions.deleteLinksForOrigami(og));

    boxService.deleteOG(id)                               
    .then(res => {  
      dispatch(success());
    })
    .catch(error => { 
      dispatch(failure(error.toString()));
      dispatch(alertActions.error(error.toString()));
    })
  };

  function request(data) { return { type: boxConstants.DELETE_OG_REQUEST, data } };
  function success() { return { type: boxConstants.DELETE_OG_SUCCESS } };
  function failure(error) { return { type: boxConstants.DELETE_OG_FAILURE } };
}


function load(username) {
  return dispatch => {
    dispatch(request());

    boxService.load(username)                               // Get upload tokens
    .then(res => {  
      const normalizedOrigami = normalize(res, [origami]);
      dispatch(success({ origamis: normalizedOrigami }));
    })
    .catch(error => { 
      dispatch(failure(error.toString()));
      dispatch(alertActions.error(error.toString()));
    })
  };

  function request() { return { type: boxConstants.LOAD_REQUEST } };
  function success(data) { return { type: boxConstants.LOAD_SUCCESS, data } };
  function failure(error) { return { type: boxConstants.LOAD_FAILURE } };
}