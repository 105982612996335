import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { editIcon, trashIcon, pencilEditIcon } from '../helpers/icons';
import DeleteOG from './delete-og-modal';
import { useDispatch, useSelector } from 'react-redux';
import { sidebarStates } from '../../redux/_constants';
import { makerActions, sidebarActions } from '../../redux/_actions';

function EditSelector(props) {
  const { deleteLine, modelID } = props;
  const [toggleDel, setToggleDel] = useState(false);
  const selected = useSelector((state) => state.box?.status?.selected);
  const dispatch = useDispatch();

  const toggleShowDel = () => {
    setToggleDel(!toggleDel);
  };

  const deleteOG = (e) => {
    toggleShowDel();
    deleteLine(e, modelID);
  };

  const toggleEdit = () => {
    dispatch(makerActions.edit3d(selected));
    dispatch(sidebarActions.show(sidebarStates.MAKER));
  };

  return (
    <Dropdown style={{ float: 'right' }}>
      <Dropdown.Toggle
        id="dropdown-edit-selector"
        variant="outline"
        style={{ height: '48px' }}
        disabled={!selected}
      >
        {editIcon}
      </Dropdown.Toggle>
      <Dropdown.Menu className="edit-selector">
        <Dropdown.Item as="button" onClick={() => toggleEdit()}>
          Edit
          <span style={{ float: 'right', marginTop: '-2px' }}>
            {pencilEditIcon}
          </span>
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item
          className="text-danger"
          as="button"
          onClick={() => toggleShowDel()}
        >
          Delete
          <span style={{ float: 'right' }}>{trashIcon}</span>
        </Dropdown.Item>
      </Dropdown.Menu>
      <DeleteOG
        toggleDel={toggleDel}
        toggleShowDel={toggleShowDel}
        deleteOG={deleteOG}
      />
    </Dropdown>
  );
}

export default EditSelector;
