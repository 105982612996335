import { wsActions, makerActions } from '../_actions';
import { wsConstants } from '../_constants';


export const createWS = () => {
  let socket = null;

  const onOpen = store => (event) => {
    store.dispatch(wsActions.connected());

    // Handle upload completed before WS open
    const { pending } = store.getState().ws;
    if (pending !== null) {
      store.dispatch(makerActions.kickoff3d());
    }
  };

  const onClose = store => () => {
    store.dispatch(wsActions.disconnected());
  };

  const onMessage = store => (event) => {
    const payload = JSON.parse(event.data);
    const { maker } = store.getState();
    store.dispatch(makerActions.created3d(maker.modelID, maker.upload.data));
    store.dispatch(wsActions.receive(payload));
  };

  // the middleware part of this function
  return store => next => action => {
    switch (action.type) {
      case wsConstants.CONNECT:
        if (socket !== null) {
          socket.close();
        }

        // connect to the remote host
        socket = new WebSocket(action.host);

        // websocket handlers
        socket.onmessage = onMessage(store);
        socket.onclose = onClose(store);
        socket.onopen = onOpen(store);
        return next(action);
      case wsConstants.DISCONNECT:
        if (socket !== null) {
          socket.close();
        }
        socket = null;
        return next(action);
      case wsConstants.SEND:
        socket.send(action.msg)
        return next(action);
      default:
        return next(action);
    }
  };
};
