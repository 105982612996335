import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from "react-router-dom";
import { UserProvider } from './user-provider';
import { Provider } from 'react-redux'
import { store } from './redux/store'


ReactDOM.render(
  <UserProvider>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </UserProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();
