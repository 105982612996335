import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from './_reducers';
import { createWS } from './_middleware';


const logger = createLogger();
const ws = createWS();
let middleware = [];


if (process.env.NODE_ENV === 'development') {
  middleware = [...middleware, thunk, logger, ws];
} else {
  middleware = [...middleware, thunk, ws];
}


export const store = createStore(
	rootReducer,
	applyMiddleware(...middleware)
);