import { tagConstants } from '../_constants';
import { tagService } from '../_services';
import { stackUtilities } from '../_utilities';
import { alertActions, stackActions, boxActions } from './';
import { store } from '../store';

export const tagActions = {
  select,
  tag,
  untag,
  load,
  updateTagItem,
};

// Where object is the byId dictionary, and array is allIds (normalized data)
function select(name, id, object, array) {
  const items = stackUtilities.filter(
    id,
    object,
    array,
    'stackId',
    'origamiId'
  );
  return { type: tagConstants.SELECT, name, id, items };
}

// Used to replace tagConstants.TAG_PENDING placeholder until id is returned from API
function updateTagItem(link, items) {
  items[link.origamiId] = link.id;
  return { type: tagConstants.TAG_PENDING, items };
}

function untag(id, linkId, stackId, items) {
  const { tagQueue } = store.getState().tag;
  const queueId = `${id}+${stackId}`;
  let queueIndex = 0;
  let nextQueue = null;

  return (dispatch) => {
    // Doesn't exist, cannot remove
    if (items && !items.hasOwnProperty(id)) {
      dispatch(failure('Tag does not exist.'));
      dispatch(alertActions.error('Tag does not exist.'));
    } else {
      // Optimistic UI update
      if (items !== null && items.hasOwnProperty(id)) {
        delete items[id];
      }

      if (tagQueue.hasOwnProperty(queueId)) {
        queueIndex = tagQueue[queueId].index + 1;
      }

      tagQueue[queueId] = {
        index: queueIndex,
        type: tagConstants.UNTAG_REQUEST,
      };

      dispatch(request());
      dispatch(stackActions.deleteLink({ id: linkId, stackId: stackId }));
      dispatch(
        boxActions.deleteLink({ id: linkId, origamiId: id, stackId: stackId })
      );

      if (linkId === tagConstants.TAG_REQUEST) {
        dispatch(canceled());
      } else {
        tagService
          .untag(linkId)
          .then((res) => {
            //const link = res.data.updateOrigamiLink;
            nextQueue = store.getState().tag.tagQueue;

            if (nextQueue[queueId].type === tagConstants.UNTAG_REQUEST) {
              nextQueue = {}; // was the last in queue
            }

            dispatch(success());
          })
          .catch((error) => {
            dispatch(failure(error.toString()));
            dispatch(alertActions.error(error.toString()));
          });
      }
    }
  };

  function request() {
    return { type: tagConstants.UNTAG_REQUEST, items, tagQueue };
  }
  function success() {
    return { type: tagConstants.UNTAG_SUCCESS, nextQueue };
  }
  function canceled() {
    return { type: tagConstants.UNTAG_CANCELED };
  }
  function failure(error) {
    return { type: tagConstants.UNTAG_FAILURE };
  }
}

function tag(id, stackId, items) {
  const { tagQueue } = store.getState().tag;
  const queueId = `${id}+${stackId}`;
  let queueIndex = 0;
  let nextQueue = null;

  return (dispatch) => {
    // Already exists, do not add extra tag
    if (items && items[id] === stackId) {
      dispatch(failure('Tag already exists.'));
      dispatch(alertActions.error('Tag already exists.'));
    } else {
      // Optimistic UI update, update both items for checkmark & linklist for #
      if (items !== null) {
        items[id] = tagConstants.TAG_REQUEST;
      }

      if (tagQueue.hasOwnProperty(queueId)) {
        queueIndex = tagQueue[queueId].index + 1;
      }

      tagQueue[queueId] = {
        index: queueIndex,
        type: tagConstants.TAG_REQUEST,
      };

      dispatch(request());
      dispatch(
        stackActions.addLink({ id: tagConstants.TAG_REQUEST, stackId: stackId })
      );
      dispatch(
        boxActions.addLink({
          id: tagConstants.TAG_REQUEST,
          origamiId: id,
          stackId: stackId,
        })
      );

      tagService
        .tag(id, stackId)
        .then((res) => {
          const link = res.data.createOrigamiLink;
          nextQueue = store.getState().tag.tagQueue;

          if (nextQueue[queueId].type === tagConstants.TAG_REQUEST) {
            // Add new link to currently selected tag list
            if (items !== null) {
              items[id] = link.id;
            }
            nextQueue = {}; // was the last in queue
            dispatch(success());
            dispatch(stackActions.addLink(link));
            dispatch(boxActions.addLink(link));
          } else {
            // if UNTAG_REQUEST in queue then kill tag
            dispatch(untag(id, link.id, stackId, null));
          }
        })
        .catch((error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        });
    }
  };

  function request() {
    return { type: tagConstants.TAG_REQUEST, items, tagQueue };
  }
  function success() {
    return { type: tagConstants.TAG_SUCCESS, items, nextQueue };
  }
  function failure(error) {
    return { type: tagConstants.TAG_FAILURE };
  }
}

function load(id) {
  return (dispatch) => {
    dispatch(request());

    tagService
      .load(id)
      .then((res) => {
        dispatch(success(res.data.getStack));
      })
      .catch((error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      });
  };

  function request() {
    return { type: tagConstants.STACK_REQUEST };
  }
  function success(data) {
    return { type: tagConstants.STACK_SUCCESS, data };
  }
  function failure(error) {
    return { type: tagConstants.STACK_FAILURE };
  }
}
