import { useEffect } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import '@google/model-viewer';

export function useModelViewer({ selector, src, iosSrc }) {
  const modelViewer = selector && document.querySelector(`#${selector}`);
  //const tapDistance = 2;

  useHotkeys(
    'f',
    (e) => {
      e.preventDefault();
      if (modelViewer) {
        modelViewer.flip();
      }
    },
    [modelViewer]
  );

  useHotkeys(
    'r',
    (e) => {
      e.preventDefault();
      if (modelViewer) {
        modelViewer.rotate();
      }
    },
    [modelViewer]
  );

  useEffect(() => {
    if (!modelViewer) {
      return;
    }

    let panning = false;
    let panX, panY;
    let startX, startY;
    let lastX, lastY;
    let metersPerPixel;
    let startCameraTarget;

    modelViewer &&
      modelViewer.addEventListener(
        'mousedown',
        (event) => {
          startX = event.clientX;
          startY = event.clientY;
          panning = event.ctrlKey || event.metaKey || event.shiftKey;
          if (!panning) return;

          lastX = startX;
          lastY = startY;
          startPan();
          event.stopPropagation();
        },
        true
      );

    /*
    modelViewer &&
      modelViewer.addEventListener(
        'touchstart',
        (event) => {
          const { targetTouches, touches } = event;
          startX = targetTouches[0].clientX;
          startY = targetTouches[0].clientY;
          console.log('touch start');
          panning =
            targetTouches.length === 2 &&
            targetTouches.length === touches.length;
          if (!panning) return;

          lastX = 0.5 * (targetTouches[0].clientX + targetTouches[1].clientX);
          lastY = 0.5 * (targetTouches[0].clientY + targetTouches[1].clientY);
          startPan();
          event.stopPropagation();
          console.log('touch start');
        },
        true
      );

    modelViewer &&
      modelViewer.addEventListener(
        'touchmove',
        (event) => {
          if (!panning || event.targetTouches.length !== 2) return;

          const { targetTouches } = event;
          const thisX =
            0.5 * (targetTouches[0].clientX + targetTouches[1].clientX);
          const thisY =
            0.5 * (targetTouches[0].clientY + targetTouches[1].clientY);
          movePan(thisX, thisY);
          event.stopPropagation();
        },
        true
      );

    modelViewer &&
      modelViewer.addEventListener(
        'touchend',
        (event) => {
          if (event.targetTouches.length === 0) {
            deadCenter();
            if (event.cancelable) {
              event.preventDefault();
            }
          }
          event.stopPropagation();
        },
        true
      );
  */
    const startPan = () => {
      const orbit = modelViewer.getCameraOrbit();
      const { theta, phi, radius } = orbit;
      const psi = theta - modelViewer.turntableRotation;
      metersPerPixel =
        (0.75 * radius) / modelViewer.getBoundingClientRect().height;
      panX = [-Math.cos(psi), 0, Math.sin(psi)];
      panY = [
        -Math.cos(phi) * Math.sin(psi),
        Math.sin(phi),
        -Math.cos(phi) * Math.cos(psi),
      ];
      modelViewer.interactionPrompt = 'none';
      startCameraTarget = modelViewer.getCameraTarget();
    };

    const deadCenter = () => {
      panning = false;
      if (startCameraTarget) {
        modelViewer.cameraTarget = `${startCameraTarget.x}m ${startCameraTarget.y}m ${startCameraTarget.z}m`;
      } else {
        modelViewer.cameraTarget = `auto auto auto`;
      }
    };

    const movePan = (thisX, thisY) => {
      const dx = (thisX - lastX) * metersPerPixel;
      const dy = (thisY - lastY) * metersPerPixel;
      lastX = thisX;
      lastY = thisY;

      const target = modelViewer.getCameraTarget();
      target.x += dx * panX[0] + dy * panY[0];
      target.y += dx * panX[1] + dy * panY[1];
      target.z += dx * panX[2] + dy * panY[2];
      modelViewer.cameraTarget = `${target.x}m ${target.y}m ${target.z}m`;

      // This pauses turntable rotation
      modelViewer.dispatchEvent(
        new CustomEvent('camera-change', {
          detail: { source: 'user-interaction' },
        })
      );
    };

    const handleMouseUp = (event) => {
      if (panning) {
        deadCenter();
      }
    };

    const handleMouseMove = (event) => {
      if (!panning) return;
      movePan(event.clientX, event.clientY);
      event.stopPropagation();
    };

    window.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [modelViewer]);

  return (
    <model-viewer
      id={selector}
      src={src}
      ios-src={iosSrc}
      alt="3D Model"
      shadow-intensity="0"
      environment-image="/static/white.png"
      camera-orbit="0deg auto auto"
      camera-controls
      ar
      quick-look-browsers="safari chrome"
      style={{ width: '100%', height: '100%', border: 'none !important' }}
      data-js-focus-visible
    />
  );
}
