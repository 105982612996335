import { stackConstants } from '../_constants';

export const stackUtilities = {
  sort,
  filter,
};

// Returns dictionary of { origamiId: linkId } given tagId id, stackLinks byId / allIds
// Upgrading the id parameter to be string or array of ids
// Will overwrite linkId to last one if using id array input
function filter(id, object, array, param_src, param_dst) {
  if (Object.keys(object).length === 0) {
    return {};
  }
  const idArray = typeof id === 'string' ? [id] : id;

  // Create union of all tags in filtered tags
  const filtered = array.filter((i) => {
    if (!object.hasOwnProperty(i)) {
      console.log(i);
      return false;
    }
    if (!object[i].hasOwnProperty(param_src)) {
      console.log(i);
      return false;
    }
    return idArray.includes(object[i][param_src]);
  });

  // Compile dictionary of OGids with the respective tags
  const result = {};

  for (let i = 0; i < filtered.length; i++) {
    let key = object[filtered[i]][param_dst];
    let linkKey = object[filtered[i]]['id'];

    if (result.hasOwnProperty(key)) {
      result[key] = [...result[key], ...[linkKey]];
    } else {
      result[key] = [linkKey];
    }
  }

  // Filter result for only OGids that contain === filter tags
  const final = Object.assign(
    {},
    ...Object.keys(result).map((k) => {
      return result[k].length === idArray.length ? { [k]: result[k] } : null;
    })
  );

  return final;
}

function sort(sortBy, object, idArray, newItem) {
  const allIds = Array.from(idArray);

  if (newItem && newItem.id) {
    allIds.push(newItem.id);
    object[newItem.id] = newItem;
  }

  allIds.sort((idA, idB) => {
    const a = object[idA];
    const b = object[idB];
    switch (sortBy) {
      case stackConstants.SORT_NAME_ASC:
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return 1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return -1;
        }
        return 0;
      case stackConstants.SORT_NAME_DESC:
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      case stackConstants.SORT_COUNT_ASC:
        if (a.origamiLinks.items.length < b.origamiLinks.items.length) {
          return -1;
        }
        if (a.origamiLinks.items.length > b.origamiLinks.items.length) {
          return 1;
        }
        return 0;
      case stackConstants.SORT_COUNT_DESC:
        if (a.origamiLinks.items.length < b.origamiLinks.items.length) {
          return 1;
        }
        if (a.origamiLinks.items.length > b.origamiLinks.items.length) {
          return -1;
        }
        return 0;
      default:
        return allIds;
    }
  });
  return allIds;
}
