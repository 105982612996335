import React from "react";
import "./homepage.css";
import {
  Authenticator,
  Greetings,
  SignIn,
  SignUp,
  ForgotPassword,
} from "aws-amplify-react";
import { AuthTheme, SignUpConfig } from "./auth-theme";
import HeroScroller from "./hero-widget";
import CustomSignIn from "./signin-helper";
import CustomSignUp from "./signup-helper";
import CustomForgotPassword from "./forgotpassword-helper";
import { useUser } from "../../user-provider";

const sprite = "/static/placeholders/sprite.png";
const phone = "/static/placeholders/phoneBoxE.png";

function Homepage() {
  const { WithSignIn } = useUser();
  const HOCSignIn = WithSignIn(CustomSignIn);

  return (
    <main className="App-main">
      <article className="App-main-article">
        <div className="App-main-phone">
          <HeroScroller />
          <img src={phone} className="App-phone" alt="phone" />
        </div>
        <div className="App-main-form">
          <div className="App-main-logo">
            <h1 className="hero" style={{ backgroundImage: `url(${sprite})` }}>
              Origami
            </h1>
            <img src={sprite} className="App-logo" alt="logo" />
          </div>
          <div className="App-main-text">
            <span>For the explorer, storyteller</span>
            <br></br>
            <span>student, teacher, minimalist, collector</span>
            <br></br>
            <span>mother, daughter, father, son</span>
            <br></br>
            <span>and lover in</span>
            <br></br>
            <span>You</span>
            <br></br>
          </div>
          <div className="App-main-login">
            <Authenticator
              authState="signIn"
              theme={AuthTheme}
              hide={[Greetings, SignIn, SignUp, ForgotPassword]}
            >
              <HOCSignIn />
              <CustomSignUp override="SignUp" signUpConfig={SignUpConfig} />
              <CustomForgotPassword override="ForgotPassword" />
            </Authenticator>
          </div>
        </div>
      </article>
    </main>
  );
}

export default Homepage;
