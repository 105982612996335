import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { arrowUpIcon, arrowDownIcon, downIcon } from '../../helpers/icons';
import { useSelector, useDispatch } from 'react-redux';
import { stackActions } from '../../../redux/_actions';
import { stackConstants } from '../../../redux/_constants';

function TagsSelector(props) {
  const dispatch = useDispatch();
  const stack = useSelector((state) => state.stack);
  let nameIcon = arrowUpIcon;
  let countIcon = arrowDownIcon;
  let sortName = stackConstants.SORT_NAME_ASC;
  let sortCount = stackConstants.SORT_COUNT_DESC;

  switch (stack.status.sort) {
    case stackConstants.SORT_NAME_DESC:
      break;
    case stackConstants.SORT_NAME_ASC:
      nameIcon = arrowDownIcon;
      sortName = stackConstants.SORT_NAME_DESC;
      break;
    case stackConstants.SORT_COUNT_DESC:
      nameIcon = arrowDownIcon;
      sortName = stackConstants.SORT_NAME_DESC;
      countIcon = arrowUpIcon;
      sortCount = stackConstants.SORT_COUNT_ASC;
      break;
    case stackConstants.SORT_COUNT_ASC:
      nameIcon = arrowDownIcon;
      sortName = stackConstants.SORT_NAME_DESC;
      break;
    default:
      break;
  }

  return (
    <Dropdown style={{ marginLeft: '2%', float: 'right' }}>
      <Dropdown.Toggle
        id="dropdown-tags-view-selector"
        variant="outline"
        style={{ height: '48px' }}
      >
        {downIcon}
      </Dropdown.Toggle>
      <Dropdown.Menu className="tags-view-menu">
        <Dropdown.Item
          as="button"
          onSelect={() =>
            dispatch(
              stackActions.sort(sortName, stack.tags.byId, stack.tags.allIds)
            )
          }
        >
          Sort by name
          <span style={{ float: 'right' }}>{nameIcon}</span>
        </Dropdown.Item>
        <Dropdown.Item
          as="button"
          onSelect={() =>
            dispatch(
              stackActions.sort(sortCount, stack.tags.byId, stack.tags.allIds)
            )
          }
        >
          Sort by count
          <span style={{ float: 'right' }}>{countIcon}</span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default TagsSelector;
