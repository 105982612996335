import React, { useState, useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import StackSidebar from './stacks/stack-sidebar';
import BlackBox from './blackbox/blackbox-sidebar';
import Gifts from './gifts/gifts-sidebar';
import StackTags from './tags/tags-sidebar';
import ModelMaker from './maker/model-maker-sidebar';
import StackSidebarFooter from './helpers/sidebar-footer';
import { listIcon } from '../helpers/icons';
import { useHotkeys } from 'react-hotkeys-hook';
import { useSelector, useDispatch } from 'react-redux';
import { sidebarActions } from '../../redux/_actions';
import { sidebarStates } from '../../redux/_constants';
import './sidebar.css';

/*
const LogOut = (logout) => { 
  return(
    <Dropdown.Item as="button" onSelect={logout} style={{height: '48px', borderBottom: '1px solid rgba(0,0,0,.125)'}}>Logout 
      <span style={{float: 'right'}}>{logoutIcon}</span>
    </Dropdown.Item>
  )
}
*/

function MainMenu(props) {
  const { history, id, files, setId } = props;
  const [show, setShow] = useState(null);
  const [menu, setMenu] = useState(sidebarStates.MAKER);
  const [makerFiles, setMakerFiles] = useState([]);
  const { alert, sidebar } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    if (files) {
      setMakerFiles(files);
    }
  }, [files]);

  useEffect(() => {
    if (sidebar.show) {
      setShow('slide-in');
      setMenu(sidebar.selected);
    } else {
      setShow(null);
    }
  }, [sidebar]);

  const handleToggle = (isOpen, event, metadata) => {
    if (isOpen || metadata?.source === 'click' || event?.source === 'click') {
      showMenu();
    }
  };

  const showMenu = () => {
    dispatch(sidebarActions.show());
  };

  const hideMenu = () => {
    dispatch(sidebarActions.hide());
  };

  const changeMenu = (selected) => {
    dispatch(sidebarActions.changeMenu(selected));
  };

  const handleMenu = () => {
    switch (menu) {
      case sidebarStates.BLACKBOX:
        return <BlackBox menu={setMenu} />;
      case sidebarStates.GIFTS:
        return <Gifts menu={setMenu} />;
      case sidebarStates.TAGS:
        return <StackTags menu={setMenu} />;
      case sidebarStates.MAKER:
        return <ModelMaker files={makerFiles} menu={setMenu} setId={setId} />;
      default:
        return <StackSidebar id={id} history={history} />;
    }
  };

  useHotkeys(
    'tab',
    (e) => {
      show === null ? showMenu() : hideMenu();
      e.preventDefault();
    },
    [show]
  );

  useHotkeys(
    'esc',
    (e) => {
      if (show === null) {
        return;
      }
      if (!alert.clear) {
        return;
      }
      e.preventDefault();
      hideMenu();
    },
    [show]
  );

  useHotkeys(
    't',
    (e) => {
      if (show === null) {
        return;
      }
      e.preventDefault();
      changeMenu(sidebarStates.TAGS);
    },
    [show]
  );

  useHotkeys(
    'b',
    (e) => {
      if (show === null) {
        return;
      }
      e.preventDefault();
      changeMenu(sidebarStates.BLACKBOX);
    },
    [show]
  );

  useHotkeys(
    'g',
    (e) => {
      if (show === null) {
        return;
      }
      e.preventDefault();
      changeMenu(sidebarStates.GIFTS);
    },
    [show]
  );

  useHotkeys(
    'm',
    (e) => {
      if (show === null) {
        return;
      }
      e.preventDefault();
      changeMenu(sidebarStates.MAKER);
    },
    [show]
  );

  return (
    <Dropdown
      style={{ marginRight: '30px', float: 'left' }}
      show={true}
      onToggle={handleToggle}
    >
      <Dropdown.Toggle id="dropdown-main-menu" variant="outline">
        {listIcon}
      </Dropdown.Toggle>
      <Dropdown.Menu
        className={['main-menu', show].join(' ')}
        id="stack-sidebar"
      >
        {handleMenu()}
        <StackSidebarFooter />
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default MainMenu;
