import "@google/model-viewer";
import { useModelViewer } from "./model-viewer-hooks";

function ModelViewer({ modelGLB, modelUSDZ, localFile }) {
  let src, iosSrc;

  if (localFile) {
    src = modelGLB;
    iosSrc = null;
  } else {
    // Removed .glb/.usdz extension for S3 get
    src = modelGLB ? modelGLB : "/static/placeholders/origamiscan.glb";
    iosSrc = modelUSDZ ? modelUSDZ : "/static/placeholders/origamiscan.usdz";
  }

  const mv = useModelViewer({ selector: "boxViewer", src, iosSrc });
  return mv;
}

export default ModelViewer;
